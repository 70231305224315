import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link, useHistory } from 'react-router-dom';
import Cropper from 'react-easy-crop'
import ReCAPTCHA from "react-google-recaptcha";

import { login, signupUser, updateProfile } from '../../store/session';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useDocumentTitle } from '../Common/useMeta';

const SignupFormPage = ({ editing }) => {
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionUser = useSelector(state => state.session.user);
  const [ username, setUsername ] = useState( editing ? sessionUser.username : '');
  const [ firstName, setFirstName ] = useState( editing ? sessionUser.firstName : '');
  const [ lastName, setLastName ] = useState( editing ? sessionUser.lastName : '');
  const [ email, setEmail ] = useState( editing ? sessionUser.email : '');
  const [ websiteUrl, setWebsiteUrl ] = useState( editing ? sessionUser.websiteUrl : '');
  const [ avatarUrl, setAvatarUrl ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ inputErrors, setInputErrors ] = useState([]);
  const [ processing, setProcessing ] = useState(false);
  const [ imageUrl, setImageUrl ] = useState(editing ? sessionUser.avatarUrl : '');

  const [crop, setCrop] = useState({ x: 2, y: 2 });
  const [zoom, setZoom] = useState(1);

  useDocumentTitle(editing ? 'Edit Profile' : 'Sign Up');

  const validateSignup = () => {
    const errors = [];

    if(username.length < 4) errors.push('Username must have more than 3 characters');
    if(username.length > 30) errors.push('Username must have 30 or fewer characters');
    if(firstName.length > 50) errors.push('First name must have 50 or fewer characters');
    if(lastName.length > 50) errors.push('Last name must have 50 or fewer characters');
    if(email.length < 3) errors.push('E-mail must have more than 2 characters');
    if(email.length > 256) errors.push('E-mail must have less than 256 characters');
    if(websiteUrl.length > 500) errors.push('Website URL must have 500 or fewer characters');    
    if(confirmPassword !== password) errors.push("Your password doesn't match");
    
    setInputErrors(errors);
    return errors;
  }
  const { trackPageView } = useMatomo();

  // Track page view
  useEffect(() => {
    trackPageView()
  }, []);  

  useEffect(() => {
    if (inputErrors && inputErrors.length) validateSignup();
  }, [username, firstName, lastName, websiteUrl, avatarUrl, email, password, confirmPassword]);

  if (sessionUser && !editing) {
    return (<Redirect to='/' />);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const errors = validateSignup();
    if (errors.length > 0) return;

    if (password !== confirmPassword) {
      setInputErrors(["Your password doesn't match", ...inputErrors]);
      return;
    }

    const user = {
      username: username.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      websiteUrl: websiteUrl.trim(),
      avatarUrl: avatarUrl || '/img/userDefault.png',
      password
    }

    setUsername(user.username);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setWebsiteUrl(user.websiteUrl);

    setProcessing(true);

    if (!editing) {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      user.ct = captchaToken;
    }

    return dispatch(editing ? updateProfile(user) : signupUser(user) )
      .catch((res) => {        
        if (res.data && res.data.errors) setInputErrors(res.data.errors);
        setProcessing(false);
        return res;
      }).then((res) => {
        setProcessing(false);        
        if (res && res?.errors) setInputErrors(res.errors);
        else if (res?.data && res.data?.errors) setInputErrors(res.data.errors);
        else history.push("/user/"+username);
      });
  };

  const onCropChange = (crop) => {
    setCrop(crop);    
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {    
    if (imageUrl) {
      var image = new Image();
      image.onload = function() {

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = 480;// croppedAreaPixels.width;
        canvas.height = 480; //croppedAreaPixels.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            croppedAreaPixels.x * scaleX,
            croppedAreaPixels.y * scaleY,
            croppedAreaPixels.width * scaleX,
            croppedAreaPixels.height * scaleY,
            0,
            0,
            480,//croppedAreaPixels.width,
            480 //croppedAreaPixels.height
        );
        
        const reader = new FileReader()
        canvas.toBlob(blob => {
            if (blob) {
              reader.readAsDataURL(blob)
              reader.onloadend = () => {
                setAvatarUrl(reader.result);
              }
            }
        });
      };
      image.src = imageUrl;    
    }

  }

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  }
  
  

  return (
    <div className="w-full mx-auto absolute min-h-full pb-32 py-5 flex-1 flex flex-col justify-center items-center bg-background2 bg-center bg-cover mt-12 sm:mt-24">
      <h1 className="block text-2xl text-white my-2 text-center">
        { editing ? <span>TAMAGO Profile</span> : <span>Sign-up to <span className="font-bold tracking-widest">TAMAGO</span></span> } 
      </h1>
      <form onSubmit={handleSubmit} className="px-6 sm:px-8 py-6 w-5/6 sm:w-1/3 sm:min-w-max mb-4 shadow-lg rounded bg-white glow">
        <div className='flex flex-col justify-center items-center'>
          <div className='relative w-full h-64'>      
            <Cropper
              image={imageUrl != '' ? imageUrl : (typeof navigator.standalone === 'boolean' ? null : '/img/userDefault.png')}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              showGrid={false}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
              cropSize={{width: 256, height: 256}}
              objectFit='vertical-cover'
            />          
          </div>
          { imageUrl && <input type="range" min="0.5" max="3" value={zoom} step={0.1} className='w-64 my-4' onChange={(e, zoom) => { setZoom(e.target.value); return true; } } /> }
          <label className="block bg-white text-mandarin hover:bg-mandarin-dark hover:text-white font-bold mb-2 text-center font-bold py-2 px-4 rounded cursor-pointer" htmlFor="image-upload">
            Upload Image
            <input
              id="image-upload"
              accept="image/*"
              type='file'
              onChange={(e) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(e.target.files[0]); 
                  reader.onloadend = function() {
                      setImageUrl(reader.result);
                  }
              }}
              className="hidden"
            />  
          </label>
        </div>
       
        <div className='flex justify-center'>
          <label className="block font-bold text-space-cadet mb-2 w-full sm:w-3/6 sm:text-center">
            Profile Name
            <input
              type='text'
              placeholder='Enter your profile name'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              // required
              className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4 sm:text-center"
              style={{background: '#1d50b322'}}
            />
          </label>
        </div>        
        <div className='sm:grid sm:grid-cols-2 gap-6'>
          <label className="block font-bold text-space-cadet mb-2">
            First Name
            <input
              type='text'
              placeholder='Enter your first name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              // required
              className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4"
              style={{background: '#1d50b322'}}
            />
          </label>
          <label className="block font-bold text-space-cadet mb-2">
            Last Name
            <input
              type='text'
              placeholder='Enter your last name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              // required
              className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4"
              style={{background: '#1d50b322'}}
            />
          </label>
        </div>
        <div className='sm:grid sm:grid-cols-2 gap-6'>
          <label className="block font-bold text-space-cadet mb-2">
            E-mail
            <input
              type='search'
              placeholder='Enter your e-mail'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // required
              className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4"
              style={{background: '#1d50b322'}}
            />
          </label>
          <label className="block font-bold text-space-cadet mb-2">
            Website
            <input
              type='text'
              placeholder='Enter your website (optional)'
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4"
              style={{background: '#1d50b322'}}
            />
          </label>
        </div>
        <div className='sm:grid sm:grid-cols-2 gap-6'>
          <label className="block font-bold text-space-cadet mb-2">
            Password
            <input
              type='password'
              placeholder='Enter your password'
              value={password}
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
              // required
              className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4"
              style={{background: '#1d50b322'}}
            />
          </label>
          <label className="block font-bold text-space-cadet mb-2">
            Confirm Password
            <input
              type='password'
              placeholder='Confirm your password'
              value={confirmPassword}
              autoComplete="new-password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              // required
              className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 "
              style={{background: '#1d50b322'}}
            />
          </label>
        </div>

        <ul id="login-errors" className="block my-2 text-center text-red-600 font-bold">
          {inputErrors.filter((err) => err != 'Invalid value').map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>

        <div className='my-2 flex justify-around flex-col sm:flex-row'>
          <button type='submit' disabled={(!!inputErrors.length) || processing} className={`sm:w-2/6 ${!!inputErrors.length ? "bg-gray-400" : "bg-mandarin hover:bg-mandarin-dark"} text-white font-bold py-2 px-4 rounded my-4 `+(processing?'dotty':'')}>
            { processing ? 'Processing' : editing ? 'Update' : 'Create Profile' }
          </button>
        </div>
      </form>

      { 
        !editing && <>
          <div className="p-1">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={'6Lccd0wgAAAAAAu8JBoTBLYmL1qZ_rzI996L157Q'}
              size="invisible"
            />
            <Link to='/login' className="text-mandarin font-large hover:font-bold">Sign in</Link>
          </div>
        </>
      }

    </div>
  );
};

SignupFormPage.defaultProps = {
  editing: false
}

export default SignupFormPage;
