import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import * as nearAPI from 'near-api-js';
import { isIOS, isSafari, isMobile } from 'react-device-detect';

import LoginFormPage from './components/LoginFormPage';
import LoginFormPage2 from './components/LoginFormPage2';
import SignupFormPage from './components/SignupFormPage';
import ForgotPasswordFormPage from './components/ForgotPasswordFormPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import LandingPage from './components/LandingPage';
import LiveEvent from './components/LandingPage/LiveEvent';
import ElectonicGrooveEvent from './components/LandingPage/ElectonicGrooveEvent';
import SearchResults from './components/SearchResults';
import Navigation from './components/Navigation';
import Player from './components/Player';
import Footer from './components/Footer';
import Upload from './components/Upload';
import TrackPage from './components/TrackPage';
import SendTip from './components/TrackPage/SendTip';
import Download from './components/TrackPage/Download';
import TrackAction from './components/TrackPage/TrackAction';
import UserPage from './components/User';
import WalletPage from './components/User/WalletPage';
import VerifyAccount from './components/Verify';

import { restoreUser } from './store/session';
import { getGenres } from './store/ui';
import { restoreCSRF } from './store/csrf';
import { initNear } from './store/near';

import configData from './config.json';
import TipsState from './components/TipsState';
import MintTrack from './components/TrackPage/MintTrack';
import ListTrack from './components/TrackPage/ListTrack';
import BidTrack from './components/TrackPage/BidTrack';
import NFTTrackPage from './components/TrackPage/NFTTrackPage';
import BalancePage from './components/User/BalancePage';

async function initNearContracts() {
  const nearNet = process.env.NEAR_NETWORK || configData.NEAR_NETWORK;
  const nearConfig = {
    networkId: nearNet,
    nodeUrl: 'https://rpc.' + nearNet + '.near.org',
    contractName: (process.env.NEAR_TIPPING_CONTRACT || configData.NEAR_TIPPING_CONTRACT),
    nftContractName: (process.env.NEAR_NFT_CONTRACT || configData.NEAR_NFT_CONTRACT),
    marketContractName: (process.env.NEAR_MARKET_CONTRACT || configData.NEAR_MARKET_CONTRACT),
    //walletUrl: "https://" + (nearNet === 'mainnet' ? '' : (nearNet + '.')) + "mynearwallet.com",
    walletUrl: 'https://wallet.' + (nearNet === 'mainnet' ? '' : (nearNet + '.')) + 'near.org',
    helperUrl: 'https://helper.' + nearNet + '.near.org',
    archivalUrl: 'https://archival-rpc.' + nearNet + '.near.org',
    logIntoAccount: (process.env.NEAR_SIGN_INTO || configData.NEAR_SIGN_INTO),
  };

  const keyStore = new nearAPI.keyStores.BrowserLocalStorageKeyStore();
  const near = await nearAPI.connect({ keyStore, ...nearConfig });
  const wallet = new nearAPI.WalletConnection(near);

  const tipContract = await new nearAPI.Contract(
    wallet.account(),
    nearConfig.contractName,
    {
      viewMethods: ['getTipsTrack', 'getTipsTrackTotal', 'getTipsReceived', 'getTipsReceivedTotal', 'getTipsSent', 'getTipsSentTotal', 'getTrackOwner', 'getMinTip'],
      changeMethods: ['addTip'],
      sender: wallet.getAccountId(),
    }
  );

  const nftContract = await new nearAPI.Contract(
    wallet.account(),
    nearConfig.nftContractName,
    {
      viewMethods: ['nft_token'],
      changeMethods: ['nft_approve'],
      sender: wallet.getAccountId(),
    }
  );

  const marketContract = await new nearAPI.Contract(
    wallet.account(),
    nearConfig.marketContractName,
    {
      viewMethods: ['storage_minimum_balance','storage_balance_of','get_sales','get_sale'],
      changeMethods: ['storage_deposit','storage_withdraw','remove_sale','update_price','offer','accept_offer'],
      sender: wallet.getAccountId(),
    }
  );


  return { near, tipContract, nftContract, marketContract, nearConfig, wallet };
}

function App() {
  const dispatch = useDispatch();
  const currentTrack = useSelector(state => state.player.currentTrack);
  const [isLoaded, setIsLoaded] = useState(false);

  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  // CSRF heartbeat every minute
  const MINUTE_MS = 60000;

  const noTransition = [
    '/recent',
    '/recommended',
    '/popular',
    '/genre',
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      restoreCSRF();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {

    dispatch(restoreUser()).then(() => {
      setIsLoaded(true);
    });

    initNearContracts().then(({ near, tipContract, nftContract, marketContract, nearConfig, wallet }) => {
      dispatch(initNear({
        api: near,
        config: nearConfig,
        wallet,
        tipContract,
        nftContract,
        marketContract
      }));
    });

  }, [dispatch]);

  useEffect(() => {
    if (isLoaded) {
      window.store.dispatch(getGenres());
    }
  }, [isLoaded]);

  useEffect(() => {
    if (location !== displayLocation) {

      if (
        (location.pathname === '/' || noTransition.reduce((prev, curr) => { return location.pathname.startsWith(curr) || prev }, false)) &&
        (displayLocation.pathname === '/' || noTransition.reduce((prev, curr) => { return displayLocation.pathname.startsWith(curr) || prev }, false))
      ) {
        setDisplayLocation(location);
      } else {
        setTransistionStage("fadeOut");
      }
    }
  }, [location]);

  return isLoaded && (
      <div className={'flex flex-col h-screen ' + (isIOS || isSafari ? 'isIOS ' : '')} id='app'>
        <Navigation isLoaded={isLoaded} />
        {isLoaded && (
          <div
            className={`${transitionStage}`}
            onAnimationEnd={() => {
              if (transitionStage === "fadeOut") {
                setTransistionStage("fadeIn");
                setDisplayLocation(location);
              }
            }}
          >
            <Switch location={displayLocation}>
              <Route exact path='/'>
                <LandingPage />
              </Route>
              <Route exact path='/the-love-boat-party'>
                <LiveEvent />
              </Route>

              {
                /*
                <Route exact path='/convert-audio-to-nft'>
                  <ConvertAudio />
                </Route>
                */
              }

              <Route exact path='/electronic-groove-anniversary'>
                <ElectonicGrooveEvent />
              </Route>
              <Route path='/search'>
                <SearchResults />
              </Route>
              <Route path='/login'>
                <LoginFormPage />
              </Route>
              <Route path='/loginnow'>
                <LoginFormPage2 />
              </Route>
              <Route path='/signup'>
                <SignupFormPage />
              </Route>
              <Route path='/forgot-password'>
                <ForgotPasswordFormPage />
              </Route>
              <Route path='/verify/:token?'>
                <VerifyAccount />
              </Route>
              <Route path='/reset/:token'>
                <ResetPasswordPage />
              </Route>
              <Route path='/upload'>
                <Upload />
              </Route>
              <Route path='/profile'>
                <SignupFormPage editing={true} />
              </Route>
              <Route path='/user/:username/wallet/balance'>
                <BalancePage />
              </Route>
              <Route path='/user/:username/wallet'>
                <WalletPage />
              </Route>
              <Route path='/user/:username'>
                <UserPage />
              </Route>
              <Route exact path={`/tracks/:id(\\d+)`}>
                <TrackPage />
              </Route>
              <Route path={`/tracks/:id(\\d+)/edit`}>
                { /*<EditTrack />*/}
                <Upload editing={true} />
              </Route>
              <Route path={`/tracks/:id(\\d+)/nft`}>
                <NFTTrackPage />
              </Route>
              <Route path={`/tracks/:id(\\d+)/mint`}>
                <MintTrack />
              </Route>
              <Route path={`/tracks/:id(\\d+)/list`}>
                <ListTrack />
              </Route>
              <Route path={`/tracks/:id(\\d+)/bid`}>
                <BidTrack />
              </Route>

              <Route path='/tracks/:id/sendtip'>
                <SendTip />
              </Route>

              <Route path='/tracks/:id/download'>
                <Download />
              </Route>

              <Route path='/tracks/:id/action'>
                <TrackAction />
              </Route>

              <Route exact path='/tips-state'>
                <TipsState />
              </Route>

              <Route exact path='/genre/:genre?'>
                <LandingPage />
              </Route>
              <Route exact path='/:listtype/:genre?'>
                <LandingPage />
              </Route>

              <Route>
                <h1 className='text-2xl'>PAGE NOT FOUND</h1>
              </Route>
            </Switch>
          </div>
        )}
        <Footer />
        {currentTrack && <Player />}
      </div>
  );
}

export default App;
