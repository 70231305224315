import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { playTrack, playAudioTrack } from '../../store/player';
import WaveSurfer from "wavesurfer.js";
import MarkersPlugin from "./MarkersPlugin";
import SkullIcon from '../../img/icons/skullz.png';
import BombIcon from '../../img/icons/bomb.png';
import ChestIcon from '../../img/icons/chest.png';
import configData from '../../config.json'
import { fetch } from "../../store/csrf";

const formWaveSurferOptions = (ref) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let context, processor;
    if (isSafari && false) {
        // Safari 11 or newer automatically suspends new AudioContext's that aren't
        // created in response to a user-gesture, like a click or tap, so create one
        // here (inc. the script processor)
        let AudioContext = window.AudioContext || window.webkitAudioContext;
        context = new AudioContext();
        processor = context.createScriptProcessor(1024, 1, 1);
    }

    return {
        container: ref,
        audioContext: context || null,
        audioScriptProcessor: processor || null,
        waveColor: "#BFC0C0",
        progressColor: "#6fcc5e",
        cursorColor: "transparent",
        responsive: true,
        height: 128,
        normalize: true,
        partialRender: true,
        hideScrollbar: true,
        plugins: [
            MarkersPlugin.create({})
        ]
    }
}

export function WaveForm({ track, height, showTime, hideTimeMobile }) {
    const dispatch = useDispatch();
    const bombRef = useRef();
    const chestRef = useRef();
    const skullRef = useRef();
    const sessionUser = useSelector(state => state.session.user);
    const currentTrack = useSelector(state => state.player.currentTrack);
    const isPlaying = useSelector(state => state.player.isPlaying);
    const wavesurferPlayer = useSelector(state => state.player.wavesurfer);
    const waveformRef = useRef(null);
    const [wavesurfer, setWaveSurfer] = useState(null);
    const [displayTime, setDisplayTime] = useState(track.duration);
    const [peakData, setPeakData] = useState(null);

    const customSeekTo = (progress) => {
        if (
            typeof progress !== 'number' ||
            !isFinite(progress) ||
            progress < 0 ||
            progress > 1
        ) {
            return;
        }

        const isWebAudioBackend = wavesurfer.params.backend === 'WebAudio';
        const paused = wavesurfer.backend.isPaused();

        if (isWebAudioBackend && !paused) {
            wavesurfer.backend.pause();
        }

        // avoid small scrolls while paused seeking
        const oldScrollParent = wavesurfer.params.scrollParent;
        wavesurfer.params.scrollParent = false;
        wavesurfer.backend.seekTo(progress * wavesurfer.getDuration());
        //if (progress) console.log(progress, wavesurfer.drawer);
        wavesurfer.drawer.progress(progress);

        if (isWebAudioBackend && !paused) {
            wavesurfer.backend.play();
        }

        wavesurfer.params.scrollParent = oldScrollParent;
        wavesurfer.fireEvent('seek', progress);
    }

    const secondsToMinutes = seconds => Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2);

    const callback2 = useCallback((time) => {
        if (currentTrack && currentTrack.id === track.id &&
            wavesurfer && wavesurfer.backend &&
            wavesurferPlayer && wavesurferPlayer.backend &&
            !isNaN(wavesurferPlayer.getDuration())
        ) {
            setDisplayTime(wavesurferPlayer.getCurrentTime());
            if (wavesurferPlayer.getCurrentTime() === wavesurferPlayer.getDuration()) {
                wavesurfer.seekTo(0);
                customSeekTo(0);
            }
            customSeekTo(wavesurferPlayer.getCurrentTime() / wavesurferPlayer.getDuration());
        } else if (wavesurfer && wavesurfer.backend) {
            customSeekTo(0);
        }
    }, [wavesurfer, wavesurferPlayer, currentTrack]);

    useEffect(() => {

        setWaveSurfer(null);
        setDisplayTime(track.duration);

        if (waveformRef.current) {
            waveformRef.current.innerHTML = '';
            let options = formWaveSurferOptions(waveformRef.current);
            if (height) {
                options = { ...options, height };
            }
            setWaveSurfer(WaveSurfer.create(options));
        }
        return () => {
            if (wavesurfer) {
                wavesurfer.destroy();
            }
        }
    }, [track.id]);

    useEffect(() => {
        setDisplayTime(track.duration);

        if (wavesurfer) {
            if (waveformRef.current) waveformRef.current.innerHTML = '';
            wavesurfer.backend.destroy();
            wavesurfer.drawer.destroy();
            wavesurfer.init();
            wavesurfer.backend.setPeaks(JSON.parse(peakData));
            wavesurfer.backend.explicitDuration = track.duration;
            wavesurfer.drawBuffer();
            wavesurfer.fireEvent('waveform-ready');
            wavesurfer.track_id = track.id;
            wavesurfer.container.firstChild.style.overflow = 'visible';
        }

    }, [track.duration, wavesurfer]);

    useEffect(() => {
        if (waveformRef.current && wavesurfer && peakData) { //track.TrackPeak && track.TrackPeak.peakData) {
            wavesurfer.backend.destroy();
            wavesurfer.drawer.destroy();
            wavesurfer.init();
            wavesurfer.backend.setPeaks(JSON.parse(peakData));
            wavesurfer.backend.explicitDuration = track.duration;
            wavesurfer.drawBuffer();
            wavesurfer.fireEvent('waveform-ready');
            wavesurfer.track_id = track.id;
            wavesurfer.container.firstChild.style.overflow = 'visible';

            var elements = waveformRef.current.parentNode.getElementsByClassName('wavesurfer-marker');
            while (elements.length > 0) {
                elements[0].parentNode.removeChild(elements[0]);
            }

            for (let i = 0; i < track.Reactions.length; i++) {
                if (track.Reactions[i].trackAt) {
                    let ele = null;
                    switch (track.Reactions[i].reactionType) {
                        case 'bomb': ele = bombRef.current.cloneNode(true); break;
                        case 'skull': ele = skullRef.current.cloneNode(true); break;
                        case 'gem': ele = chestRef.current.cloneNode(true); break;
                        default: break;
                    }

                    if (ele) {
                        let wrapper = document.createElement('span');
                        wrapper.setAttribute('data-tooltip', track.Reactions[i].User.username);
                        wrapper.setAttribute('class', 'track-reaction tooltip');
                        wrapper.appendChild(ele);

                        wavesurfer.addMarker({
                            time: track.Reactions[i].trackAt,
                            markerElement: wrapper
                        });
                    }
                }
            }

            for (let i = 0; i < track.Comments.length; i++) {
                if (track.Comments[i].trackAt) {
                    let ele = document.createElement('img');
                    ele.src = track.Comments[i].User.avatarUrl;
                    ele.style.width = "22px";
                    ele.style.height = "22px";
                    ele.style.borderRadius = "50%";
                    ele.title = track.Comments[i].content + " - " + track.Comments[i].User.username;

                    const isUserComment = sessionUser && sessionUser.id === track.Comments[i].userId;

                    let wrapper = document.createElement('span');
                    wrapper.setAttribute('data-tooltip', ele.title);
                    wrapper.setAttribute('class', `track-reaction tooltip ${isUserComment ? 'user-comment' : ''}`);
                    wrapper.appendChild(ele);

                    wavesurfer.addMarker({
                        time: track.Comments[i].trackAt,
                        markerElement: wrapper
                    });
                }
            }
        }
    }, [track, wavesurfer, peakData]);

    useEffect(() => {
        if (track) {
            fetch(configData.API_URL + `/api/tracks/${track.id}/peaks/`).then(data => {
                if (data?.data?.peaks?.peakData) {
                    setPeakData(data?.data?.peaks.peakData);
                }
            })
        }
    }, [track])

    useEffect(() => {
        if (wavesurfer) {
            wavesurfer.unAll();
            wavesurfer.drawer.unAll();
            wavesurfer.drawer.on('click', (evt, progress) => {
                if (wavesurferPlayer && wavesurferPlayer.backend && wavesurfer.track_id === wavesurferPlayer.track_id) {
                    wavesurferPlayer.seekTo(progress);
                } else {
                    //dispatch(playAudioTrack());
                    //dispatch(playTrack(wavesurfer.track_id));
                    dispatch(playTrack(wavesurfer.track_id)).then(() => {
                        dispatch(playAudioTrack());
                    });

                }
            });
        }
        return () => {
            if (wavesurferPlayer) {
            }
        }
    }, [isPlaying, currentTrack, wavesurferPlayer, wavesurfer]);

    useEffect(() => {
        if (wavesurferPlayer) {
            //wavesurferPlayer.on('timeupdate', callback1);            
            wavesurferPlayer.on('audioprocess', callback2);
        }

        return () => {
            if (wavesurferPlayer) wavesurferPlayer.un('audioprocess', callback2);
        }
    }, [wavesurferPlayer, callback2]);

    useEffect(() => {
        return () => {
            if (wavesurferPlayer && callback2) {
                wavesurferPlayer.un('audioprocess', callback2);
            }
        }
    }, [wavesurferPlayer]);

    return <>
        <div ref={waveformRef} className={currentTrack && currentTrack.id === track.id ? 'flex-1 track-glow' : 'flex-1'} />
        {showTime && <span className={`pl-2 opacity-70 ${hideTimeMobile ? 'hidden md:block' : ''}`}>{secondsToMinutes(displayTime)}</span>}
        <img src={BombIcon} ref={bombRef} alt="bomb-icon" style={{ height: "28px", display: "none" }} />
        <img src={SkullIcon} ref={skullRef} alt="skull-icon" style={{ height: "28px", display: "none" }} />
        <img src={ChestIcon} ref={chestRef} alt="chest-icon" style={{ height: "28px", display: "none" }} />
    </>
}

WaveForm.defaultProps = {
    showTime: true,
    hideTimeMobile: false,
    height: null
}

export default WaveForm;
