import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { postReaction, deleteReaction } from '../../store/track';
import { getTips } from '../../store/near';
import styles from './TrackReact.module.css'
//import {ReactComponent as BombIcon} from '../../img/icons/bomb2-svgrepo-com.svg'
//import {ReactComponent as ChestIcon} from '../../img/icons/chest-svgrepo-com.svg'
//import {ReactComponent as SkullIcon} from '../../img/icons/skull3-svgrepo-com.svg'
import SkullIcon from '../../img/icons/skullz.png'
import BombIcon from '../../img/icons/bomb.png'
import ChestIcon from '../../img/icons/chest.png'
import { ReactComponent as DownloadIcon } from '../../img/icons/download-svgrepo-com.svg';
import { ReactComponent as DownloadIcon2 } from '../../img/icons/download2-svgrepo-com.svg';
import { ReactComponent as DownloadIcon3 } from '../../img/icons/download3-svgrepo-com.svg';
import { formatNumbers } from '../../utils/formatNumbers';

const TrackReact = ({ track, className, height, showCount }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const sessionUser = useSelector(state => state.session.user);
    const player = useSelector(state => state.player);
    const tips = useSelector(state => state.near.tipData);

    useEffect(() => {
        dispatch(getTips(track.id));
    }, []);

    const like = async (type, liked) => {
        if (sessionUser) {
            if (sessionUser.accountStatus != 'ok') {
                history.push('/verify');
            } else {
                const trackAt = player.isPlaying && player.currentTrack.id === track.id ? player.wavesurfer.getCurrentTime() : null;
                if (liked)
                    dispatch(deleteReaction(track.id)).catch(err => {
                        if (err?.status == 401) {
                            history.push('/verify');
                        }
                    });
                else
                    dispatch(postReaction(track.id, type, { trackAt })).catch(err => {
                        if (err?.status == 401) {
                            history.push('/verify');
                        }
                    });
            }
        } else {
            history.push('/login');
        }
    }

    if (!track || !track.Reactions) return null;

    return (
        <div className={`sm:gap-1 ${styles.reactions} ${className}`}>
            <>
                {
                    ['bomb', 'skull', 'gem'].map(type => {

                        const reactions = track.Reactions.filter(reaction => reaction.reactionType === type && reaction.commentId == 0);
                        const liked = sessionUser && reactions.find(curVal => curVal.userId === sessionUser.id);

                        let likeIcon = '';
                        switch (type) {
                            case 'bomb':
                                likeIcon = <i className={`relative ${styles[type]} ${liked ? styles.liked : ''}`} onClick={(e) => like(type, liked)}
                                    style={{ marginTop: "-8px" }}>
                                    <span style={{ top: 'calc(50% - 3px)' }}></span>
                                    <img src={BombIcon} style={height ? { height: (height * 1.184) + 'px' } : { height: "45px" }} alt="Like" title="Like" />
                                </i>
                                break;
                            case 'skull':
                                likeIcon = <i className={`relative ${styles[type]} ${liked ? styles.liked : ''}`} onClick={(e) => like(type, liked)}>
                                    <span></span>
                                    <img src={SkullIcon} style={height ? { height: (height) + 'px' } : {}} alt="Dislike" title="Dislike" />
                                </i>
                                break;
                            case 'gem':
                                likeIcon = <i className={`relative ${styles[type]} ${liked ? styles.liked : ''}`}>
                                    <Link to={{
                                        pathname: '/tracks/' + track.id + '/action',
                                        state: { action: 'tip' }
                                    }}>
                                        <span className='blue'></span>
                                        <img src={ChestIcon} style={height ? { height: (height) + 'px' } : {}} alt="Support" title="Support" />
                                    </Link>
                                </i>
                                break;
                            default:
                                break;
                        }

                        return <div key={track.id + type} className={`${styles.reaction} ${type === 'gem' ? styles['reaction-blue'] : ''}`}>
                            {likeIcon}
                            <span>{showCount ? formatNumbers(reactions.length) || '-' : ' '}</span>
                        </div>
                    })
                }
            </>
            {track.downloadPrice &&
                <div className={`${styles.reaction} ${styles['reaction-download']}`}>
                    <i>
                        <Link to={{
                            pathname: '/tracks/' + track.id + '/action',
                            state: { action: 'download' }
                        }}>
                            <DownloadIcon3 style={height ? { height: (height-5) } : { height: 31 }} className={`h-9 sm:ml-1 mt-0.5 cursor-pointer z-index-1 ${styles.downloadIcon}`} title="Download" />
                            <span className='download'></span>
                        </Link>
                    </i>
                </div>
            }
        </div>
    )
}

TrackReact.defaultProps = {
    height: false,
    showCount: true
}

export default TrackReact;