import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams, Link } from 'react-router-dom';
import * as nearAPI from 'near-api-js';
import Cropper from 'react-easy-crop'
import { getGenres, removeGenres, getTags, removeTags } from '../../store/ui';
import { newTrack, getTrack, editTrack, deleteTrack, uploadProgress, clearUploadInfo, setUploadErrors } from '../../store/track';
import WaveSurfer from "wavesurfer.js";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Toggle from 'react-toggle'
import "react-toggle/style.css"

import { ReactComponent as NearType } from '../../img/icons/near_type.svg'
import { ReactComponent as VinylSVG } from '../../img/icons/vinyl-svgrepo-com.svg'
import { ReactComponent as CheckmarkSVG } from '../../img/icons/checkmark-svgrepo-com.svg'

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useDocumentTitle } from '../Common/useMeta';
import { getTextWidth } from '../../utils/getTextWidth';

var jsmediatags = window.jsmediatags;

function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
  
    return (hrs ? (hrs + ' hr ') : '') + (mins ? (mins + ' mins ') : '') + secs + ' secs';
}

const Upload = ({ editing }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { utils } = nearAPI;
    const sessionUser = useSelector(state => state.session.user);
    const nearWallet = useSelector(state => state.near.wallet);
    const genres = useSelector(state => state.ui.genres);
    const tags = useSelector(state => state.ui.tags);
    const track = useSelector(state => state.track.track);
    const uploadProgressPct = useSelector(state => state.track.uploadProgress);
    const uploadInfo = useSelector(state => state.track.uploadInfo);
    const uploadErrors = useSelector(state => state.track.uploadErrors);
    const uploadMSRemaining = useSelector(state => state.track.uploadMSRemaining);
    const nearTipContract = useSelector(state => state.near.tipContract);    

    //const waveformRef = useRef(null);

    const [title, setTitle] = useState('');
    const [peakData, setPeakData] = useState('');
    const [duration, setDuration] = useState(0);
    const [downloadPrice, setDownloadPrice] = useState(0.1);
    const [description, setDescription] = useState('');
    const [downloadDescription, setDownloadDescription] = useState('');

    const [genreId, setGenreId] = useState('');
    const [newGenre, setNewGenre] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [trackTags, setTrackTags] = useState([]);
    const [trackImage, setTrackImage] = useState('');
    const [trackFile, setTrackFile] = useState(null);
    const [prevTrackFile, setPrevTrackFile] = useState(null);
    const [downloadFile, setDownloadFile] = useState(null);
    const [inputErrors, setInputErrors] = useState([]);
    const [peaksProcessing, setPeaksProcessing] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [allowDownloads, setAllowDownloads] = useState(false);
    const [minPrice, setMinPrice] = useState(null);
    const [usd, setUsd] = useState(null);

    const [crop, setCrop] = useState({ x: 10, y: 10 });
    const [zoom, setZoom] = useState(1);
    const { trackPageView } = useMatomo();

    useDocumentTitle('Upload');

    const priceOk = (allowDownloads && downloadPrice >= minPrice) || !allowDownloads;

    // Track page view
    useEffect(() => {
        trackPageView()
        fetch('https://helper.mainnet.near.org/fiat')
            .then(response => {
                return response.json();
            })    
            .then(d => {    
                setUsd(d?.near?.usd);
            });
    }, []);    

    useEffect(() => {
        if (uploadInfo) {     
            
            //waveformRef?.current?.replaceChildren();
            setTrackFile(uploadInfo.audio); 
            setTitle(uploadInfo.title);           
            setDescription(uploadInfo.description);
            setDownloadDescription(uploadInfo.downloadDescription);
            setImageUrl(uploadInfo.imageUrl);
            setNewGenre(uploadInfo.newGenre);
            
            if (uploadInfo.downloadPrice != null && uploadInfo.downloadPrice != 'null') {
                setAllowDownloads(true);
                setDownloadPrice(uploadInfo.downloadPrice);
                if (uploadInfo.downloadFile) {
                    setDownloadFile(uploadInfo.downloadFile);
                }
            }
            if (genres && genres.length) {
                const selectedGenre = genres.find(genre => genre.id == uploadInfo.genreId);
                setGenreId({ value: selectedGenre.id, label: selectedGenre.name });
            }
        }        
    }, [uploadInfo, genres]);

    useEffect(() => {
        // is finished uploading, clear redux state and redirect to track page
        if (uploadInfo?.success) {
            const trackURL = `/tracks/${uploadInfo.id}`;
            dispatch(clearUploadInfo());
            history.push(trackURL);
            return;
        }
    }, [uploadInfo?.success]);

    useEffect(() => {                
        if (uploadErrors && uploadErrors.length) {
            const errors = inputErrors.concat(uploadErrors);
            setInputErrors(errors);
        }
    }, [uploadErrors])

    useEffect(() => {        
        if (id) dispatch(getTrack(id)).then((t) => {
            if (editing) {
                if (sessionUser.id !== t.User.id) {
                    setAccessDenied(true);
                } else {
                    setTitle(t.title);
                    setDescription(t.description);
                    setDownloadDescription(t.downloadDescription);
                    setImageUrl(t.imageUrl);
                    setAllowDownloads(t.downloadPrice != null && sessionUser?.nearAccount);
                    setDownloadPrice(t.downloadPrice || 0.1);
                }
            }
        });
    }, [id, dispatch]);

    useEffect(() => {

        if (genres && newGenre.length !== 0) {
            const genre = genres.find(gen => gen.name.replace(' ', '').toLowerCase() === newGenre.replace(' ', '').toLowerCase());
            if (genre) {
                setGenreId({ value: genre.id, label: genre.name });
                setNewGenre('');
            }
        }


        let errors = [];
        if (title.length > 200) errors.push('Title must have 200 or fewer characters');
        if (genreId && genreId.value === 16 && !newGenre) errors.push('Enter in new genre name');
        else if (genreId && genreId.value === 16 && newGenre.length === 0) errors.push('Enter in new genre name');
        if (genreId === '') errors.push('Select a genre');
        if (trackFile && trackFile.size && trackFile.size > (1024 * 1024 * 1024)) {
            // 1GB limit
            alert('Your audio file is larger then 1GB');
            setTrackFile(null);
        }

        if (uploadErrors && uploadErrors.length) {            
            errors = errors.concat(uploadErrors);            
        }

        if (!editing && allowDownloads && downloadFile == null && trackFile) {
            setDownloadFile(trackFile);
        }

        setInputErrors(errors);

    }, [title, imageUrl, newGenre, genreId, trackFile, downloadFile, allowDownloads, uploadErrors ]);

    useEffect(() => {
        dispatch(getGenres());
        dispatch(getTags());
        return () => {
            //dispatch(removeGenres());
            dispatch(removeTags());
        }
    }, [dispatch]);

    useEffect(() => {
        if (trackFile) {
            if (!editing) {

                if (prevTrackFile == null) {
                    setTitle('');
                    setPeakData('');
                    setDuration(0);
                    setDescription('');
                    setDownloadDescription('');
                    setGenreId('');
                    setNewGenre('');
                    setImageUrl('');
                    setDownloadFile(null);
                    setInputErrors([]);
                    setPeaksProcessing(false);
                    setTrackTags([]);
                    setDownloadPrice(0.1);
                }
                setPrevTrackFile(trackFile);

                jsmediatags.read(trackFile, {
                    onSuccess: function (meta) {
                        if (meta.tags.picture && meta.tags.picture.data) {
                            let base64String = "";
                            for (var i = 0; i < meta.tags.picture.data.length; i++) {
                                base64String += String.fromCharCode(meta.tags.picture.data[i]);
                            }
                            setImageUrl(`data:${meta.tags.picture.format};base64,${window.btoa(base64String)}`);
                        }

                        let title = '';
                        if (meta.tags.title) title += meta.tags.title;
                        if (meta.tags.album) title += ' - ' + meta.tags.album;
                        setTitle(title);

                        let desc = '';
                        if (meta.tags.title) desc += meta.tags.title;
                        if (meta.tags.album) desc += " - " + meta.tags.album;
                        if (meta.tags.artist) desc += "\nBy " + meta.tags.artist;
                        if (meta.tags.year) desc += "\n" + meta.tags.year;
                        setDescription(desc);

                        if (meta.tags.genre) {
                            const genre = genres.find(gen => gen.name.replace(' ', '').toLowerCase() === meta.tags.genre.replace(' ', '').toLowerCase());
                            if (genre) {
                                setGenreId({ value: genre.id, label: genre.name });
                            } else {
                                setGenreId(
                                    genres
                                        .filter(genre => genre.id === 16)
                                        .map((genre) => { return { value: genre.id, label: genre.name } })
                                        .pop()
                                );
                                setNewGenre(meta.tags.genre);
                            }
                        }

                    },
                    onError: function (error) {
                        if (trackFile.name) {
                            setTitle(trackFile.name);
                        }
                    }
                });
            }

            /*
            setPeaksProcessing(true);
            const ws = WaveSurfer.create({
                container: waveformRef.current,
                interact: false,
                hideCursor: true,
                height: 120,
                cursorColor: "transparent",
            });
            ws.on('ready', () => {
                setPeakData(ws.backend.getPeaks(500));
                setPeaksProcessing(false);
                setDuration(ws.getDuration());
            });
            ws.loadBlob(trackFile);
            */
        }
    }, [trackFile]);

    useEffect(() => {
        if (editing && track && track.id && genres) {
            const genre = genres.find(genre => genre.id === track.genreId);
            if (genre) setGenreId({ value: genre.id, label: genre.name });
        }
        if (editing && track && track.id && tags) {
            setTrackTags(tags.filter(tag => track.TagsForTrack.find(t => t.id === tag.id)).map(tag => { return { value: tag.id, label: tag.name } }));
        }
        if (editing && track && track.id) {     
            /*
            waveformRef?.current?.replaceChildren(); 
            const wavesurfer = WaveSurfer.create({
                container: waveformRef.current,
                interact: false,
                hideCursor: true,
                height: 120,
                cursorColor: "transparent",
            });
            wavesurfer.backend.setPeaks(JSON.parse(track.TrackPeak.peakData));
            wavesurfer.backend.explicitDuration = track.duration;
            wavesurfer.drawBuffer();
            wavesurfer.fireEvent('waveform-ready');
            wavesurfer.track_id = track.id;
            wavesurfer.container.firstChild.style.overflow = 'visible';            
            */
        }
    }, [track, genres, tags]);
    
    useEffect(() => {        
        if (nearTipContract) {
            nearTipContract.getMinTip({}).then((minTip) => setMinPrice(parseFloat(utils.format.formatNearAmount(minTip))) );            
        }
    }, [nearTipContract]);

    if (!sessionUser) {
        return (<Redirect to='/login' />);
    }

    if (editing && accessDenied) {
        return (<Redirect to={`/tracks/` + id} />);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        dispatch(uploadProgress({ loaded: 0, total: 100 }));
        // VALID FILE EXTENSIONS
        // WAV, FLAC, AIFF, ALAC, OGG, MP2, MP3, AAC, AMR, WMA

        const trackData = {
            title: title.trim(),
            description: description.trim(),            
            imageUrl: trackImage || '/img/trackDefault.png',
            trackFile,
            userId: sessionUser.id,
            genreId: genreId ? genreId.value : '',
            newGenre,
            peakData: JSON.stringify(peakData),
            duration,
            tags: JSON.stringify(trackTags),
            downloadPrice: allowDownloads ? downloadPrice : null,
            downloadFile: downloadFile == trackFile ? null : downloadFile,
            downloadDescription: downloadDescription?.trim(),
            downloadFilename: downloadFile?.name,
            downloadContentType: downloadFile?.type,
        }

        setTitle(trackData.title);
        setDescription(trackData.description);
        setDownloadDescription(trackData.downloadDescription);

        const trackDispatch = await dispatch(editing ? editTrack(track.id, trackData) : newTrack(trackData));

        if (trackDispatch) {
            setTitle('');
            setPeakData('');
            setDuration(0);
            setDescription('');
            setDownloadDescription('');
            setGenreId('');
            setNewGenre('');
            setImageUrl('');
            setTrackFile(null);
            setDownloadFile(null);
            setInputErrors([]);
            setPeaksProcessing(false);
            setTrackTags([]);
            setDownloadPrice(0.1);

            //history.push(`/tracks/${trackDispatch.id}`);
        }
    };

    const handleDelete = async () => {
        await dispatch(deleteTrack(track.id))
        history.push(`/`)
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        if (imageUrl) {
            var image = new Image();
            image.setAttribute("crossorigin", "anonymous");
            image.onload = function () {
                const canvas = document.createElement('canvas');
                const scaleX = image.naturalWidth / image.width;
                const scaleY = image.naturalHeight / image.height;
                canvas.width = 480;//croppedAreaPixels.width;
                canvas.height = 480;//croppedAreaPixels.height;
                const ctx = canvas.getContext("2d");

                ctx.drawImage(
                    image,
                    croppedAreaPixels.x * scaleX,
                    croppedAreaPixels.y * scaleY,
                    croppedAreaPixels.width * scaleX,
                    croppedAreaPixels.height * scaleY,
                    0,
                    0,
                    480, //croppedAreaPixels.width,
                    480 // croppedAreaPixels.height
                );

                const reader = new FileReader()
                canvas.toBlob(blob => {
                    if (blob) {
                        reader.readAsDataURL(blob)
                        reader.onloadend = () => {
                            setTrackImage(reader.result);
                        }
                    }
                });
            };
            image.src = imageUrl;
        }
    }

    const onZoomChange = (zoom) => {
        setZoom(zoom);
    }

    const handleTrackFileSelect = (e) => {
        //waveformRef.current.replaceChildren();
        setTrackFile(e.target.files[0]);
        if (editing) {
            if (track.downloadFilename) {
                // do nothing
            } else {
                setDownloadFile(e.target.files[0]);
            }
        } else {            
            if (downloadFile == null) setDownloadFile(e.target.files[0]);
        }
    }    

    const handleCancel = (e) => {
        dispatch(clearUploadInfo());
        dispatch(setUploadErrors([]));
        setTitle('');
        setPeakData('');
        setDuration(0);
        setDescription('');
        setDownloadDescription('');
        setGenreId('');
        setNewGenre('');
        setImageUrl('');
        setTrackFile(null);
        setDownloadFile(null);
        setInputErrors([]);
        setPeaksProcessing(false);
        setTrackTags([]);
        setDownloadPrice(0.1);
    }

    return ( 
        <div className="w-full mx-auto absolute min-h-full pb-32 sm:pb-48 py-5 flex-1 flex flex-col justify-center items-center bg-background2 bg-center bg-cover mt-12 sm:mt-24">
            <form onSubmit={handleSubmit} className="mx-12 px-12 sm:px-0 sm:mx-0 w-full sm:w-2/3 sm:max-w-screen-lg" style={uploadProgressPct?{width:'auto'}:{}}>
                {!editing &&
                    <>
                        <label className={`block mb-2 text-center ${trackFile ? 'hidden' : ''}`} htmlFor='audio-file'>
                            <span className='bg-mandarin text-white hover:bg-mandarin-dark hover:text-white mb-2 text-center font-bold py-3 px-8 rounded cursor-pointer text-xl'>Select Audio File</span>
                            <input
                                accept="audio/mp3, audio/wav, audio/mp4, audio/m4a"
                                type='file'
                                onChange={handleTrackFileSelect}
                                className="block appearance-none w-full bg-white px-2 py-2 rounded text-black mt-1 mb-4 hidden"
                                style={{ background: '#1d50b322' }}
                                id="audio-file"
                            />
                        </label>
                    </>
                }
                <div className={`w-full rounded shadow-lg bg-white ${trackFile || editing ? 'max-h-full' : 'max-h-0 overflow-hidden'} glow`} style={{ transition: 'all .3s ease' }}>
                    <div className={`px-6 sm:px-8 py-4 ${uploadProgressPct ? '' : 'mb-4'}`}>
                        {uploadProgressPct
                            ?
                            <div className="relative pt-1 w-96 text-center text-black">
                                <div className='mb-2 text-xl'>Uploading</div>
                                <img src={imageUrl} className="w-48 mb-2" style={{marginLeft: 'auto', marginRight: 'auto'}} />
                                <div className="mb-2 font-bold">{ title }</div>
                                <div className="overflow-hidden h-2 mb-1 text-xs flex rounded bg-blue-200">
                                    <div style={{ width: (uploadProgressPct.loaded / uploadProgressPct.total * 100) + "%" }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
                                </div>
                                <span  className='dotty'>{ msToTime(uploadMSRemaining) } remaining</span>
                                {/*<span className='dotty'>{uploadProgressPct.loaded / uploadProgressPct.total * 100 > 99 ? 'Processing' : 'Uploading'}</span>*/}
                            </div>
                            :
                            <>
                                <div className='flex flex-col'>
                                    <div className='hidden sm:grid grid-cols-3 gap-6 mt-2 text-space-cadet font-bold'>
                                        <div className='flex items-center'>Audio File</div>
                                        <div className='flex items-center'>Track Image</div>
                                        <div className='flex items-center'>
                                            Sell Download
                                            <div className='flex flex-grow items-center justify-center sm:justify-end h-10 whitespace-nowrap'>
                                                <Toggle disabled={sessionUser?.nearAccount == null} id='allow-downloads' checked={allowDownloads} onChange={(e) => setAllowDownloads(e.target.checked)} />                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grid sm:grid-cols-3 gap-2 sm:gap-6'>
                                        <div className='flex sm:hidden items-center text-space-cadet font-bold'>Audio File</div>
                                        <div className='border rounded p-2 flex flex-col h-80 sm:h-auto'>
                                            <div className='text-space-cadet text-sm text-center font-bold flex-grow'>{ editing && trackFile == null && track ? track.User.username+' - '+track.title+'.aac' : trackFile?.name }</div>
                                            <div className='relative'>
                                                <VinylSVG className='w-32 opacity-90' style={{marginLeft: 'auto', marginRight: 'auto'}} />
                                                <CheckmarkSVG className='w-15 absolute' style={{top: '35%', fill: '#fff', left: '43%'}} />
                                            </div>
                                            {
                                                /*
                                                <div className='' ref={waveformRef} style={{ zIndex: '0', position: 'relative' }}></div>                                                
                                                */
                                            }
                                            <div className='text-xs text-space-cadet text-center opacity-50 flex-grow flex items-end justify-center'>This file will be encoded for streaming</div>
                                            <div className='text-xs text-space-cadet text-center opacity-50'>WAV MP3 AAC M4A</div>                                            
                                            {
                                                <label className={`block bg-white text-mandarin hover:bg-mandarin-dark hover:text-white font-bold text-center py-2 px-4 rounded cursor-pointer mt-2 border border-mandarin text-sm `} htmlFor='audio-file'>
                                                    <span type="button" className='mb-2 text-center font-bold py-2 px-4 rounded cursor-pointer text-sm mt-2'>Upload File</span>
                                                    <input
                                                        accept="audio/*"
                                                        type='file'
                                                        onChange={handleTrackFileSelect}
                                                        className="block appearance-none w-full bg-white px-2 py-2 rounded text-black mt-1 mb-4 hidden"
                                                        style={{ background: '#1d50b322' }}
                                                        id="audio-file"
                                                    />
                                                </label>
                                            }
                                        </div>
                                        <div className='flex sm:hidden items-center text-space-cadet font-bold mt-4'>Track Image</div>
                                        <div className='border rounded p-2 flex flex-col'>
                                            <div className='relative w-full h-72 flex-grow'>
                                                <Cropper
                                                    image={imageUrl ? imageUrl : (typeof navigator.standalone === 'boolean' ? null : '/img/trackDefault.png')}
                                                    crop={crop}
                                                    zoom={zoom}
                                                    aspect={1}
                                                    showGrid={false}
                                                    onCropChange={onCropChange}
                                                    onCropComplete={onCropComplete}
                                                    onZoomChange={onZoomChange}
                                                    /*cropSize={{width: 320, height: 320}}*/
                                                    objectFit='horizontal-cover'
                                                />
                                            </div>

                                            <div className='text-xs text-space-cadet text-center opacity-50 mt-2'>Drag to re-position image. Pinch or scroll wheel to zoom.</div>
                                            <div className='text-xs text-space-cadet text-center opacity-50'>PNG, JPG, GIF (no animation)</div>                                            

                                            <label className="block bg-white text-mandarin hover:bg-mandarin-dark hover:text-white font-bold text-center py-2 px-4 rounded cursor-pointer mt-2 border border-mandarin text-sm">
                                                Upload Image
                                                <input
                                                    accept="image/*"
                                                    type='file'
                                                    onChange={(e) => {
                                                        const reader = new FileReader();
                                                        reader.readAsDataURL(e.target.files[0]);
                                                        reader.onloadend = function () {
                                                            setImageUrl(reader.result);
                                                        }
                                                    }}
                                                    className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4 hidden"
                                                    style={{ background: '#1d50b322' }}
                                                />
                                            </label>


                                        </div>

                                        <div className='flex sm:hidden items-center text-space-cadet font-bold mt-4'>
                                            Sell Download
                                            <div className='flex flex-grow items-center justify-end sm:justify-end h-10 whitespace-nowrap'>
                                                <Toggle disabled={sessionUser?.nearAccount == null} id='allow-downloads' checked={allowDownloads} onChange={(e) => setAllowDownloads(e.target.checked)} />                                                
                                            </div>
                                        </div>
                                        <div className={'border rounded p-2 transition-all '+(allowDownloads?'':'bg-gray-100')}>                                                
                                            {
                                                sessionUser?.nearAccount == null && 
                                                <div className='text-space-cadet sm:ml-4'><Link className='text-tama-dark-green underline' to={'/user/'+sessionUser.username+'/wallet'}>Connect Wallet</Link> to enable downloading</div>
                                            }
                                            {
                                                sessionUser?.nearAccount && 
                                                <>
                                                    <div className='transition-all w-full h-full flex flex-col' style={allowDownloads?{opacity: 1}:{ opacity: 0 }}>                                                        
                                                        <div className='text-space-cadet text-sm text-center font-bold'>
                                                            { editing && downloadFile == null && track?.downloadFilename == null && <span className='text-lg' style={{color: 'red'}}>Upload file</span> }
                                                            { editing && downloadFile == null && track?.downloadFilename != null && track?.downloadFilename }                        
                                                            { editing && downloadFile != null && downloadFile.name }                        
                                                            { !editing && downloadFile?.name }  
                                                        </div>
                                                        <textarea
                                                            placeholder='Description of downloadable content'
                                                            value={downloadDescription}
                                                            onChange={(e) => setDownloadDescription(e.target.value)}                                                            
                                                            className="block appearance-none w-full px-2 py-2 rounded text-black my-2"
                                                            style={{ background: '#1d50b322', height: '60px' }}
                                                        />
                                                        <div className='flex flex-col items-center my-2 flex-grow justify-center'>
                                                            <label className='text-space-cadet font-bold cursor-pointer text-xl'>Download Price</label>
                                                            <input type='text' 
                                                                className="block appearance-none w-full p-2 rounded text-black mt-2 mb-4 text-center" 
                                                                style={{ background: '#1d50b322', minWidth: 40, width: getTextWidth(downloadPrice, '16px "NoyhRLight", sans-serif')+17 }} 
                                                                value={downloadPrice} 
                                                                onChange={(e) => setDownloadPrice(e.target.value.replace(/([^0-9.]+)/, ''))}
                                                                />
                                                            <NearType height={12} fill='#333' />
                                                            { usd && <div className='text-space-cadet ml-1 text-sm'>≈{(downloadPrice*usd).toFixed(2)} USD</div>}   
                                                            { allowDownloads && !priceOk && <div className='ml-1 text-sm' style={{color: 'red'}}>Minimum price is { minPrice }</div> } 
                                                        </div>
                                                        <div className='text-xs text-space-cadet text-center opacity-50'>This is the file that will be downloaded</div>
                                                        <div className='text-xs text-space-cadet text-center opacity-50'>Any filetype, WAV, ZIP, etc</div>         
                                                        <label className="block bg-white text-mandarin hover:bg-mandarin-dark hover:text-white font-bold text-center py-2 px-4 rounded cursor-pointer border border-mandarin text-sm mt-2">
                                                            Upload New File
                                                            <input
                                                                type='file'
                                                                onChange={(e) => setDownloadFile(e.target.files[0])}
                                                                className="block appearance-none w-full bg-white px-2 py-2 rounded text-black mt-1 mb-4 hidden"
                                                                style={{ background: '#1d50b322' }}
                                                                id="download-file"
                                                            />
                                                        </label>
                                                    </div>                                                                                       
                                                </>
                                            }
                                        </div> 
                                    </div>

                                </div>
                                <div>
                                    <div className='mt-3 w-full'>

                                        <label className="block font-bold text-space-cadet mb-2">
                                            Title
                                            <input
                                                type='text'
                                                placeholder='Enter the title of the track'
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                                required
                                                className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4"
                                                style={{ background: '#1d50b322' }}
                                            />
                                        </label>
                                        <label className="block font-bold text-space-cadet mb-2">
                                            Description
                                            <textarea
                                                placeholder='Enter a description'
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                required
                                                className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4"
                                                style={{ background: '#1d50b322', height: '112px' }}
                                            />
                                        </label>
                                        <div className="grid sm:grid-cols-2 gap-6">
                                            <label className="block text-space-cadet mb-2">
                                                <span className='font-bold'>Genre</span>
                                                <Select
                                                    placeholder='Select Genre'
                                                    options={
                                                        [
                                                            {
                                                                label: 'Music',
                                                                options: genres.filter(genre => genre.name !== 'Audiobooks' && genre.name !== 'Pocasts').map((genre) => { return { value: genre.id, label: genre.name } })
                                                            },
                                                            {
                                                                label: 'Audio',
                                                                options: genres.filter(genre => genre.name === 'Audiobooks' || genre.name === 'Pocasts').map((genre) => { return { value: genre.id, label: genre.name } })
                                                            },
                                                        ]
                                                    }
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            border: 'none !important',
                                                            boxShadow: 'none',
                                                            background: 'rgba(29, 80, 179, 0.133) none repeat scroll 0% 0%',
                                                            borderRadius: '0.25rem',
                                                        }),
                                                        valueContainer: (provided) => ({
                                                            ...provided,
                                                            padding: '2px 7px'
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            marginTop: '2px'
                                                        }),
                                                        menuList: (provided) => ({
                                                            ...provided,
                                                            height: '185px'
                                                        }),
                                                        groupHeading: (provided) => ({
                                                            ...provided,
                                                            color: '#000000',
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            borderBottom: '1px solid #ccc',
                                                            paddingBottom: '5px'
                                                        }),
                                                    }}
                                                    value={genreId}
                                                    onChange={(e) => setGenreId(
                                                        genres
                                                            .filter(genre => genre.id === e.value)
                                                            .map((genre) => { return { value: genre.id, label: genre.name } })
                                                            .pop()
                                                    )}
                                                    required
                                                    className="block appearance-none w-full rounded text-black"
                                                />
                                                {genreId && genreId.value === 16 &&
                                                    <input
                                                        type='text'
                                                        placeholder='Enter new genre'
                                                        value={newGenre}
                                                        onChange={(e) => setNewGenre(e.target.value)}
                                                        className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4"
                                                        style={{ background: '#1d50b322' }}
                                                    />
                                                }
                                            </label>
                                            <label className="block text-space-cadet mb-2">
                                                <span className='font-bold'>Tags</span>
                                                <div className=''>
                                                    <CreatableSelect
                                                        options={tags.map((tag) => { return { value: tag.id, label: tag.name } })}
                                                        isMulti
                                                        placeholder="&nbsp;&nbsp;Enter tags"
                                                        styles={{
                                                            clearIndicator: () => ({
                                                                display: 'none'
                                                            }),
                                                            control: (provided) => ({
                                                                ...provided,
                                                                border: 'none !important',
                                                                boxShadow: 'none'
                                                            }),
                                                            valueContainer: (provided) => ({
                                                                ...provided,
                                                                background: 'rgba(29, 80, 179, 0.133) none repeat scroll 0% 0%',
                                                                borderRadius: '0.25rem',
                                                                padding: '2px 4px'
                                                            }),
                                                            indicatorsContainer: (provided) => ({
                                                                ...provided,
                                                                marginRight: '-8px',
                                                                display: 'none'
                                                            }),
                                                            input: (provided) => ({
                                                                ...provided,
                                                                marginLeft: '8px'
                                                            }),
                                                            multiValue: (provided) => ({
                                                                ...provided,
                                                                background: '#ffffff',
                                                                paddingLeft: '4px',
                                                                fontSize: '1.125rem',
                                                                lineHeight: '1.2rem'
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                marginTop: '2px'
                                                            }),
                                                            menuList: (provided) => ({
                                                                ...provided,
                                                                height: '150px'
                                                            }),
                                                        }}
                                                        onChange={newValue => setTrackTags(newValue)}
                                                        value={trackTags}
                                                    />
                                                </div>
                                            </label>
                                        </div>    
                                        
                                    </div>

                                </div>                                

                                <ul id="login-errors" className="block my-2 text-center text-red-600 font-bold">
                                    {inputErrors.map((error, idx) => <li key={idx}>{error}</li>)}
                                </ul>

                                <div className='my-2 flex justify-around'>
                                    <button type='submit' 
                                            disabled={peaksProcessing || inputErrors.length || !priceOk || (editing && allowDownloads && downloadFile == null && track?.downloadFilename == null)} 
                                            className={`w-72 sm:w-2/6 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded text-xl m-0 ${peaksProcessing ? 'dotty opacity-50' : ''} ${inputErrors.length || !priceOk || (editing && allowDownloads && downloadFile == null && track?.downloadFilename == null) ? 'opacity-50' : ''}`}>
                                        {peaksProcessing ? 'Processing' : editing ? 'Update' : 'Add Track'}
                                    </button>
                                </div>
                            </>
                        }
                        {editing && !uploadProgressPct && <div className='flex justify-center mt-8 mb-2 cursor-pointer' style={{ color: 'red ' }} onClick={() => { if (window.confirm('Are you sure?')) { handleDelete() } }}>Permanently Delete</div>}
                        {!editing && !uploadProgressPct && <div className='flex justify-center mt-8 mb-2 cursor-pointer' style={{ color: 'red ' }} onClick={handleCancel}>Cancel</div>}
                    </div>
                </div>

                {(trackFile || editing) && !uploadProgressPct &&
                    <div className='text-silver flex text-center justify-center mt-6'>
                        <p>
                            By uploading, you confirm that your sounds comply with our <a href="/Tamago-Terms-Of-Use.pdf" target="_blank" className='text-white'>Terms of Use</a><br />and you don't infringe anyone else's rights.<br /><br />
                            Please note that by adding rightsholder information, the respective rightsholder<br />will be able to see monetization and availability settings of this track.
                        </p>
                    </div>
                }
                {
                    uploadProgressPct && <div className='text-silver flex text-center justify-center mt-6'>
                        <p>Upload times may vary depending on your internet connection<br />and the size of the content you are uploading.</p>
                    </div>
                }

            </form>
        </div>
    );
};

Upload.defaultProps = {
    editing: false,
    track: null
}

export default Upload;
