import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { deleteComment, postCommentReaction, deleteCommentReaction } from '../../store/track';
import SkullIcon from '../../img/icons/skullz.png'
import BombIcon from '../../img/icons/bomb.png'
import { ReactComponent as ReplyIcon } from "../../img/icons/reply.svg";
import { ReactComponent as DeleteIcon } from "../../img/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../img/icons/edit.svg";
import AddReply from "./AddReply";
import { timeSince } from "../../utils/getTimeSince";
import CommentReplies from "./CommentReplies";
import styles from '../Common/TrackReact.module.css'
import { formatNumbers } from "../../utils/formatNumbers";

const TrackComments = ({ track, sessionUser, setUpdatedComment, setEditActive, setUpdatedCommentId }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [addingReply, setAddingReply] = useState(false)
    const [commentId, setCommentId] = useState(null)
    const [replyUser, setReplyUser] = useState(null)

    const cancelReply = () => {
        setAddingReply(false)
        setCommentId(null)
    }

    const filteredComments = track.Comments && track.Comments.filter(comment => comment.parentId === null || comment.parentId === 0)

    const getCommentReplies = (commentId) => {
        const filteredReplies = track.Comments && track.Comments.filter(comment => comment.parentId === commentId)

        return filteredReplies
    }

    const like = async (type, liked, commentId) => {
        const newComment = {
            trackId: track.id,
            commentId,
        }

        if (sessionUser) {

            if (sessionUser.accountStatus != 'ok') {
                history.push('/verify');
            } else {
                if (liked)
                    dispatch(deleteCommentReaction(track.id, commentId)).catch(err => {
                        if (err?.status == 401) {
                            history.push('/verify');
                        }
                    });
                else
                    dispatch(postCommentReaction(track.id, commentId, newComment, type)).catch(err => {
                        if (err?.status == 401) {
                            history.push('/verify');
                        }
                    });
            }
        } else {
            history.push('/login');
        }
    }

    return (
        <>
            {track.Comments &&
                <>
                    <div className="flex flex-col text-white border-space-cadet">
                        {filteredComments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((comment) => {
                            return (
                                <div className="flex flex-col border-b-2 border-space-cadet" key={comment.id}>
                                    <div className="flex flex-grow flex-row items-center justify-between">
                                        <div className="flex flex-grow flex-row items-center">
                                            <div className="py-4 sm:py-4 flex flex-row items-center space-x-2">
                                                <div className="">
                                                    <img className="h-8 w-8 max-w-none rounded-full" src={comment.User.avatarUrl} alt="user avatar"></img>
                                                </div>
                                                <div className="">
                                                    <div className="font-medium text-link">
                                                        <Link to={`/user/${comment.User.username}`}>{comment.User.firstName}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-2 sm:px-4 py-4 w-2/3 sm:w-3/4">
                                                <p className="whitespace-nowrap text-ellipsis truncate">{comment.content}</p>
                                            </div>
                                        </div>
                                        <div className='text-right text-sm whitespace-nowrap opacity-50'>
                                            {timeSince(Date.parse(comment.createdAt), true)}
                                        </div>
                                    </div>

                                    <div className="flex items-right mb-2">
                                        <div className='flex-grow flex items-center justify-start ml-8' style={{ color: 'rgb(111, 204, 94)' }}>
                                            {
                                                ['bomb', 'skull'].map(type => {

                                                    const reactions = track.Reactions &&
                                                        track.Reactions.filter(reaction => Number(reaction.commentId) === comment.id && reaction.reactionType === type)

                                                    const liked = sessionUser && reactions && reactions.find(curVal => curVal.userId === sessionUser.id);

                                                    let likeIcon = '';
                                                    switch (type) {
                                                        case ('bomb'):
                                                            likeIcon = <div className='w-10 items-center flex flex-row gap-1 ml-2 cursor-pointer' onClick={(e) => { like(type, liked, comment.id) }}>
                                                                <img src={BombIcon} alt='bomb-icon' className={`h-5 w-auto -mt-1 ${liked ? styles.likedsmall : ''}`} />
                                                                <span className="hover:text-white text-sm font-bold">{formatNumbers(track.Reactions.filter(reaction => Number(reaction.commentId) === comment.id && reaction.reactionType === 'bomb').length)}</span>
                                                            </div>
                                                            break;
                                                        case ('skull'):
                                                            likeIcon = <div className='w-10 items-center flex flex-row gap-1 cursor-pointer' onClick={(e) => { like(type, liked, comment.id) }}>
                                                                <img src={SkullIcon} alt='skull-icon' className={`h-5 w-auto -mt-1 ${liked ? styles.likedsmall : ''}`} />
                                                                <span className="hover:text-white text-sm font-bold">{formatNumbers(track.Reactions.filter(reaction => Number(reaction.commentId) === comment.id && reaction.reactionType === 'skull').length)}</span>
                                                            </div>
                                                            break;
                                                        default:
                                                            break;
                                                    }

                                                    return (
                                                        <div key={type}>
                                                            {likeIcon}
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                sessionUser &&
                                                <div className='flex flex-row items-center cursor-pointer hover:text-white' 
                                                     onClick={() => { 
                                                        setAddingReply(true); 
                                                        setCommentId(comment.id); 
                                                        setReplyUser(comment.User.username) 
                                                    }}>
                                                    <ReplyIcon className='w-4 h-4 ml-2' />
                                                    <span className='ml-2 text-sm'>Reply</span>
                                                </div>
                                            }
                                            {
                                                sessionUser && comment.User.id === sessionUser.id &&
                                                <>
                                                    <div className='flex flex-row items-center cursor-pointer hover:text-white' 
                                                         onClick={() => { 
                                                            setEditActive(true); 
                                                            setUpdatedComment(comment.content); 
                                                            setUpdatedCommentId(comment.id) 
                                                        }}>
                                                        <EditIcon className='w-4 h-4 ml-2' />
                                                        <span className='ml-2 text-sm'>Edit</span>
                                                    </div>
                                                    <div className='flex flex-row items-center cursor-pointer hover:text-white' 
                                                         onClick={() => { 
                                                            dispatch(deleteComment(track.id, comment.id)).catch(err => {
                                                                if (err?.status == 401) {
                                                                    history.push('/verify');
                                                                }
                                                            });
                                                        }}>
                                                        <DeleteIcon className='w-4 h-4 ml-2' />
                                                        <span className='ml-2 text-sm'>Delete</span>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>

                                    <CommentReplies
                                        comment={comment}
                                        sessionUser={sessionUser}
                                        track={track}
                                        replies={getCommentReplies(comment.id)}
                                    />

                                    {addingReply && commentId === comment.id ? (
                                        <AddReply track={track} comment={comment} sessionUser={sessionUser} cancelReply={cancelReply} replyUser={replyUser} />
                                    ) :
                                        null
                                    }
                                </div>
                            );
                        })}
                    </div>
                </>
            }
        </>
    );
}

export default TrackComments;
