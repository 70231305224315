import { utils } from "near-api-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import { ReactComponent as NearIcon } from '../../img/icons/icon_nm.svg'
import { ReactComponent as NearType } from '../../img/icons/near_type.svg'
import RecordStoreIcon from '../../img/icons/record-store.png';
import { assignWallet } from "../../store/session";
import { useDocumentTitle } from "../Common/useMeta";
import { getTextWidth } from '../../utils/getTextWidth';

const BalancePage = () => {
    const dispatch = useDispatch();
    const sessionUser = useSelector(state => state.session.user);
    const nearWallet = useSelector(state => state.near.wallet);
    const nearConfig = useSelector(state => state.near.config);
    const nearTipContract = useSelector(state => state.near.tipContract);    
    const marketContract = useSelector(state => state.near.marketContract);    
    const [listingFee, setListingFee] = useState(0.01);
    const [deposited, setDeposited] = useState(0);        
    const [redirect, setRedirect] = useState(false);
    const [usd, setUsd] = useState(null);
    const [actionType, setActionType] = useState('deposit');
    const [depositAmount, setDepositAmount] = useState('1');
    const [withdrawlAmount, setWithdrawlAmount] = useState(0);

    const isConnected = nearWallet?.getAccountId() != '';

    useEffect(() => {
        fetch('https://helper.mainnet.near.org/fiat')
            .then(response => {
                return response.json();
            })    
            .then(d => {    
                setUsd(d?.near?.usd);
            });
    }, []);    

    useEffect(async () => { 
        if (nearWallet) {
            if (!nearWallet.isSignedIn() || !sessionUser) {
                setRedirect(true);
            }
        }        
    }, [sessionUser, nearWallet.getAccountId()]);

    useEffect(() => {
        if (marketContract && sessionUser) {
            marketContract.storage_minimum_balance().then(f => setListingFee(f));
            marketContract.storage_balance_of({ account_id: sessionUser.nearAccount }).then(f => {setDeposited(f); setWithdrawlAmount(utils.format.formatNearAmount(f));});
        }
    }, [marketContract]);

    useDocumentTitle('Wallet Balance');    

    if (redirect) {
        return (sessionUser ? <Redirect to={`/user/${sessionUser.username.replace('?','%3F')}/wallet`} /> : <Redirect to="/" />);
    }

    const makeDeposit = (e) => {
        e.preventDefault();
        marketContract.storage_deposit({
            args: {
                account_id: nearWallet.getAccountId()
            },
            gas: 300000000000000,
            amount: utils.format.parseNearAmount(depositAmount)
        });
    }

    const makeWithdrawl = (e) => {
        e.preventDefault();
        marketContract.storage_withdraw({
            args: {},
            gas: 300000000000000,
            amount: 1
        });
    }


    return (
        <div className="w-full mx-auto absolute min-h-full pb-32 pt-24 sm:pt-0 py-5 flex-1 flex flex-col justify-center items-center bg-background2 bg-center bg-cover mt-20 sm:mt-24">
            <form className="px-6 sm:px-8 py-6 w-5/6 sm:w-1/3 sm:min-w-max mb-4">                
                <div className=''>
                    {
                        isConnected && sessionUser && 
                        <div className="text-xl text-center border text-space-cadet flex flex-col rounded p-4 bg-white glow">

                            <div className="flex flex-col items-center py-4">
                                <div>Balance</div>
                                <div className="flex items-center text-4xl">{utils.format.formatNearAmount(deposited)} <NearType className='h-4 ml-1.5' /></div>
                                { usd && <div className='text-space-cadet ml-1 text-sm'>≈ {(utils.format.formatNearAmount(deposited)*usd).toFixed(2)} USD</div>}

                                <div className='w-full sm:w-3/5 mx-auto mt-8'>
                                    <div className='flex'>
                                        <div className='flex-1 text-center cursor-pointer p-2' 
                                             style={{ backgroundColor: actionType == 'deposit' ? '#fff' : '#eee', borderTopLeftRadius: '0.25rem', borderWidth: 1, borderBottom: 'none' }}
                                             onClick={() => setActionType('deposit')}
                                        >
                                            Deposit
                                        </div>
                                        <div className='flex-1 text-center cursor-pointer p-2' 
                                             style={{ backgroundColor: actionType == 'withdrawl' ? '#fff' : '#eee', borderTopRightRadius: '0.25rem', borderWidth: 1, borderBottom: 'none' }} 
                                             onClick={() => setActionType('withdrawl')}
                                        >
                                            Withdrawl
                                        </div>
                                    </div>
                                    <div className='flex flex-col items-center flex-grow justify-center border rounded p-2 h-56' style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 'none' }}>
                                        {
                                            actionType == 'deposit' && 
                                            <>                                                
                                                <label className='text-space-cadet font-bold cursor-pointer text-xl'>Deposit Amount</label>
                                                <div className='text-space-cadet flex mb-2 text-sm'>
                                                    Listing fee per NFT is { utils.format.formatNearAmount(listingFee) } <NearType height={8} fill='#333' className='mx-1 mt-1.5' />
                                                    { usd && <div className='text-sm'>≈ {(utils.format.formatNearAmount(listingFee)*usd).toFixed(2)} USD</div>}   
                                                </div>
                                                <input type='text' 
                                                    className="block appearance-none w-full p-2 rounded text-black mt-2 mb-4 text-center" 
                                                    style={{ background: '#1d50b322', minWidth: 40, width: getTextWidth(depositAmount, '16px "NoyhRLight", sans-serif')+38 }} 
                                                    value={depositAmount} 
                                                    onChange={(e) => setDepositAmount(e.target.value.replace(/([^0-9.]+)/, ''))}
                                                    />
                                                <NearType height={12} fill='#333' />
                                                { usd && <div className='text-space-cadet ml-1 text-sm'>≈{(depositAmount*usd).toFixed(2)} USD</div>}   
                                                <button 
                                                    type='button' 
                                                    className={`w-48 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded text-xl m-0 mt-2`}
                                                    onClick={makeDeposit}
                                                >Make Deposit</button>
                                            </>
                                        }
                                        {
                                            actionType == 'withdrawl' && 
                                            <>
                                                <label className='text-space-cadet font-bold cursor-pointer text-xl'>Withdrawl Amount</label>
                                                <input type='text' 
                                                    className="block appearance-none w-full text-black mt-2 mb-2 text-2xl text-center" 
                                                    style={{minWidth: 40, width: getTextWidth(withdrawlAmount, '1.25rem "NoyhRLight", sans-serif')+38 }} 
                                                    value={withdrawlAmount} 
                                                    readOnly={true}
                                                    disabled={true}                                                    
                                                    />
                                                <NearType height={12} fill='#333' className='mb-2' />
                                                { usd && <div className='text-space-cadet ml-1 text-sm'>≈{(withdrawlAmount*usd).toFixed(2)} USD</div>}   
                                                <button 
                                                    type='button' 
                                                    className={`w-48 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded text-xl m-0 mt-2`}
                                                    onClick={makeWithdrawl}
                                                >Make Withdrawl</button>
                                            </>
                                        }
                                    </div>
                                </div>

                                <Link 
                                    to={`/user/${sessionUser.username.replace('?','%3F')}/wallet`}
                                    className={`w-48 bg-white text-space-cadet py-2 px-4 rounded border text-xl m-0 mt-6`}
                                >Disconnect Wallet</Link>

                            </div>
                            
                        </div>
                    }                    
                </div>
            </form>            
            <Link to={'/user/'+sessionUser?.username} className='flex flex-col items-center text-xl'><img src={RecordStoreIcon} width={30} className='mb-2' /> Back to profile</Link>
        </div>
    );
}

export default BalancePage;