import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import configureStore from './store';
import { restoreCSRF, fetch } from './store/csrf';
import * as sessionActions from './store/session';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import { PersistGate } from 'redux-persist/integration/react'


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const instance = createInstance({
  urlBase: process.env.REACT_APP_MATOMO_URL || 'https://stats.tamastream.online/',
  siteId: process.env.REACT_APP_MATOMO_SITEID || '3'
})

const { store, persistor } = configureStore();

if (process.env.NODE_ENV !== 'production') {
  restoreCSRF();
  window.csrfFetch = fetch;
  window.store = store;
  window.sessionActions = sessionActions;
}
else { // TODO: discuss with Ryan. Do we want this in production?
  restoreCSRF();
  window.csrfFetch = fetch;
  window.store = store;
  window.sessionActions = sessionActions;
}

const Root = () => {
  return (
    <MatomoProvider value={instance}>      
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>          
      </MatomoProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
