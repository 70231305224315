import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addReply } from '../../store/track';

const AddReply = ({ track, comment, sessionUser, cancelReply, replyUser }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [content, setContent] = useState();

  const handleSubmit = async () => {

    if (sessionUser.accountStatus != 'ok') {
      history.push('/verify');
      return;
    }

    // append replyUser to content
    const newContent = `@${replyUser} ${content}`;

    const newComment = {
      content: newContent,
      userId: sessionUser.id,
      trackId: track.id,
      trackAt: comment.trackAt,
      parentId: comment.id
    }

    dispatch(addReply(track.id, newComment)).catch(err => {
      if (err?.status == 401) {
          history.push('/verify');
      }
    });

    setContent('');
    cancelReply();
  };

  return (
    <>
      {track.User && sessionUser &&
        <>
          <div className='w-full ml-6'>
            <form className="flex flex-row flex-wrap sm:flex-nowrap items-center">
              <textarea
                placeholder='Reply...'
                value={content}
                onChange={(e) => setContent(e.target.value)}
                required
                className="appearance-none bg-transparent flex-grow px-2 py-2 h-10 sm:mr-4 shadow text-white border-independence border-b leading-tight focus:outline-none focus:shadow-outline"
              />
              <button
                onClick={() => handleSubmit()}
                type='button'
                className="flex-shrink-0 border-transparent border-4 text-white bg-mandarin hover:bg-mandarin-dark font-medium text-sm py-1 px-2 rounded">
                Post
              </button>
              <button
                className="flex-shrink-0 border-transparent border-4 text-mandarin hover:text-white text-md font-medium py-1 px-2 rounded"
                type="button"
                onClick={cancelReply}
              >
                Cancel
              </button>
            </form>
          </div>
        </>
      }
    </>
  );
}

export default AddReply;
