import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { useDocumentTitle } from '../Common/useMeta';
import TamagoLogo from '../../img/tamago.svg';
import AWMLogo from '../../img/icons/awmlogowhite.svg'

const LiveEvent = () => {    
    const sessionUser = useSelector(state => state.session.user);

    useDocumentTitle('The Love Boat Party');

    return (
      <>
      
        <div className='pt-10 flex-1 pb-20 text-center' style={{background: 'radial-gradient(circle, rgb(35, 35, 50) 0%, rgb(53, 53, 85) 0%, rgb(35, 35, 50) 100%)', minHeight: '100vh'}}>      
            <div className='h-24 sm:h-48'></div>

            <div className="flex justify-center">
                <img src={TamagoLogo} className=' w-24 sm:w-36' />                
            </div>

            <div className="mt-8 mb-2 italic "><span className="">Tamago</span> and <img src={AWMLogo} className='mx-1 h-2.5 inline'  /> Presents</div>
            <div className="text-6xl text-tama-green">The Love Boat</div>
            <div className="text-xl ">A Benefit for Save The Children Ukraine (MMW)</div>
            <a href="https://support.savethechildren.org/site/TR?px=5877423&fr_id=1541&pg=personal" className="text-xl mb-6 text-tama-green mb-6 block">Donate Now!</a>

            <div className="text-xl">Friday March 25th, 2022</div>
            <div className="text-xl mb-6">Miami Music Week Boat Party</div>
            {
                sessionUser && <div>
                    <div className="text-xl">Ticket password is</div>
                    <div className="text-3xl mb-8">loveandunity</div>                    
                    
                    <a href="http://www.ticketfairy.com/r/12876/1065829" className={`w-72 sm:w-2/6 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded text-xl m-0`}>Get your TICKET</a>

                    <div className="text-xl mb-2 mt-6">$30 suggested donation</div>
                    <a href="https://support.savethechildren.org/site/TR?px=5877423&fr_id=1541&pg=personal" className="text-xl mb-6 text-tama-green mb-6 block">Donate Now!</a><br />
                </div>
            }
            {
                !sessionUser && <div>
                    <div className="text-xl mb-8"><span className="text-tama-green">Sign up</span> for Tamago to get our FREE TICKET</div>
                    <Link to='/signup' className={`w-72 sm:w-2/6 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded text-xl m-0`}>Sign Up</Link>
                </div>
            }
        </div>
      </>
    )
}

export default LiveEvent;