import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as nearAPI from 'near-api-js';
import { ReactComponent as NearIcon } from '../../img/icons/icon_nm.svg'
import { ReactComponent as NearType } from '../../img/icons/near_type.svg'
import RecordStoreIcon from '../../img/icons/record-store.png';
import { assignWallet } from "../../store/session";
import { useQuery } from "../../utils/useQuery";
import { useDocumentTitle } from "../Common/useMeta";

const WalletPage = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const sessionUser = useSelector(state => state.session.user);
    const nearConnection = useSelector(state => state.near.api);
    const nearWallet = useSelector(state => state.near.wallet);
    const nearConfig = useSelector(state => state.near.config);
    const nearTipContract = useSelector(state => state.near.tipContract);    
    const [redirectOnConnect, setRedirectOnConnect] = useState(null);

    const isConnected = nearWallet?.getAccountId() != '';

    useEffect(() => {
        if (query && query.get('redirect') ) {            
            setRedirectOnConnect(query.get('redirect'));
        }
    }, [query]);

    useEffect(async () => { 
        if (sessionUser?.id && nearWallet?.getAccountId() ) { //} && nearWallet.getAccountId() != sessionUser.nearAccount ) {
            const nearAccount = nearWallet.account();            
            const keyPair = await nearConnection.config.keyStore.getKey(nearConnection.config.networkId, nearAccount.accountId);
            if (keyPair) {
                const { signature } = keyPair.sign(Buffer.from(nearAccount.accountId+"||Tamago"));            

                dispatch(assignWallet({
                    walletId: nearAccount.accountId,
                    pk: keyPair.publicKey.toString(),
                    signature: Buffer.from(signature).toString('base64')
                }));            
            }
        }
    }, [sessionUser.id, nearWallet.getAccountId()]);

    const connectWallet = () => {
        nearWallet.requestSignIn(
            nearConfig.logIntoAccount, 
            'Tamago',
            redirectOnConnect, 
            null
        );
    }    

    const disconnectWallet = () => {
        nearWallet.signOut();
        dispatch(assignWallet({ walletId: null }));
    }    

    useDocumentTitle('Wallet');    
    
    return (
        <div className="w-full mx-auto absolute min-h-full pb-32 pt-24 sm:pt-0 py-5 flex-1 flex flex-col justify-center items-center bg-background2 bg-center bg-cover mt-20 sm:mt-24">
            <form className="px-6 sm:px-8 py-6 w-5/6 sm:w-1/3 sm:min-w-max mb-4">
                <div className=''>
                    {
                        isConnected && <div className="text-xl text-center border text-white flex flex-col rounded p-4" style={{background: 'linear-gradient(235deg, #423f9c 31%, #862359 69%)', filter: 'drop-shadow(0 0 400px rgba(114, 124, 211, 0.6))'}}>
                            { sessionUser.nearAccount && <>
                                <NearIcon height={60} className='my-4 mx-auto' fill="#fff" />
                                <h1 className="text-2xl mb-4">Connected Successfully</h1>
                                <div className="mb-4">Your Tamago and NEAR account<br />{ sessionUser.nearAccount }<br />have been successfully linked</div>
                                
                              </> 
                            }
                            <div onClick={disconnectWallet} className="flex items-center justify-center rounded border hover:bg-white hover:text-black w-48 px-4 py-2 cursor-pointer mx-auto mt-2">
                                Disconnect Wallet
                            </div>
                            <Link to={`/user/${sessionUser.username.replace('?','%3F')}/wallet/balance`} className="flex items-center justify-center rounded border hover:bg-white hover:text-black w-48 px-4 py-2 cursor-pointer mx-auto mt-4">
                                Balance
                            </Link>
                        </div>
                    }
                    {
                        !isConnected && 
                        <div className="">                            
                            <div onClick={connectWallet} className="flex items-center justify-center text-white rounded border px-4 py-2 cursor-pointer w-52 mx-auto" style={{background: 'linear-gradient(235deg, #423f9c 31%, #862359 69%)', filter: 'drop-shadow(0 0 400px rgba(114, 124, 211, 0.6))', width: 215}}>
                                <NearIcon height={20} className='mr-2' fill='#fff' /> <span className="text-2xl">Connect Wallet</span>
                            </div>
                            { sessionUser.nearAccount && false && <>
                                <div className="mt-8 mb-1 text-center flex items-center justify-center text-xl"><NearType fill="#fff" height={10} className='mr-1' /> sent to your Tamago account will be sent to</div>
                                <div className="text-center text-xl">{ sessionUser.nearAccount }</div>
                              </> 
                            }
                        </div>
                    }
                </div>
            </form>            
            <Link to={'/user/'+sessionUser.username} className='flex flex-col items-center text-xl'><img src={RecordStoreIcon} width={30} className='mb-2' /> Back to profile</Link>
        </div>
    );
}

export default WalletPage;