import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { postComment } from '../../store/track';

const CommentNew = ({ track, sessionUser }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [content, setContent] = useState('');
    const currentTrack = useSelector(state => state.player.currentTrack);
    const isPlaying = useSelector(state => state.player.isPlaying);
    const player = useSelector(state => state.player.wavesurfer);


    const handleSubmit = async (e) => {

        if (sessionUser.accountStatus != 'ok') {
            history.push('/verify');
            return;
        } 
        
        e.preventDefault();
        const trackAt = isPlaying && player && currentTrack && currentTrack.id == track.id ? player.getCurrentTime() : null;
        const newComment = {
            content,
            userId: sessionUser.id,
            trackId: track.id,
            trackAt,
            parentId: 0
        }

        dispatch(postComment(track.id, newComment));

        setContent('');
    };

    return (
        <>
            {track.User && sessionUser &&
                <>
                    <div className='self-center'>
                        <form onSubmit={handleSubmit} className="flex flex-row flex-wrap sm:flex-nowrap border-independence items-center rounded-lg">
                            <img src={sessionUser.avatarUrl} alt='User' className='h-8 w-8 mr-4 rounded-full withOutline'></img>
                            <textarea
                                placeholder='Post a comment'
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                required
                                className="appearance-none flex-grow px-2 py-2 h-10 sm:mr-4 rounded shadow text-black"
                            />
                            <button type='submit' className="bg-mandarin w-full sm:w-auto hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded my-0 mt-3 sm:mt-0">Comment</button>
                        </form>
                    </div>
                </>
            }
        </>
    );
}

export default CommentNew;
