import { fetch } from './csrf';
import configData from '../config.json'

const SET_GENRES = 'ui/setGenres';
const REMOVE_GENRES = 'ui/removeGenres';
const SET_TAGS = 'ui/setTags';
const REMOVE_TAGS = 'ui/removeTags';

const setGenres = (genres) => {
    return {
        type: SET_GENRES,
        payload: genres
    };
};

export const removeGenres = () => {
    return {
        type: REMOVE_GENRES,
    };
};

export const getGenres = () => async(dispatch) => {
    const res = await fetch(configData.API_URL + '/api/genres')
    dispatch(setGenres(res.data.genres));
    return res;
};

const setTags = (tags) => {
    return {
        type: SET_TAGS,
        payload: tags
    };
};

export const removeTags = () => {
    return {
        type: REMOVE_TAGS,
    };
};

export const getTags = () => async(dispatch) => {
    const res = await fetch(configData.API_URL + '/api/tags')
    dispatch(setTags(res.data.tags));
    return res;
};

const initialState = { genres: [], tags: [] };

export default function uiReducer(state = initialState, action) {
    let newState;
    switch (action.type) {
        case SET_GENRES:
            newState = Object.assign({}, state);
            newState.genres = action.payload;
            return newState;
        case REMOVE_GENRES:
            newState = Object.assign({}, state);
            newState.genres = [];
            return newState;
        case SET_TAGS:
            newState = Object.assign({}, state);
            newState.tags = action.payload;
            return newState;
        case REMOVE_TAGS:
            newState = Object.assign({}, state);
            newState.tags = [];
            return newState;
        default:
            return state;
    };
};