import { fetch } from './csrf';
import configData from '../config.json'
import { SELECT_TRACK } from './track'
import { arrayMove } from '@dnd-kit/sortable';

const SET_TRACK = 'player/setTrack';
const SET_NEXT_TRACK = 'player/setNextTrack';
const UNLOAD_TRACK = 'player/unloadTrack';
const PLAY_TRACK = 'player/playAudioTrack';
const STOP_TRACK = 'player/stopTrack';
const SET_WAVESURFER = 'player/setWaveSurfer';
const SET_ISTRACKLOADING = 'player/isTrackLoading';
const ADD_TO_QUEUE = 'player/addToQueue';
const REMOVE_FROM_QUEUE = 'player/removeFromQueue';
const REORDER_QUEUE_TRACK = 'player/reorderQueueTrack';
const CLEAR_QUEUE = 'player/clearQueue';

let playTimeout = null;

export const setIsTrackLoading = (isTrackLoading) => {
    return {
        type: SET_ISTRACKLOADING,
        payload: isTrackLoading
    }
}

export const setWaveSurfer = (wavesurfer) => {
    if (wavesurfer) wavesurfer.pause();
    return {
        type: SET_WAVESURFER,
        payload: wavesurfer,
    };
};

const setTrack = (track) => {
    return {
        type: SET_TRACK,
        payload: track,
    };
};

export const setNextTrack = (track) => {
    return {
        type: SET_NEXT_TRACK,
        payload: track,
    };
};

export const unloadTrack = () => {
    clearTimeout(playTimeout);
    return {
        type: UNLOAD_TRACK,
    }
};

export const playAudioTrack = () => {
    const currentState = window.store.getState();
    if (currentState.player &&
        currentState.player.wavesurfer &&
        currentState.player.wavesurfer.getCurrentTime() === 0 &&
        currentState.player.currentTrack
    ) {
        clearTimeout(playTimeout);
        playTimeout = setTimeout(() => {
            fetch(configData.API_URL + `/api/tracks/${currentState.player.currentTrack.id}/play`);
        }, 10000);
    }
    return {
        type: PLAY_TRACK,
    }
};

export const stopTrack = () => {
    clearTimeout(playTimeout);
    return {
        type: STOP_TRACK,
    }
};

export const playTrack = (id) => async (dispatch) => {
    const res = await fetch(configData.API_URL + `/api/tracks/${id}`);
    dispatch(setTrack(res.data.track));
    return res;
}

export const addToQueue = (track) => {
    return {
        type: ADD_TO_QUEUE,
        payload: track,
    }
}

// clear queue
export const clearQueue = () => {
    return {
        type: CLEAR_QUEUE        
    }
}

export const removeFromQueue = (track) => {
    return {
        type: REMOVE_FROM_QUEUE,
        payload: track,
    }
}

export const moveInQueue = (fromIndex, toIndex) => {
    return {
        type: REORDER_QUEUE_TRACK,
        payload: { fromIndex, toIndex }
    }
}

const initialState = { currentTrack: null, nextTrack: null, queue: [], isPlaying: false, isTrackLoading: false, playlist: null };

export default function playerReducer(state = initialState, action) {
    let newState;
    switch (action.type) {
        case CLEAR_QUEUE:
            newState = Object.assign({}, state);
            newState.queue = [];
            return newState;            
        case REORDER_QUEUE_TRACK:
            newState = Object.assign({}, state);
            newState.queue = arrayMove(newState.queue, action.payload.fromIndex, action.payload.toIndex);
            return newState;
        case ADD_TO_QUEUE:
            newState = Object.assign({}, state);
            newState.queue.push(action.payload);
            return newState;
        case REMOVE_FROM_QUEUE:
            newState = Object.assign({}, state);
            newState.queue = newState.queue.filter(track => track.id !== action.payload.id);
            return newState;
        case SET_ISTRACKLOADING:
            newState = Object.assign({}, state);
            newState.isTrackLoading = action.payload;
            return newState;
        case SELECT_TRACK:
            newState = Object.assign({}, state);
            if (newState.currentTrack && newState.currentTrack.id === action.payload.id) {
                newState.currentTrack = action.payload;
            }
            return newState;
        case SET_TRACK:
            newState = Object.assign({}, state);
            newState.currentTrack = action.payload;
            return newState;
        case SET_NEXT_TRACK:
            newState = Object.assign({}, state);
            newState.nextTrack = action.payload;
            return newState;
        case UNLOAD_TRACK:
            newState = Object.assign({}, state);
            newState.currentTrack = null;
            return newState;
        case PLAY_TRACK:
            newState = Object.assign({}, state);
            newState.isPlaying = true;
            return newState;
        case STOP_TRACK:
            newState = Object.assign({}, state);
            newState.isPlaying = false;
            return newState;
        case SET_WAVESURFER:
            newState = Object.assign({}, state);
            newState.wavesurfer = action.payload;
            return newState;
        default:
            return state;
    };
};
