export const timeSince = (date, truncated) => {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + `${truncated ? " y" : " years"}`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + `${truncated ? " mnths" : " months"}`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + `${truncated ? " d" : " days"}`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + `${truncated ? " h" : " hours"}`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + `${truncated ? " m" : " minutes"}`;
  }
  return Math.floor(seconds) + `${truncated ? " s" : " secs"}`;
}