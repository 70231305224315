import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { postFollow, deleteFollow } from '../../store/session';
import SkullIcon from '../../img/icons/skullz.png'
import BombIcon from '../../img/icons/bomb.png'
import ChestIcon from '../../img/icons/chest.png'
import { ReactComponent as TreasureIcon } from '../../img/icons/treasure-chest-svgrepo-com.svg'
import { ReactComponent as NearType } from '../../img/icons/near_type.svg'
import { useEffect, useState } from 'react';
import * as nearAPI from 'near-api-js';
import { formatNumbers } from '../../utils/formatNumbers';
import { timeSince } from '../../utils/getTimeSince';
import Tooltip from '../Common/Tooltip';

const UserMainInfo = ({ user, userActivity }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { utils } = nearAPI;
    const sessionUser = useSelector(state => state.session.user);
    const tipContract = useSelector(state => state.near.tipContract);
    const [tips, setTips] = useState({ count: 0, total: 0 });
    const [tipsSent, setTipsSent] = useState({ count: 0, total: 0 });

    const getTips = async () => {
        if (user && user.nearAccount && tipContract) {
            const tips = await tipContract.getTipsReceivedTotal({ receiver: user.nearAccount });
            if (tips.success && tips.data) {
                setTips(tips.data);
            }
            const tipsSent = await tipContract.getTipsSentTotal({ sender: user.nearAccount });
            if (tipsSent.success && tipsSent.data) {
                setTipsSent(tipsSent.data);
            }
        }
    }
    useEffect(() => {
        getTips();
    }, [user, tipContract]);

    const following = sessionUser && sessionUser.following && sessionUser.following.find(curVal => curVal.followUserId === user.id)

    const follow = async () => {
        if (sessionUser) {
            if (following)
                dispatch(deleteFollow(user.id));
            else
                dispatch(postFollow(user.id));
        } else {
            history.push('/login');
        }
    }

    const allPlays = user?.Tracks?.rows.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.playCount
    }, 0)

    const bombCount = user?.Tracks?.rows.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.Reactions.filter(reaction => reaction.reactionType === 'bomb').length
    }, 0)

    const skullCount = user?.Tracks?.rows.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.Reactions.filter(reaction => reaction.reactionType === 'skull').length
    }, 0)

    const chestCount = user?.Tracks?.rows.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.Reactions.filter(reaction => reaction.reactionType === 'gem').length
    }, 0)


    const tracksCount = user.Tracks.rows?.filter(track => track.tokenUserId == user.id || track.tokenUserId == null).length;

    return (
        <>
            {user && (
                <div className=''>
                    <div className='flex flex-col lg:flex-row flex-nowrap m-4 sm:m-10 p-2 sm:p-8 rounded' style={{ background: '#ffffff06' }}>

                        <div className="order-2 lg:order-none flex-1 sm:pr-6 mt-6 sm:mt-0 flex flex-col">
                            <div className='flex-grow flex items-center lg:items-start mb-2'>
                                <Link to={'/user/' + sessionUser?.username + '/wallet'}><TreasureIcon className='w-16 sm:w-24 xl:w-28 ml-2 sm:ml-0 mr-7' /></Link>
                                <div className='flex flex-col text-xl'>
                                    <div>Activity: <span className='text-tama-green'>{userActivity && (userActivity.filter(ele => ele.activityType === 'Reaction').length + userActivity.filter(ele => ele.activityType === 'Comment').length)}</span></div>
                                    <div>Loot: <span className='text-tama-green'>{Math.floor((parseFloat(utils.format.formatNearAmount(tips.total)) * 0.97) * 100) / 100}</span> <NearType fill="#fff" height={10} className="inline mr-0.5" style={{ marginBottom: '3px' }} /></div>
                                    <div>Contributions: <span className='whitespace-nowrap'><span className='text-tama-green'>{Math.floor((parseFloat(utils.format.formatNearAmount(tipsSent.total))) * 100) / 100}</span> <NearType fill="#fff" height={10} className="inline mr-0.5" style={{ marginBottom: '3px' }} /></span></div>
                                </div>
                            </div>
                            <div className='grid grid-cols-4 my-4 sm:my-0'>
                                <div className="text-white text-center justify-center text-xl">
                                    <i className={`fas fa-play fa-lg my-4`} style={{ fontSize: '24px', lineHeight: '8px' }}></i>
                                    <div className='text-2xl text-tama-green' data-tip data-for='playCount'>
                                        {formatNumbers(allPlays)}
                                    </div>
                                    {allPlays > 1000 && <Tooltip id='playCount' value={allPlays} />}
                                </div>

                                <div className="text-white text-center justify-center text-xl">
                                    <img src={BombIcon} alt="bomb-icon" className='h-8 mb-2 inline' />
                                    <div className='text-2xl text-tama-green' data-tip data-for='bombCount'>
                                        {formatNumbers(bombCount)}
                                    </div>
                                    {bombCount > 1000 && <Tooltip id='bombCount' value={bombCount} />}
                                </div>

                                <div className="text-white text-center justify-center text-xl">
                                    <img src={SkullIcon} alt="skull-icon" className='h-8 mb-2 inline' />
                                    <div className='text-2xl text-tama-green' data-tip data-for='skullCount'>
                                        {formatNumbers(skullCount)}
                                    </div>
                                    {skullCount > 1000 && <Tooltip id='skullCount' value={skullCount} />}
                                </div>

                                <div className="text-white text-center justify-center text-xl">
                                    <img src={ChestIcon} alt="chest-icon" className='h-8 mb-2 inline' />
                                    <div className='text-2xl text-tama-green' data-tip data-for='chestCount'>
                                        {formatNumbers(chestCount)}
                                    </div>
                                    {chestCount > 1000 && <Tooltip id='chestCount' value={chestCount} />}
                                </div>
                            </div>
                        </div>

                        <div style={{ background: '#ffffff08', borderRadius: '5px' }} className="flex-none flex flex-col sm:flex-row mx-2 pt-6 sm:p-6 items-center sm:items-start mb-2">
                            <img src={user.avatarUrl} alt='User' className='w-60 h-60 shadow-2xl rounded-full'></img>
                            <div className="flex-grow flex flex-col sm:pl-10 w-60 items-center sm:items-start pt-2 sm:pt-0">
                                <div className="text-4xl text-mandarin sm:pb-3">{`${user.username}`}</div>
                                {
                                    sessionUser && sessionUser.id === user.id && <Link to={`/profile`} className="bg-mandarin-dark text-white hover:bg-mandarin-dark hover:text-white mb-2 text-center font-bold py-1 rounded cursor-pointer w-32">Edit Profile</Link>
                                }
                                {
                                    (sessionUser && sessionUser.id === user.id) ? '' :
                                        <button onClick={follow} type='button' className={`${following ? '' : ''} bg-mandarin-dark hover:font-bold hover:bg-mandarin-dark hover:opacity-100 text-white py-2 px-4 rounded my-2`}>
                                            <i className="fas fa-headphones  mr-2"></i>
                                            {following ? 'Unfollow' : 'Follow'} Artist
                                        </button>
                                }
                                <div className="text-silver pb-3 flex-grow flex items-end">Joined {timeSince(Date.parse(user.createdAt))} ago</div>
                            </div>
                        </div>

                        <div className="flex-1 grid grid-cols-3 pt-4 sm:pt-0 sm:pl-6 mb-4">

                            <div className="text-white text-center text-xl">
                                Followers<br />
                                <span className='text-2xl text-tama-green' data-tip data-for='followers'>{formatNumbers(user.followers.length)}</span>
                                {user.followers.length > 1000 && <Tooltip id='followers' value={user.followers.length} />}
                            </div>
                            <div className="text-white text-center text-xl">
                                Following<br />
                                <span className='text-2xl text-tama-green' data-tip data-for='following'>{formatNumbers(user.following.length)}</span>
                                {user.following.length > 1000 && <Tooltip id='following' value={user.following.length} />}
                            </div>
                            <div className="text-white text-center text-xl">
                                Tracks<br />
                                <span className='text-2xl text-tama-green' data-tip data-for='tracks'>{formatNumbers(tracksCount)}</span>
                                {tracksCount > 1000 && <Tooltip id='tracks' value={tracksCount} />}
                            </div>

                        </div>

                    </div>
                </div>
            )}
        </>
    );
}

export default UserMainInfo;
