import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { useDocumentTitle } from '../Common/useMeta';
import TamagoLogo from '../../img/tamago.svg';
import TopClip from '../../img/events/ega-2022/1.png'
import TriangleClip from '../../img/events/ega-2022/2.png'
import BottomClip1 from '../../img/events/ega-2022/3.png'
import BottomClip2 from '../../img/events/ega-2022/4.png'
import Clip1 from '../../img/events/ega-2022/5.png'
import EGClip1 from '../../img/events/ega-2022/eg.png'
import EGClip2 from '../../img/events/ega-2022/playing.png'
import Follow1 from '../../img/events/ega-2022/follow-listed.png'
import Follow2 from '../../img/events/ega-2022/follow-eg.png'

const ElectonicGrooveEvent = () => {    
    const sessionUser = useSelector(state => state.session.user);

    useDocumentTitle('The 15th Year Anniversary of Electronic Groove');

    return (
      <div style={{background: '#000000'}}>
        <div className="absolute text-center w-full justify-center flex">
            <img src={TopClip} alt='Top Clip' style={{maxWidth: '100%'}} />            
        </div>
        <img src={BottomClip1}  style={{maxWidth: '30%', position: 'absolute', left: 0, bottom: 0, maxHeight: '40%'}} />
        <img src={BottomClip2}  style={{maxWidth: '50%', position: 'absolute', right: 0, bottom: 0}} />
        <img src={TriangleClip} className="-ml-32 sm:ml-0 w-32 sm:w-48 "  style={{ maxWidth: 150, position: 'absolute', left: '10%', top: '30%'}} />
        <img src={TriangleClip} className="-mr-32 sm:mr-0 w-32 sm:w-48 " style={{ maxWidth: 150, position: 'absolute', right: '10%', top: '30%', transform: 'scaleX(-1)'}} />
        <img src={Clip1} className="-ml-32 sm:ml-0 w-48 sm:w-64 opacity-40 sm:opacity-100 " style={{ position: 'absolute', left: 0, top: '40%', minWidth: 250}} />
        <div className='p-8 sm:p-0 pt-10 flex-1 pb-20 text-center' style={{minHeight: '100vh', zIndex: 1, position: 'relative'}}>                  
            <div className='h-14 sm:h-24'></div>
            <div className="mt-8 mb-2 text-2xl"><span style={{fontWeight: 800}}>LISTED AND TAMAGO</span> PRESENTS</div>
            <div className="text-2xl" style={{lineHeight: 1}}>THE 15<span style={{fontSize: 16}}>th</span> YEAR</div>
            <div className="text-4xl sm:text-5xl" style={{fontWeight: 800, lineHeight: 1}}>ANNIVERSARY OF</div>
            <img src={EGClip1} className="mt-3 max-w-full px-8 sm:px-0" style={{marginLeft: 'auto', marginRight: 'auto', width: 300}} />
            <img src={EGClip2} className="mt-8 max-w-full px-8 sm:px-0" style={{marginLeft: 'auto', marginRight: 'auto', width: 400}} />
            {
                /*<a href="https://support.savethechildren.org/site/TR?px=5877423&fr_id=1541&pg=personal" className="text-xl mb-6 text-tama-green mb-6 block">Donate Now!</a>*/
            }

            <div className="text-xl sm:text-2xl mt-6" style={{letterSpacing: 7}}>OCT 20TH, 2022 | 8PM </div>
            <div className="text-xl sm:text-2xl mb-6" style={{letterSpacing: 2, fontWeight: 800}}>DUKE OF TOYKO - AMSTERDAM</div>
            <div className="text-xl sm:text-2xl mb-6" style={{letterSpacing: 2, fontWeight: 800}}>A FUNDRAISER EVENT FOR THE CHILDREN OF UKRAINE</div>


            <div className="text-xl mb-2 mt-6 mb-4">Proof of donation required<br />Suggested donation of $30</div>
            <a target="_blank" href="https://support.savethechildren.org/site/TR/BecomeaFundraiser/General?px=6155692&pg=personal&fr_id=1571" className="text-xl text-white font-bold py-2 px-4 rounded text-xl m-0 mt-4 mb-6" style={{backgroundColor: '#1a59a0'}}>Donate Now!</a><br />

            {
                sessionUser && <div>
                    <div className="text-xl mt-8">Ticket password is</div>
                    <div className="text-3xl mb-4">EGADE2022</div>                    
                    
                    <a href="https://www.ticketfairy.com/event/the-15th-anniversary-of-electronic-groove-by-tamago-and-listed-20oct2022/?pk=e6f325366d88858f6768c00214c0444f62d25360944242769432609f3bf627f9" className={`text-xl bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded text-xl m-0 mt-4 mb-6`}>Get your TICKET</a>
                    
                </div>
            }
            {
                !sessionUser && <div>
                    <div className="text-xl mt-8 mb-4"><span className="text-tama-green">Sign up</span> for Tamago to get your TICKET</div>
                    <Link to='/signup' className={`w-72 sm:w-2/6 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded text-xl m-0`}>Sign Up</Link>
                </div>
            }

            <div className="mt-12">
                <div className="text-2xl">Connect for more</div>
                <div className="flex justify-center gap-4 mt-2">
                    <a href="#"><img className="w-16" src={Follow1} alt="Follow Listed" /></a>
                    <a href="#"><img className="w-16" src={Follow2} alt="Follow Electronic Groove" /></a>
                </div>
            </div>
        </div>
      </div>
    )
}

export default ElectonicGrooveEvent;