import { fetch } from './csrf';
import axios from "axios";
import configData from '../config.json'
import Cookies from 'js-cookie';

const ADD_TRACK = 'track/addTrack';
export const SELECT_TRACK = 'track/selectTrack';
const CLEAR_TRACK = 'track/clearTrack';
const SET_TRACKS = 'track/setTracks';
const ADD_TRACKS = 'track/addTracks';
const SET_LIST_TYPE = 'track/setTrackListType';
const SET_GENRE = 'track/setGenre';
const UPLOAD_PROGRESS = 'track/uploadProgress';
const SET_UPLOAD_INFO = 'track/setUploadInfo';
const SET_UPLOAD_ERRORS = 'track/setUploadErrors';
const CLEAR_UPLOAD_INFO = 'track/clearUploadInfo';
const SET_UPLOAD_MS_REMAINING = 'track/uploadMSRemaining';
const GET_TIPS = 'track/getTips';
export const ADD_REACTION = 'track/addReaction';
export const DELETE_REACTION = 'track/deleteReaction';

const addTrack = (track) => {
    return {
        type: ADD_TRACK,
        payload: track,
    };
};

const selectTrack = (track) => {
    return {
        type: SELECT_TRACK,
        payload: track,
    };
};

const setTracks = (tracks) => {
    return {
        type: SET_TRACKS,
        payload: tracks,
    };
};

const addTracks = (tracks) => {
    return {
        type: ADD_TRACKS,
        payload: tracks,
    };
};

const setListType = (type) => {
    return {
        type: SET_LIST_TYPE,
        payload: type,
    };
};

const setGenre = (genre) => {
    return {
        type: SET_GENRE,
        payload: genre,
    };
};

const addReaction = (reaction) => {
    return {
        type: ADD_REACTION,
        payload: reaction,
    };
}

const removeReaction = (reaction) => {
    return {
        type: DELETE_REACTION,
        payload: reaction,
    };
}

const setUploadInfo = (data) => {
    return {
        type: SET_UPLOAD_INFO,
        payload: data,
    };
};

export const setUploadErrors = (data) => {
    return {
        type: SET_UPLOAD_ERRORS,
        payload: data,
    };
};

const setUploadMSRemaining = (ms) => {
    return {
        type: SET_UPLOAD_MS_REMAINING,
        payload: ms,
    };
}

export const clearUploadInfo = () => {
    return {
        type: CLEAR_UPLOAD_INFO,
        payload: null,
    };
};

export const uploadProgress = (progress) => {
    return {
        type: UPLOAD_PROGRESS,
        payload: progress,
    };
}

const clearTrack = (id) => {
    return {
        type: CLEAR_TRACK,
        payload: id
    };
};

export const newTrack = (track) => async (dispatch) => {
    const { title, description, imageUrl, trackFile, userId, genreId, newGenre, peakData, duration, tags, downloadPrice, downloadFile, downloadFilename, downloadContentType, downloadDescription } = track;

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("imageUrl", imageUrl);
    formData.append("userId", userId);
    formData.append("genreId", genreId);
    formData.append("newGenre", newGenre);
    formData.append("peakData", peakData);
    formData.append("duration", duration);
    formData.append("tags", tags);
    formData.append("downloadPrice", downloadPrice);

    if (trackFile) formData.append("audio", trackFile);

    if (downloadPrice != null && downloadPrice != 'null') {
        formData.append("downloadDescription", downloadDescription);
        formData.append("downloadFile", downloadFile);
        formData.append("downloadFilename", downloadFilename);
        formData.append("downloadContentType", downloadContentType);
    }

    const fd = Object.fromEntries(formData);
    dispatch(setUploadInfo(fd));
    dispatch(setUploadErrors([]));

    let lastTimestamp = null;
    let lastUpdate = null;
    let lastLoadedBytes = null;
    let remaining = [];
    const res = await axios.post(configData.API_URL + '/api/tracks', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "XSRF-Token": Cookies.get("XSRF-TOKEN")
        },
        onUploadProgress: progressEvent => {
            dispatch(uploadProgress(progressEvent));
            const now = new Date();
            if (lastTimestamp) {
                const bytesInChunk = progressEvent.loaded - lastLoadedBytes;
                const bytesRemaining = progressEvent.total - progressEvent.loaded;
                const chunksRemaining = bytesRemaining / bytesInChunk;
                const timeSince = now - lastTimestamp;

                // average last 100 measurements
                remaining.push(timeSince / bytesInChunk); // time per byte
                if (remaining.length > 1000) remaining.shift();
                if (now - lastUpdate > 1000) { // only update redux every second
                    dispatch(setUploadMSRemaining(remaining.reduce((a, b) => a + b, 0) / remaining.length * bytesRemaining));
                    lastUpdate = now;
                }
            }
            lastTimestamp = now;
            lastLoadedBytes = progressEvent.loaded;
        }
    }).catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 500) {
                dispatch(setUploadErrors(['Error: ' + error.response.data.title]));
            } else if (error.response.status === 401 || error.response.status === 403) {
                dispatch(setUploadErrors(['An error occured during upload.  Please refresh and try again.']));
            } else {
                dispatch(setUploadErrors(['Error (' + error.response.status + '): ' + error.response.data.title]));
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            dispatch(setUploadErrors(['Error']));
        } else {
            // Something happened in setting up the request that triggered an Error              
            dispatch(setUploadErrors(['Error: ' + error.message]));
        }

        dispatch(uploadProgress(null));
        return;
    });

    dispatch(uploadProgress(null));

    if (res?.data?.track) {
        fd.id = res.data.track.id;
        fd.success = true;
        dispatch(setUploadInfo(fd));
        dispatch(addTrack(res.data.track));
    }

    return res?.data?.track;
};

export const editTrack = (trackId, track) => async (dispatch) => {
    /*
    const res = await fetch(configData.API_URL + `/api/tracks/${trackId}`, {
        method: 'PATCH',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ track }),
    });
    */

    const { title, description, imageUrl, trackFile, userId, genreId, newGenre, peakData, duration, tags, downloadPrice, downloadFile, downloadFilename, downloadContentType, downloadDescription } = track;

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("imageUrl", imageUrl);
    formData.append("userId", userId);
    formData.append("genreId", genreId);
    formData.append("newGenre", newGenre);
    formData.append("peakData", peakData);
    formData.append("duration", duration);
    formData.append("tags", tags);
    formData.append("downloadPrice", downloadPrice);

    if (trackFile) formData.append("audio", trackFile);
    if (downloadPrice != null && downloadPrice != 'null') {
        formData.append("downloadFile", downloadFile);
        formData.append("downloadFilename", downloadFilename);
        formData.append("downloadContentType", downloadContentType);
        formData.append("downloadDescription", downloadDescription);
    }

    const fd = Object.fromEntries(formData);
    fd.id = trackId;
    dispatch(setUploadInfo(fd));
    dispatch(setUploadErrors([]));

    let lastTimestamp = null;
    let lastUpdate = null;
    let lastLoadedBytes = null;
    let remaining = [];
    const res = await axios.patch(configData.API_URL + '/api/tracks/' + trackId, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "XSRF-Token": Cookies.get("XSRF-TOKEN")
        },
        onUploadProgress: progressEvent => {
            dispatch(uploadProgress(progressEvent));
            const now = new Date();
            if (lastTimestamp) {
                const bytesInChunk = progressEvent.loaded - lastLoadedBytes;
                const bytesRemaining = progressEvent.total - progressEvent.loaded;
                const chunksRemaining = bytesRemaining / bytesInChunk;
                const timeSince = now - lastTimestamp;

                // average last 100 measurements
                remaining.push(timeSince / bytesInChunk); // time per byte
                if (remaining.length > 1000) remaining.shift();
                if (now - lastUpdate > 1000) { // only update redux every second
                    dispatch(setUploadMSRemaining(remaining.reduce((a, b) => a + b, 0) / remaining.length * bytesRemaining));
                    lastUpdate = now;
                }
            }
            lastTimestamp = now;
            lastLoadedBytes = progressEvent.loaded;
        }
    }).catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 500) {
                dispatch(setUploadErrors(['Error: ' + error.response.data.title]));
            } else if (error.response.status === 401 || error.response.status === 403) {
                dispatch(setUploadErrors(['An error occured during upload.  Please refresh and try again.']));
            } else {
                dispatch(setUploadErrors(['Error (' + error.response.status + '): ' + error.response.data.title]));
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            dispatch(setUploadErrors(['Error']));
        } else {
            // Something happened in setting up the request that triggered an Error              
            dispatch(setUploadErrors(['Error: ' + error.message]));
        }

        dispatch(uploadProgress(null));
        return;
    });

    dispatch(uploadProgress(null));

    if (res) {
        fd.success = true;
        dispatch(setUploadInfo(fd));
        dispatch(getTrack(trackId));
    }

    return res?.data?.track;
}

export const mintTrack = (trackId, track) => async (dispatch) => {

    const { imageUrl, unlockableFile, unlockableContentDescription, unlockableFilename, unlockableContentType, royalites, imageFile } = track;

    const formData = new FormData();
    formData.append("imageUrl", imageUrl);
    formData.append("royalites", JSON.stringify(royalites));

    if (unlockableContentDescription) {
        formData.append("unlockableContentDescription", unlockableContentDescription);
    }

    if (unlockableFile) {
        formData.append("unlockableFile", unlockableFile);
        formData.append("unlockableFilename", unlockableFilename);
        formData.append("unlockableContentType", unlockableContentType);
    }

    if (imageFile) {
        formData.append("imageFile", imageFile);
    }

    const fd = Object.fromEntries(formData);
    fd.id = trackId;
    dispatch(setUploadInfo(fd));
    dispatch(setUploadErrors([]));

    let lastTimestamp = null;
    let lastUpdate = null;
    let lastLoadedBytes = null;
    let remaining = [];
    const res = await axios.post(configData.API_URL + '/api/tracks/' + trackId + '/mint', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "XSRF-Token": Cookies.get("XSRF-TOKEN")
        },
        onUploadProgress: progressEvent => {
            dispatch(uploadProgress(progressEvent));
            const now = new Date();
            if (lastTimestamp) {
                const bytesInChunk = progressEvent.loaded - lastLoadedBytes;
                const bytesRemaining = progressEvent.total - progressEvent.loaded;
                const chunksRemaining = bytesRemaining / bytesInChunk;
                const timeSince = now - lastTimestamp;

                // average last 100 measurements
                remaining.push(timeSince / bytesInChunk); // time per byte
                if (remaining.length > 1000) remaining.shift();
                if (now - lastUpdate > 1000) { // only update redux every second
                    dispatch(setUploadMSRemaining(remaining.reduce((a, b) => a + b, 0) / remaining.length * bytesRemaining));
                    lastUpdate = now;
                }
            }
            lastTimestamp = now;
            lastLoadedBytes = progressEvent.loaded;
        }
    }).catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 500) {
                dispatch(setUploadErrors(['Error: ' + error.response.data.title]));
            } else if (error.response.status === 400) {
                dispatch(setUploadErrors(['Error minting track.']));
            } else if (error.response.status === 401) {
                dispatch(setUploadErrors(['Access denied']));
            } else if (error.response.status === 402) {
                dispatch(setUploadErrors(['Connect your NEAR wallet to mint a track']));
            } else if (error.response.status === 403) {
                dispatch(setUploadErrors(['An error occured during upload.  Please refresh and try again.']));
            } else if (error.response.status === 409) {
                dispatch(setUploadErrors(['Track has already been minted.']));
            } else {
                dispatch(setUploadErrors(['Error (' + error.response.status + '): ' + error.response.data.title]));
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            dispatch(setUploadErrors(['Error']));
        } else {
            // Something happened in setting up the request that triggered an Error              
            dispatch(setUploadErrors(['Error: ' + error.message]));
        }

        dispatch(uploadProgress(null));
        return;
    });

    dispatch(uploadProgress(null));

    if (res) {
        fd.success = true;
        dispatch(setUploadInfo(fd));
        dispatch(getTrack(trackId));
    }

    return res?.data?.track;
}

export const deleteTrack = (trackId) => async (dispatch) => {

    const res = await fetch(configData.API_URL + `/api/tracks/${trackId}`, {
        method: 'DELETE'
    });

    dispatch(clearTrack(trackId));

    return res.data.comment;
};

export const getTrack = (id) => async (dispatch) => {
    const res = await fetch(configData.API_URL + `/api/tracks/${id}`);
    dispatch(selectTrack(res.data.track));
    return res.data.track;
};

export const setTrack = (track) => async (dispatch) => {
    dispatch(selectTrack(track));
};

export const updateTrackDisplayOrder = (trackId, displayOrder) => async (dispatch) => {
    const res = await fetch(configData.API_URL + `/api/tracks/${trackId}/displayOrder`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ displayOrder })
    });

    if (res?.data?.track) {
        dispatch(getTrack(trackId));
    }
}

export const selectGenre = (genre) => async (dispatch, getState) => {
    const state = getState();
    if (state.track.trackList?.rows?.length) dispatch(setTracks([]));
    return dispatch(setGenre(genre));
};

export const postComment = (trackId, comment) => async (dispatch) => {
    const res = await fetch(configData.API_URL + `/api/tracks/${trackId}/comment`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(comment),
    });

    dispatch(getTrack(trackId));

    return res.data.comment;
};

export const deleteComment = (trackId, commentId) => async (dispatch) => {

    const res = await fetch(configData.API_URL + `/api/tracks/${trackId}/comment/${commentId}`, {
        method: 'DELETE'
    });

    dispatch(getTrack(trackId));

    return res.data.comment;
};

export const editComment = (trackId, commentId, content) => async (dispatch) => {

    const res = await fetch(configData.API_URL + `/api/tracks/${trackId}/${commentId}`, {
        method: 'PATCH',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ content }),
    });

    dispatch(getTrack(trackId));

    return res.data.comment;
};

export const addReply = (trackId, comment) => async (dispatch) => {
    const res = await fetch(configData.API_URL + `/api/tracks/${trackId}/comment`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(comment),
    });

    dispatch(getTrack(trackId));

    return res.data.comment;
};

export const getRecentTracks = (startAt, period) => async (dispatch) => {
    let offset = startAt;
    const currentState = window.store.getState().track;
    try {
        if (offset && currentState.trackListType === 'recent') {
            if (currentState.trackList.rows.length > offset) {
                currentState.trackList.rows.slice(0, offset);
                dispatch(setTracks(currentState.trackList));
            }
        } else {
            offset = 0;
            if (currentState.trackList?.rows?.length) dispatch(setTracks([]));
        }

        let params = {}
        if (offset) params.offset = offset;
        if (currentState.genre) params.genre = currentState.genre;
        if (period) params.period = period;

        const res = await fetch(configData.API_URL + `/api/tracks/recent?` + (new URLSearchParams(params).toString()));
        dispatch(setListType('recent'));
        dispatch(addTracks(res.data.tracks));
        return res.data.tracks;
    } catch (err) {
        console.log(err);
    }
    return [];
}

export const getRecommendedTracks = (startAt, period) => async (dispatch) => {
    let offset = startAt;
    const currentState = window.store.getState().track;
    try {
        if (offset && currentState.trackListType === 'recommended') {
            if (currentState.trackList.rows.length > offset) {
                currentState.trackList.rows.slice(0, offset);
                dispatch(setTracks(currentState.trackList));
            }
        } else {
            offset = 0;
            if (currentState.trackList?.rows?.length) dispatch(setTracks([]));
        }

        let params = {}
        if (offset) params.offset = offset;
        if (currentState.genre) params.genre = currentState.genre;
        if (period) params.period = period;

        const res = await fetch(configData.API_URL + `/api/tracks/recommended?` + (new URLSearchParams(params).toString()));
        dispatch(setListType('recommended'));
        dispatch(addTracks(res.data.tracks));
        return res.data.tracks;
    } catch (err) {
        console.log(err);
    }
    return [];
}

export const getPopularTracks = (startAt, period) => async (dispatch) => {
    let offset = startAt;
    const currentState = window.store.getState().track;
    try {
        if (offset && currentState.trackListType === 'popular') {
            if (currentState.trackList.rows.length > offset) {
                currentState.trackList.rows.slice(0, offset);
                dispatch(setTracks(currentState.trackList));
            }
        } else {
            offset = 0;
            if (currentState.trackList?.rows?.length) dispatch(setTracks([]));
        }

        let params = {}
        if (offset) params.offset = offset;
        if (currentState.genre) params.genre = currentState.genre;
        if (period) params.period = period;

        const res = await fetch(configData.API_URL + `/api/tracks/popular?` + (new URLSearchParams(params).toString()));
        dispatch(setListType('popular'));
        dispatch(addTracks(res.data.tracks));
        return res.data.tracks;
    } catch (err) {
        console.log(err);
    }
    return [];
}

export const getDownloadableTracks = (startAt, period) => async (dispatch) => {
    let offset = startAt;
    const currentState = window.store.getState().track;
    try {
        if (offset && currentState.trackListType === 'downloadable') {
            if (currentState.trackList.rows.length > offset) {
                currentState.trackList.rows.slice(0, offset);
                dispatch(setTracks(currentState.trackList));
            }
        } else {
            offset = 0;
            if (currentState.trackList?.rows?.length) dispatch(setTracks([]));
        }

        let params = {}
        if (offset) params.offset = offset;
        if (currentState.genre) params.genre = currentState.genre;
        if (period) params.period = period;

        const res = await fetch(configData.API_URL + `/api/tracks/downloadable?` + (new URLSearchParams(params).toString()));
        dispatch(setListType('downloadable'));
        dispatch(addTracks(res.data.tracks));
        return res.data.tracks;
    } catch (err) {
        console.log(err);
    }
    return [];
}

export const getCryptoTracks = (startAt, period) => async (dispatch) => {
    let offset = startAt;
    const currentState = window.store.getState().track;
    try {
        if (offset && currentState.trackListType === 'crypto') {
            if (currentState.trackList.rows.length > offset) {
                currentState.trackList.rows.slice(0, offset);
                dispatch(setTracks(currentState.trackList));
            }
        } else {
            offset = 0;
            if (currentState.trackList?.rows?.length) dispatch(setTracks([]));
        }

        let params = {}
        if (offset) params.offset = offset;
        if (currentState.genre) params.genre = currentState.genre;
        if (period) params.period = period;

        const res = await fetch(configData.API_URL + `/api/tracks/crypto?` + (new URLSearchParams(params).toString()));
        dispatch(setListType('crypto'));
        dispatch(addTracks(res.data.tracks));
        return res.data.tracks;
    } catch (err) {
        console.log(err);
    }
    return [];
}

export const getNftTracks = (startAt, period) => async (dispatch) => {
    let offset = startAt;
    const currentState = window.store.getState().track;
    try {
        if (offset && currentState.trackListType === 'nfts') {
            if (currentState.trackList.rows.length > offset) {
                currentState.trackList.rows.slice(0, offset);
                dispatch(setTracks(currentState.trackList));
            }
        } else {
            offset = 0;
            if (currentState.trackList?.rows?.length) dispatch(setTracks([]));
        }

        let params = {}
        if (offset) params.offset = offset;
        if (currentState.genre) params.genre = currentState.genre;
        if (period) params.period = period;

        const res = await fetch(configData.API_URL + `/api/tracks/nfts?` + (new URLSearchParams(params).toString()));
        dispatch(setListType('nfts'));
        dispatch(addTracks(res.data.tracks));
        return res.data.tracks;
    } catch (err) {
        console.log(err);
    }
    return [];
}

export const getForsaleTracks = (startAt, period) => async (dispatch) => {
    let offset = startAt;
    const currentState = window.store.getState().track;
    try {
        if (offset && currentState.trackListType === 'forsale') {
            if (currentState.trackList.rows.length > offset) {
                currentState.trackList.rows.slice(0, offset);
                dispatch(setTracks(currentState.trackList));
            }
        } else {
            offset = 0;
            if (currentState.trackList?.rows?.length) dispatch(setTracks([]));
        }

        let params = {}
        if (offset) params.offset = offset;
        if (currentState.genre) params.genre = currentState.genre;
        if (period) params.period = period;

        const res = await fetch(configData.API_URL + `/api/tracks/forsale?` + (new URLSearchParams(params).toString()));
        dispatch(setListType('forsale'));
        dispatch(addTracks(res.data.tracks));
        return res.data.tracks;
    } catch (err) {
        console.log(err);
    }
    return [];
}

export const postReaction = (trackId, type, data) => async (dispatch) => {

    const currentSessionState = window.store.getState().session;

    // add to state, will be overwritten by getTrack.  Used to make the UI respond immediately to reactions
    dispatch(addReaction({
        userId: currentSessionState.user?.id,
        trackId: trackId,
        trackAt: data.trackAt,
        referenceId: data.referenceId,
        referenceData: data?.referenceData,
        reactionType: type,
        createdAt: (new Date()).toISOString(),
        commentId: '0',
        User: currentSessionState.user
    }));
    const res = await fetch(configData.API_URL + `/api/tracks/${trackId}/reaction/${type}`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data),
    });

    // refresh track data
    dispatch(getTrack(trackId));

    return res.data.reaction;
};

export const deleteReaction = (trackId) => async (dispatch) => {

    const currentSessionState = window.store.getState().session;

    dispatch(removeReaction({
        userId: currentSessionState.user?.id,
        trackId: trackId
    }));

    const res = await fetch(configData.API_URL + `/api/tracks/${trackId}/reaction`, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json"
        }
    });

    dispatch(getTrack(trackId));

    return res.data.reaction;
};

export const postCommentReaction = (trackId, commentId, comment, type) => async (dispatch) => {

    const currentSessionState = window.store.getState().session;

    // add to state, will be overwritten by getTrack.  Used to make the UI respond immediately to reactions
    dispatch(addReaction({
        userId: currentSessionState.user?.id,
        trackId: trackId,
        trackAt: null,
        referenceId: null,
        commentId: commentId,
        reactionType: type,
        createdAt: (new Date()).toISOString(),
        User: currentSessionState.user
    }));

    const res = await fetch(configData.API_URL + `/api/tracks/${trackId}/comment/${commentId}/reaction/${type}`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(comment),
    });

    dispatch(getTrack(trackId));

    return res.data.reaction;
};

export const deleteCommentReaction = (trackId, commentId) => async (dispatch) => {
    const res = await fetch(configData.API_URL + `/api/tracks/${trackId}/comment/${commentId}/reaction`, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json"
        }
    });

    dispatch(getTrack(trackId));

    return res.data.reaction;
};

const initialState = { track: null, trackList: [], trackListType: '', genre: 'none', uploadInfo: null, uploadProgress: null, uploadErrors: [], uploadMSRemaining: null };

export default function trackReducer(state = initialState, action) {
    let newState;
    switch (action.type) {
        case SET_UPLOAD_MS_REMAINING:
            newState = Object.assign({}, state);
            newState.uploadMSRemaining = action.payload;
            return newState;
        case SET_UPLOAD_ERRORS:
            newState = Object.assign({}, state);
            newState.uploadErrors = action.payload;
            return newState;
        case SET_UPLOAD_INFO:
        case CLEAR_UPLOAD_INFO:
            newState = Object.assign({}, state);
            newState.uploadInfo = action.payload;
            return newState;
        case ADD_TRACK:
            newState = Object.assign({}, state);
            newState.track = action.payload;
            return newState;
        case SELECT_TRACK:
            newState = Object.assign({}, state);
            newState.track = action.payload;
            if (newState.trackList?.rows) {
                newState.trackList = {
                    count: state.trackList.count,
                    rows: state.trackList.rows.map(ele => { return ele.id === newState.track.id ? newState.track : ele })
                };
            }
            return newState;

        case ADD_REACTION:
            newState = Object.assign({}, state);
            if (newState?.track?.id === action.payload.trackId) {
                if (action.payload.reactionType === 'bomb' || action.payload.reactionType === 'skull') {
                    newState.track.Reactions = newState.track.Reactions.filter(ele => {
                        return ele.userId !== action.payload.userId ||
                            (
                                !(ele.userId === action.payload.userId &&
                                    ele.commentId == action.payload.commentId &&
                                    (ele.reactionType === 'bomb' || ele.reactionType === 'skull'))
                            )
                    });
                }
                newState.track.Reactions.push(action.payload);

            }
            if (newState.trackList?.rows) {
                newState.trackList = {
                    count: state.trackList.count,
                    rows: state.trackList.rows.map(ele => {
                        if (ele.id === action.payload.trackId) {
                            ele.Reactions = ele.Reactions.filter(ele => {
                                return ele.userId !== action.payload.userId ||
                                    (
                                        !(ele.userId === action.payload.userId &&
                                            ele.commentId == action.payload.commentId &&
                                            (ele.reactionType === 'bomb' || ele.reactionType === 'skull'))
                                    )
                            });
                            ele.Reactions.push(action.payload);
                            return ele;
                        }
                        return ele;
                    })
                };
            }

            return newState;
        case DELETE_REACTION:
            newState = Object.assign({}, state);
            if (newState?.track?.id === action.payload.trackId) {
                newState.track.Reactions = newState.track.Reactions.filter(ele => { return ele.userId !== action.payload.userId });
            }

            if (newState.trackList?.rows) {
                newState.trackList = {
                    count: state.trackList.count,
                    rows: state.trackList.rows.map(ele => {
                        if (ele.id === action.payload.trackId) {
                            ele.Reactions = ele.Reactions.filter(ele => { return ele.userId !== action.payload.userId });
                            return ele;
                        }
                        return ele;
                    })
                };
            }

            return newState;
        case CLEAR_TRACK:
            newState = Object.assign({}, state);
            newState.track = null;
            if (newState.trackList?.rows) newState.trackList.rows = state.trackList.rows.filter(ele => { return ele.id !== action.payload });
            return newState;
        case SET_TRACKS:
            newState = Object.assign({}, state);
            newState.trackList = action.payload;
            return newState;
        case ADD_TRACKS:
            newState = Object.assign({}, state);
            newState.trackList = {
                count: action.payload.count,
                rows: newState.trackList.rows ? newState.trackList.rows.concat(action.payload.rows) : action.payload.rows
            };
            return newState;
        case SET_LIST_TYPE:
            newState = Object.assign({}, state);
            newState.trackListType = action.payload;
            return newState;
        case UPLOAD_PROGRESS:
            newState = Object.assign({}, state);
            newState.uploadProgress = action.payload;
            return newState;
        case SET_GENRE:
            newState = Object.assign({}, state);
            newState.genre = action.payload ? action.payload : null;
            return newState;
        default:
            return state;
    }
};