import { fetch } from './csrf';
import configData from '../config.json'
import { SELECT_TRACK, ADD_REACTION, DELETE_REACTION } from './track';

const SET_USER = 'session/setUser';
const GET_USER = 'session/getUser';
const REMOVE_USER = 'session/removeUser';
const GET_USER_TRACKS = 'session/getUserTracks';
const ADD_USER_TRACKS = 'session/addUserTracks';

const setUser = (user) => {
    return {
        type: SET_USER,
        payload: user,
    };
};

const getUserTracks = (tracks) => {
    return {
        type: GET_USER_TRACKS,
        payload: tracks,
    };
};

const addUserTracks = (tracks) => {
    return {
        type: ADD_USER_TRACKS,
        payload: tracks,
    };
};

const getUser = (user) => {
    return {
        type: GET_USER,
        payload: user,
    };
};

const removeUser = () => {
    return {
        type: REMOVE_USER,
    };
};

export const login = (user) => async (dispatch) => {
    const { credential, password, method } = user;

    const ip = await fetch('https://geolocation-db.com/json/').then(res => res.json()).then(res => res).catch(e => e);        
    const res = await fetch(configData.API_URL + '/api/session', {
        method: 'POST',
        body: JSON.stringify({
            credential,
            password,
            ip: ip?.IPv4,
            method
        }),
    });
    dispatch(setUser(res.data.user));
    return res;
}

export const forgotPassword = (user) => async (dispatch) => {
    const { credential } = user;
    const res = await fetch(configData.API_URL + '/api/session/forgotpassword', {
        method: 'POST',
        body: JSON.stringify({
            credential
        }),
    });
    return res;
}

export const restoreUser = () => async (dispatch) => {
    const res = await (fetch(configData.API_URL + '/api/session').catch((error) => {
        console.log(error)
    }));
    dispatch(setUser(res.data.user));
    return res;
}

export const signupUser = (user) => async (dispatch) => {
    const { username, firstName, lastName, email, websiteUrl, avatarUrl, password, ct } = user;

    const ip = await fetch('https://geolocation-db.com/json/').then(res => res.json()).then(res => res);
    const res = await fetch(configData.API_URL + '/api/users', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            username,
            firstName,
            lastName,
            email,
            websiteUrl,
            avatarUrl,
            password,
            ct,
            ip: ip?.IPv4
        }),
    }).catch((res) => {
        return res;
    });
    dispatch(setUser(res.data.user));
    return res;
}

export const updateProfile = (user) => async (dispatch) => {
    const { username, firstName, lastName, email, websiteUrl, avatarUrl, password } = user;
    const res = await fetch(configData.API_URL + '/api/users', {
        method: 'PATCH',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            username,
            firstName,
            lastName,
            email,
            websiteUrl,
            avatarUrl,
            password,
        }),
    });
    dispatch(setUser(res.data.user));
    return res;
}

export const assignWallet = (data) => async (dispatch) => {
    const { walletId, signature, pk } = data;
    const res = await fetch(configData.API_URL + '/api/users/wallet/assign', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ walletId, signature, pk }),
    });
    dispatch(setUser(res.data.user));
    return res;
}

export const assignSkaleWallet = (data) => async (dispatch) => {
    const { walletId } = data;
    const res = await fetch(configData.API_URL + '/api/users/skale/assign', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ walletId }),
    });
    dispatch(setUser(res.data.user));
    return res;
}

export const logout = () => async (dispatch) => {
    const res = await fetch(configData.API_URL + '/api/session', {
        method: 'DELETE',
    });
    dispatch(removeUser());
    return res;
}

export const getAUser = (username) => async (dispatch) => {

    dispatch(getUserTracks([]));

    const res = await fetch(configData.API_URL + `/api/users/${username}`);
    dispatch(getUser(res.data.user));

    const trackRes = await fetch(configData.API_URL + `/api/users/${username}/tracks`);
    dispatch(getUserTracks(trackRes.data.tracks));
    return res;
}

export const getAUsersTracks = (username, startAt) => async (dispatch) => {
    const res = await fetch(configData.API_URL + `/api/users/${username}/tracks?offset=${startAt}`);
    dispatch(addUserTracks(res.data.tracks));
    return res;
}

export const postFollow = (followUserId) => async (dispatch) => {
    const res = await fetch(configData.API_URL + `/api/users/follow/${followUserId}`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        }
    });

    dispatch(restoreUser());

    return res.data.follow;
}

export const deleteFollow = (unfollowUserId) => async (dispatch) => {
    const res = await fetch(configData.API_URL + `/api/users/follow/${unfollowUserId}`, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json"
        }
    });

    dispatch(restoreUser());

    return res.data.follow;
};

const initialState = { user: null, targetUser: null };

export default function sessionReducer(state = initialState, action) {
    let newState;
    switch (action.type) {
        case ADD_REACTION:
            newState = Object.assign({}, state);
            if (newState.targetUser && newState.targetUser.Tracks) {
                newState.targetUser.Tracks.rows = newState.targetUser.Tracks.rows.map(ele => {
                    if (ele.id == action.payload.trackId) {
                        ele.Reactions = ele.Reactions.filter(ele => {
                            return ele.userId !== action.payload.userId ||
                                (
                                    !(ele.userId === action.payload.userId &&
                                        ele.commentId == action.payload.commentId &&
                                        (ele.reactionType === 'bomb' || ele.reactionType === 'skull'))
                                )
                        });
                        ele.Reactions.push(action.payload);
                        return ele;
                    }
                    return ele;
                })
            }
            return newState;       
        case SET_USER:
            newState = Object.assign({}, state);
            newState.user = action.payload;
            return newState;        
        case GET_USER:
            newState = Object.assign({}, state);
            newState.targetUser = action.payload;
            return newState;
        case GET_USER_TRACKS:
            newState = Object.assign({}, state);
            if (newState?.targetUser) newState.targetUser.Tracks = action.payload;
            return newState;            
        case ADD_USER_TRACKS:
            newState = Object.assign({}, state);
            if (newState?.targetUser) {
                newState.targetUser.Tracks = {
                    count: action.payload.count, 
                    rows: newState.targetUser.Tracks.rows ? newState.targetUser.Tracks.rows.concat(action.payload.rows) : action.payload.rows
                }
            }
            return newState;            
        case SELECT_TRACK:
            newState = Object.assign({}, state);
            if (newState.targetUser && newState.targetUser?.Tracks?.rows) {
                newState.targetUser.Tracks.rows = state.targetUser.Tracks.rows.map(ele => { 
                    return ele.id === action.payload.id ? action.payload : ele;
                });
            }
            return newState;
        case REMOVE_USER:
            newState = Object.assign({}, state);
            newState.user = null;
            return newState;
        default:
            return state;
    };
};