import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TrackCard from '../Common/TrackCard';
import configData from '../../config.json'
import { fetch } from '../../store/csrf';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useDocumentTitle, useMetaTags } from '../Common/useMeta';
import landingStyles from '../LandingPage/LandingPage.module.css'
// import { getGenreIcon } from '../LandingPage/LandingPage'
import getGenreIcon from '../../utils/getGenreIcon'

const SearchResults = () => {
  //const dispatch = useDispatch();

  const location = useLocation();
  const sessionUser = useSelector(state => state.session.user);
  const [searchResults, setSearchResults] = useState(false);
  const { trackPageView } = useMatomo();
  const [metaTags, setMetaTags] = useMetaTags({});

  const [showingGenreResults, setShowingGenreResults] = useState(false);
  const [showingArtistResults, setShowingArtistResults] = useState(false);
  const [showingTrackResults, setShowingTrackResults] = useState(true);

  useDocumentTitle('Search Results');

  // Track page view
  useEffect(() => {
    trackPageView()
  }, []);

  useEffect(() => {
    async function doSearch() {
      setMetaTags({ ...metaTags, title: 'Search Results: ' + location.search.substr(1) });
      const res = await fetch(configData.API_URL + `/api/search/${location.search.substr(1)}`, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (res.data) setSearchResults(res.data);
    }
    doSearch();
  }, [location.search])

  return (
    <div className='mt-10 mb-20 flex-1 absolute w-full'>
      <h1 className='mx-2 sm:mx-10 text-3xl text-mandarin pl-5 py-2 mt-12 mb-4 bg-tama-brown-trans rounded'>Search Results for {location.search.substr(1)}</h1>
      {
        searchResults &&
        searchResults.tracks.count === 0 &&
        searchResults.users.count === 0 &&
        searchResults.genres.count === 0 &&
        <div className='px-5 sm:mx-10'>
          <h1 className='text-2xl mx-5'>No results found</h1>
        </div>
      }
      {
        searchResults && searchResults.genres.count > 0 && (
          <div className='px-5 sm:mx-5'>
            <div className='flex flex-row'>
              <h1 className='text-2xl sm:text-3xl sm:ml-5 mr-2 mt-6 text-mandarin'>Genres</h1>
              <div className='w-8 mt-6 py-1 text-mandarin cursor-pointer' onClick={() => setShowingGenreResults(!showingGenreResults)}>
                {showingGenreResults ? (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                ) : (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                )}
              </div>
              <span className='text-lg text-white mt-6 py-1 tracking-wide'>{searchResults.genres.rows.length} {searchResults.genres.rows.length === 1 ? 'result' : 'results'}...</span>
            </div>
            {(showingGenreResults || searchResults.genres.rows.length <= 5) && (
              <ul className='flex flex-row flex-wrap justify-start'>
                {
                  searchResults.genres.rows.map((genre) => {
                    return <li key={'genre' + genre.id}>
                      <Link to={'/genre/' + genre.name} className={`flex flex-col items-center sm:text-xl ml-1 mr-2 w-24 sm:w-48`} style={{ borderTop: 'none' }}>
                        <img className='h-16 w-20 sm:h-28 sm:w-36 mr-3' src={getGenreIcon(genre.iconId)} alt={genre.name} />
                        <div className='flex w-full text-center justify-center items-center mx-2'>{genre.name}</div>
                      </Link>
                    </li>
                  })
                }
              </ul>
            )}
          </div>
        )
      }
      {
        searchResults && searchResults.users.count > 0 && (
          <div className='px-5 sm:mx-5'>
            <div className='flex flex-row'>
              <h1 className='text-2xl sm:text-3xl text-mandarin sm:ml-5 mr-3 my-4 mt-7'>Artists</h1>
              <div className='w-8 mt-6 py-2 text-mandarin cursor-pointer' onClick={() => setShowingArtistResults(!showingArtistResults)}>
                {showingArtistResults ? (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                ) : (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                )}
              </div>
              <span className='text-lg text-white mt-6 py-2 tracking-wide'>{searchResults.users.rows.length} {searchResults.users.rows.length === 1 ? 'result' : 'results'}...</span>
            </div>
            {(showingArtistResults || searchResults.users.rows.length <= 5) && (
              <ul className='flex flex-wrap w-full'>
                {
                  searchResults.users.rows.map((user) => {
                    return <li key={'user' + user.id}>
                      <Link to={`/user/${user.username.replace('?', '%3F')}`} className='flex flex-col items-center w-20 sm:w-44 sm:text-xl mx-4 mb-4'>
                        <img src={user.avatarUrl} alt={user.username} className='w-20 h-20 sm:w-40 sm:h-40 shadow-2xl rounded-full' />
                        <div className='text-white text-center mt-3'>{user.username}</div>
                      </Link>
                    </li>
                  })
                }
              </ul>
            )}
          </div>
        )
      }
      {
        searchResults && searchResults.tracks.count > 0 && (
          <div className='px-5 sm:mx-5 mb-20'>
            <div className='flex flex-row'>
              <h1 className='text-2xl sm:text-3xl text-mandarin sm:ml-5 mr-3 mt-2 -mb-2'>Tracks</h1>
              <div className='w-8 mt-2 py-1 text-mandarin cursor-pointer' onClick={() => setShowingTrackResults(!showingTrackResults)}>
                {showingTrackResults ? (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                ) : (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                )}
              </div>
              <span className='text-lg text-white mt-2 py-1 tracking-wide'>{searchResults.tracks.rows.length} {searchResults.tracks.rows.length === 1 ? 'result' : 'results'}...</span>
            </div>
            {showingTrackResults && (
              <ul className='sm:px-4 mx-2'>
                {
                  searchResults.tracks.rows.map((track) => {
                    return <li key={'track' + track.id}>
                      <TrackCard track={track} />
                    </li>
                  })
                }
              </ul>
            )}
          </div>
        )
      }
    </div>
  )
}

export default SearchResults;