import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { playTrack, playAudioTrack, stopTrack, setTrack, setNextTrack } from '../../store/player';
import { ThreeCircles, Audio, BallTriangle, MutatingDots } from 'react-loader-spinner'
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, TouchSensor, DragOverlay } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';

import { getAUser, getAUsersTracks } from '../../store/session';
import { getTrack, setTrack as setTrack2, updateTrackDisplayOrder } from '../../store/track';
import UserMainInfo from './UserMainInfo';
import TrackCard from '../Common/TrackCard';
import { fetch } from '../../store/csrf';
import configData from '../../config.json';
import RecordStoreIcon from '../../img/icons/record-store.png';
import SkullIcon from '../../img/icons/skullz.png'
import BombIcon from '../../img/icons/bomb.png'
import ChestIcon from '../../img/icons/chest.png'
import { ReactComponent as NearType } from '../../img/icons/near_type.svg'
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { utils } from 'near-api-js';

import { useDocumentTitle, useMetaTags } from '../Common/useMeta';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isMobile } from 'react-device-detect';

const UserPage = () => {
    const { username } = useParams();
    const dispatch = useDispatch();
    const targetUser = useSelector(state => state.session.targetUser);
    const targetUserTracks = useSelector(state => state.session.targetUser && state.session.targetUser.Tracks && state.session.targetUser.Tracks.rows); // to trigger render
    const sessionUser = useSelector(state => state.session.user);
    const currentTrack = useSelector(state => state.player.currentTrack);
    const [userActivity, setUserActivity] = useState(null);
    const [activeTab, setActiveTab] = useState('tracks');
    const [isFetched, setIsFetched] = useState(false);
    //const [displayOrder, setDisplayOrder] = useState(null);
    const { trackPageView } = useMatomo();
    const [documentTitle, setDocumentTitle] = useDocumentTitle(null);
    const [metaTags, setMetaTags] = useMetaTags({});
    const [filteredTracks, setFilteredTracks] = useState(null);

    const sensors = useSensors(
        useSensor(isMobile ? TouchSensor : PointerSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
              delay: 150,
              tolerance: 5,
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const getUserData = () => {
        setIsFetched(false);
        dispatch(getAUser(username)).then(() => setIsFetched(true));
    }

    // Track page view
    useEffect(() => {
        trackPageView();
        setDocumentTitle(username);
        setMetaTags({ ...metaTags, title: username });
    }, []);

    useEffect(() => {
        getUserData();
    }, [username, dispatch]);

    useEffect(() => {
        if (currentTrack == null && targetUser && targetUser?.Tracks?.rows) {
            if (targetUser.Tracks.rows.length > 0) {
                dispatch(playTrack(targetUser.Tracks.rows.filter(track => track.trackStatus != 'processing').sort((track1, track2) => { return new Date(track2.createdAt) - new Date(track1.createdAt) })[0].id));
                setDocumentTitle(targetUser.username);
                setMetaTags({ ...metaTags, title: targetUser.username, image: targetUser.avatarUrl });
            }
        }
    }, [targetUser]);

    useEffect(() => {
        if (currentTrack && targetUser && targetUser?.Tracks?.rows) {
            const sortedTracks = targetUser.Tracks.rows.filter(track => track.trackStatus != 'processing').sort((track1, track2) => { return new Date(track2.createdAt) - new Date(track1.createdAt) });
            const currentInd = sortedTracks.findIndex(ele => ele.id == currentTrack.id);

            if (currentInd != -1 && sortedTracks.length == currentInd + 1) {
                dispatch(setNextTrack(sortedTracks[0]));
            } else if (currentInd != -1) {
                dispatch(setNextTrack(sortedTracks[currentInd + 1]));
            }
        }
    }, [currentTrack]);

    useEffect(() => {
        if (targetUser && userActivity == null) {
            fetchUserActivity();
        }
    }, [targetUser]);

    useEffect(() => {        
        let timer1;
        if (targetUser?.Tracks?.rows && targetUser.Tracks.rows.find(track => track.trackStatus == "processing")) {
            const processingTracks = targetUser.Tracks.rows.filter(track => track.trackStatus == "processing");
            timer1 = setTimeout(() => {
                processingTracks.map(track => dispatch(getTrack(track.id)));
            }, 5000);
        }
        return () => {
            clearTimeout(timer1);
        }
    }, [targetUser?.Tracks]);
    
    useEffect(() => {
        if (targetUser?.Tracks?.rows) {
            setFilteredTracks(targetUser?.Tracks?.rows?.sort((track1, track2) => { return track2.displayOrder > track1.displayOrder ? 1 : -1 }));
        }
        
    }, [targetUser?.Tracks?.rows]);

    const fetchUserActivity = () => {
        fetch(configData.API_URL + `/api/users/${targetUser.username}/activity`)
            .then(res => {
                if (res.data && res.data.activity)
                    setUserActivity(res.data.activity.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
                else
                    setUserActivity(null);
            });
    }

    const timeSince = (date) => {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }

    const fetchMoreTracks = () => {
        dispatch(getAUsersTracks(username, filteredTracks ? filteredTracks.length : null));
    }

    const handleDragEnd = (event) => {
        const {active, over} = event;

        const activeTrack = filteredTracks.find(t => t.id == active.id);
        const overTrack   = filteredTracks.find(t => t.id == over.id);
        
        let tracksToMove = [];
        if (activeTrack?.displayOrder > overTrack?.displayOrder) {
            tracksToMove = filteredTracks.filter(t => t.displayOrder >= overTrack.displayOrder && t.id != activeTrack.id && t.displayOrder <= activeTrack.displayOrder ).reverse();            
        } else {            
            tracksToMove = filteredTracks.filter(t => t.displayOrder >= activeTrack.displayOrder && t.id != activeTrack.id && t.displayOrder <= overTrack.displayOrder );
        }

        tracksToMove.map((t, ind) => {                  
            dispatch(setTrack2({ ...t, displayOrder: (activeTrack?.displayOrder > overTrack?.displayOrder ? overTrack.displayOrder + ind + 1 : overTrack.displayOrder - ind - 1) }));
            dispatch(updateTrackDisplayOrder(t.id, (activeTrack?.displayOrder > overTrack?.displayOrder ? overTrack.displayOrder + ind + 1 : overTrack.displayOrder - ind - 1)));
        });                
        dispatch(setTrack2({ ...activeTrack, displayOrder: overTrack.displayOrder }));
        dispatch(updateTrackDisplayOrder(activeTrack.id, overTrack.displayOrder));

    }

    let hasMoreTrack = true;
    if (filteredTracks?.length >= targetUser?.Tracks?.count) {
        hasMoreTrack = false;
    }

    return (
        <>
            {isFetched && targetUser && (
                <div className='mt-20 pb-32 flex-1 absolute w-full'>
                    <UserMainInfo user={targetUser} userActivity={userActivity} />
                    <div className='px-4 sm:px-10'>
                        <button className='m-0 px-12 py-2 w-1/2 sm:w-auto' style={activeTab === 'tracks' ? { backgroundColor: '#fff2' } : {}} onClick={() => setActiveTab('tracks')}>Tracks</button>
                        <button className='m-0 px-12 py-2 w-1/2 sm:w-auto' style={activeTab === 'activity' ? { backgroundColor: '#fff2' } : {}} onClick={() => setActiveTab('activity')}>Activity</button>
                        <div className='border rounded' style={{ borderColor: '#fff2', borderTopLeftRadius: '0' }}>
                            {
                                activeTab === 'tracks' &&
                                <div className='px-4 sm:px-8 pb-8'>
                                    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                                        <SortableContext items={filteredTracks} strategy={verticalListSortingStrategy}>
                                            <InfiniteScroll
                                                dataLength={filteredTracks ? filteredTracks.length : 0}
                                                next={fetchMoreTracks}
                                                hasMore={hasMoreTrack}
                                                loader={<div className='flex flex-col items-center justify-center p-8'><Audio color="#6fcc5e99" height={40} width={40} ariaLabel="three-circles-rotating" />loading</div>}
                                            >
                                                {
                                                    filteredTracks?.map(track => 
                                                        <TrackCard 
                                                            canDrag={(track.tokenUserId == null && track.userId == sessionUser?.id) || ( track.tokenUserId != null && track.tokenUserId == sessionUser?.id )} 
                                                            key={track.id} 
                                                            track={{ ...track }} 
                                                        />
                                                    )
                                                }
                                            </InfiniteScroll>
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            }
                            {
                                activeTab === 'activity' && userActivity &&
                                <div className='px-4 sm:px-8 py-6'>
                                    {
                                        userActivity.map((activity, ind) => {
                                            switch (activity.activityType) {
                                                case 'Track':
                                                    return <div key={'act' + ind} className='text-xl py-1'>
                                                        <Link to={`/tracks/${activity.id}`}>
                                                            <span style={{ color: '#c5c5ff' }}>{targetUser.username}</span> uploaded <img src={RecordStoreIcon} alt="record-store" className='inline mr-1 h-4' /><span style={{ textShadow: '0px 0px 5px rgba(104, 222, 216, 0.9)' }}>{activity.title}</span>&nbsp;
                                                            <span className='text-sm text-silver'>{timeSince(Date.parse(activity.createdAt))} ago</span>
                                                        </Link>
                                                    </div>
                                                    break;
                                                case 'Comment':
                                                    return activity.Track && <div key={'act' + ind} className='text-xl py-1'>
                                                        <Link to={`/tracks/${activity.Track.id}`}>
                                                            {targetUser.id === activity.userId ? <span style={{ color: '#c5c5ff' }}> {targetUser.username} </span> : <span className='text-tama-green'> {activity.User.username} </span>} commented on <span style={{ textShadow: '0px 0px 5px rgba(104, 222, 216, 0.9)' }}>{activity.Track.title}</span>&nbsp;
                                                            <span className='text-sm text-silver'>{timeSince(Date.parse(activity.createdAt))} ago</span>
                                                        </Link>
                                                    </div>
                                                    break;
                                                case 'Follow':
                                                    return (activity.Follower || activity.Following) && <div key={'act' + ind} className='text-xl py-1'>
                                                        {
                                                            activity.Follower &&
                                                            <Link to={`/user/${activity.Follower.username.replace('?', '%3F')}`}>
                                                                <span><span className='text-tama-green'>{activity.Follower.username}</span> started following <span style={{ color: '#c5c5ff' }}>{targetUser.username}</span>&nbsp;</span>
                                                                <span className='text-sm text-silver'>{timeSince(Date.parse(activity.createdAt))} ago</span>
                                                            </Link>
                                                        }
                                                        {
                                                            activity.Following &&
                                                            <Link to={`/user/${activity.Following.username.replace('?', '%3F')}`}>
                                                                <span><span className='text-tama-green'>{activity.Following.username}</span> started following <span style={{ color: '#c5c5ff' }}>{targetUser.username}</span>&nbsp;</span>
                                                                <span className='text-sm text-silver'>{timeSince(Date.parse(activity.createdAt))} ago</span>
                                                            </Link>
                                                        }
                                                    </div>
                                                    break;
                                                case 'Reaction':
                                                    let icon = '';
                                                    switch (activity.reactionType) {
                                                        case 'bomb': icon = BombIcon; break;
                                                        case 'skull': icon = SkullIcon; break;
                                                        case 'gem': icon = ChestIcon; break;
                                                        default: break;
                                                    }

                                                    return activity.Track && (activity.reactionType != 'gem' || (activity.reactionType == 'gem' && activity.referenceData)) && <div key={'act' + ind} className='text-xl py-1'>
                                                        <Link to={`/tracks/${activity.Track.id}`}>
                                                            {
                                                                targetUser.id === activity.userId
                                                                    ? <span style={{ color: '#c5c5ff' }}> {targetUser.username} </span>
                                                                    : <span className='text-tama-green'> {activity.User.username} </span>
                                                            }
                                                            {
                                                                activity.reactionType == 'gem' && activity.referenceData &&
                                                                <span>sent <img src={icon} alt="reaction" className='inline mr-1 h-6' />tip of {utils.format.formatNearAmount(activity.referenceData)}
                                                                    <NearType height={9} fill='currentColor' className='inline mb-0.5' /> for <span style={{ textShadow: '0px 0px 5px rgba(104, 222, 216, 0.9)' }}>{activity.Track.title}</span>&nbsp;</span>
                                                            }
                                                            {
                                                                activity.reactionType != 'gem' &&
                                                                <span>reacted with <img src={icon} alt="reaction" className='inline mr-1 h-6' /> to <span style={{ textShadow: '0px 0px 5px rgba(104, 222, 216, 0.9)' }}>{activity.Track.title}</span>&nbsp;</span>
                                                            }
                                                            {activity.commentId != '0' && <span>comment&nbsp;</span>}
                                                            <span className='text-sm text-silver'>{timeSince(Date.parse(activity.createdAt))} ago</span>
                                                        </Link>
                                                    </div>
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return '';
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )}
            {
                !isFetched &&
                <div className='mt-20 flex-1 absolute w-full'>
                    <div className='flex items-center justify-center h-full'>
                        <BallTriangle color="#6fcc5e33" height={70} width={70} ariaLabel="three-circles-rotating" />
                    </div>
                </div>
            }
        </>
    );
}

export default UserPage;
