import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { playTrack, playAudioTrack, stopTrack, addToQueue, removeFromQueue } from '../../store/player';
import { postComment, setTrack, updateTrackDisplayOrder } from '../../store/track';
import WaveForm from './WaveForm'
import TrackReact from './TractReact';
import styles from './TrackCard.module.css'
import reactionStyles from './TrackReact.module.css'
import { ReactComponent as PlayIcon } from '../../img/icons/play-svgrepo-com.svg';
import { ReactComponent as CommentIcon } from '../../img/icons/comment-svgrepo-com.svg';
import { ReactComponent as DownloadIcon } from '../../img/icons/download-svgrepo-com.svg';
import { ReactComponent as AddPlayListIcon } from '../../img/icons/add-plus-svgrepo-com2.svg';
import { ReactComponent as AddedPlayListIcon } from '../../img/icons/success-tick-svgrepo-com.svg';
import { ReactComponent as RemovePlayListIcon } from '../../img/icons/volume-volume-control-svgrepo-com.svg';
import { ReactComponent as LogoCrescent } from '../../img/tamago.svg';
import { ReactComponent as Trident } from '../../img/icons/trident.svg';
import { ReactComponent as DownloadIcon3 } from '../../img/icons/download3-svgrepo-com.svg';
import { timeSince } from '../../utils/getTimeSince';
import { formatNumbers } from '../../utils/formatNumbers';
import configData from '../../config.json'
//import { useDrag, useDrop } from 'react-dnd';
import { isMobile } from 'react-device-detect';
import Tooltip from './Tooltip';

const TrackCard = ({ track, showControls, showReactions, style, trackImageSize, darkMode, showTrackImage, canDrag, onMove, waveformHeight }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const commentInputRef = useRef();
    const queue = useSelector(state => state.player.queue);
    const queueLength = useSelector(state => state.player.queue.length);
    const currentTrack = useSelector(state => state.player.currentTrack);
    const trackLoading = useSelector(state => state.player.isTrackLoading);
    const nftContract = useSelector(state => state.near.nftContract);
    const marketContract = useSelector(state => state.near.marketContract);    
    const isPlaying = useSelector(state => state.player.isPlaying);
    const player = useSelector(state => state.player.wavesurfer);
    const sessionUser = useSelector(state => state.session.user);
    const [addCommentHasFocus, setAddCommentHasFocus] = useState(false);
    const [comment, setComment] = useState('');
    const [listing, setListing] = useState(null);
    const [token, setToken] = useState(null);

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: track.id});      
    const dragStyle = { transform: CSS.Transform.toString(transform), transition };
    
    useEffect(() => {
        if (nftContract && marketContract && track?.token) {
            nftContract.nft_token({ token_id: track.token }).then(t => setToken(t));
        }
    },[nftContract, track]);

    useEffect(() => {
        if (nftContract && marketContract && token) {
            marketContract.get_sale({ nft_contract_token: nftContract.contractId+'||'+token.token_id }).then(s => { setListing(s); });
        }
    },[nftContract, marketContract, token]) 

    const togglePlay = async (e) => {
        e.preventDefault();

        if (player) { 
            if (isPlaying && currentTrack.id === track.id) {
                player.pause();
                dispatch(stopTrack());
            } else {
                if (currentTrack === null || currentTrack.id !== track.id) {
                    player.pause();
                    player.seekTo(0);
                    dispatch(stopTrack());
                    player.backend.sourceMediaElement.mediaElement.src = configData.API_URL + "/api/tracks/" + track.id + "/stream";
                    player.backend.explicitDuration = track.duration;
                    player.track_id = track.id;
                    player.play();
                    dispatch(playTrack(track.id)).then(() => {
                        dispatch(playAudioTrack());                        
                    });
                } else {
                    dispatch(playAudioTrack());
                    player.play();
                }
            }
        }
    }

    const submitComment = async (e) => {

        if (sessionUser.accountStatus != 'ok') {
            history.push('/verify');
            return;
        } 

        e.preventDefault();
        const trackAt = isPlaying && player && currentTrack && currentTrack.id === track.id ? player.getCurrentTime() : null;
        const newComment = {
            content: comment,
            userId: sessionUser.id,
            trackId: track.id,
            trackAt
        }
        dispatch(postComment(track.id, newComment));
        setComment('');
    }

    const toggleQueue = async (e) => {
        const isInQueue = queue && queue.find(q => q.id === track.id);
        if (isInQueue) {
            dispatch(removeFromQueue(track));
        } else {
            dispatch(addToQueue(track));
        }
    }    

    const usersComments = track.Comments.filter((ele) => sessionUser && sessionUser.id === ele.userId);
    
    if (attributes['aria-pressed']) {
        dragStyle.opacity = 0.5;
    }

    const dragAttributes = canDrag ? {
        ...attributes,
        ...listeners,
        style: dragStyle
    } : {};

    const isInQueue = queue && queue.find(q => q.id === track.id);    
    
    return (
        <div ref={setNodeRef} {...dragAttributes} >
            <div className='flex flex-row pt-4 sm:mt-2 items-center flex-grow' style={style}>
                { 
                    showTrackImage && 
                    <div className='flex-initial relative sm:mr-8' style={{ minWidth: 'fit-content' }}>
                        <Link to={`/tracks/${track.id}`} className={trackImageSize + ' flex justify-center relative overflow-hidden'} style={track.token ? {filter:'drop-shadow(rgba(149, 168, 243, 0.7) 5px 5px 7px)'} : {}} >
                            <img src={track.imageUrl} alt={track.title} className={'w-auto h-auto rounded transform '} style={{background: "#fff1" }}></img>
                            <div className='absolute h-full w-full ' style={{ backgroundPosition: 'center', backgroundImage: 'url(' + track.imageUrl + ')', filter: 'blur(20px)', zIndex: -1, backgroundSize: '200%' }}></div>
                        </Link>
                    </div>
                }
                <div className='flex flex-grow sm:hidden relative'>                    
                    <div className='flex flex-col flex-grow ml-4'>
                        <span className=''>{track.User && track.User.username}</span>
                        <Link className='text-xl leading-none' to={`/tracks/${track.id}`}>{track.title}</Link>
                    </div>
                    {
                        showControls &&
                        <div className={'flex-1 flex justify-end items-center ml-2 ' + (track.trackStatus == 'processing' ? 'pointer-events-none opacity-10 ' : '')}>
                            <button disabled={track.trackStatus == 'processing'} 
                                    onClick={togglePlay} 
                                    className={'bg-mandarin hover:bg-mandarin-dark text-white font-bold h-10 w-10 rounded-full flex justify-center items-center focus:outline-none m-0 opacity-90 ' + (trackLoading && player?.track_id == track.id?styles.trackLoading:'')}>
                                <i className={`fas fa-${isPlaying && currentTrack && currentTrack.id === track.id ? 'pause' : 'play'}`}></i>
                            </button>
                        </div>
                    }
                </div>
                <div className='hidden flex-grow sm:flex flex-col w-full relative'>
                    <div className='flex flex-col sm:flex-row order-2 sm:order-none'>                        
                        <div className='flex flex-col flex-grow'>                            
                            <div className='flex items-center'>
                                {track.User && <Link className={'text-lg flex-1 ' + (darkMode ? 'text-tama-green' : 'text-tama-purple-dark')} to={`/user/${track.User.username.replace('?', '%3F')}`}>{track.User.username}</Link>}
                                <div className='opacity-50 text-right flex-1 whitespace-nowrap ml-4'>{track.Genre && <><Link to={'/genre/' + track.Genre.name}>{track.Genre.name}</Link> - </>} {timeSince(Date.parse(track.createdAt))} ago</div>
                            </div>
                            <div className='flex items-center'>
                                { 
                                    /*
                                    track.token &&
                                    <Link to={`/tracks/${track.id}/nft`} className='flex flex-col justify-center mr-2 mt-1' style={{color:'rgb(149, 168, 243)'}}>
                                        <Trident className='w-auto h-10' style={{fill: 'rgb(149, 168, 243)', width: '3.25rem', filter: 'drop-shadow(rgba(255, 255, 255, 0.4) 0px 0px 3px)'}} />
                                        <div className='text-center text-sm'>{ listing ? 'Buy It' : 'Minted' }</div>
                                    </Link>
                                    */
                                }
                                <Link className='text-3xl' to={`/tracks/${track.id}`} style={{}}>{track.title}</Link>
                            </div>                            
                        </div>
                    </div>

                    {
                        track.trackStatus == 'processing' && <div className='dotty' style={{ position: 'absolute', bottom: '45%', fontSize: 18, left: '46%' }}>Encoding</div>
                    }

                    <div className={'flex flex-row items-center order-1 relative mt-2 ' + (showReactions ? 'mb-8 ' : '') + (track.trackStatus == 'processing' ? 'pointer-events-none opacity-10 ' : '')}>
                        {
                            showControls &&
                            <button disabled={track.trackStatus == 'processing'} onClick={togglePlay}
                                className={'hidden sm:flex bg-mandarin hover:bg-mandarin-dark text-white font-bold h-12 w-12 mr-4 rounded-full justify-center items-center focus:outline-none m-0 ' + (trackLoading && player?.track_id == track.id ? styles.trackLoading : '')}>
                                <i className={`fas fa-${isPlaying && currentTrack && currentTrack.id === track.id ? 'pause' : 'play'} fa-lg`}></i>
                            </button>
                        }
                        <WaveForm track={track} height={waveformHeight} />
                        <div data-tip data-for='addTrack' className={'cursor-pointer mx-3 relative w-5 h-5 '+styles.queueIcons+(isInQueue?' '+styles.inQueue:'')} onClick={toggleQueue}>                            
                            <AddPlayListIcon title="Add to playlist" className={"cursor-pointer w-5 absolute text-tama-purple hover:text-tama-green "+styles.add} />                                                        
                            <AddedPlayListIcon className={"w-5 absolute "+styles.added} />
                            <RemovePlayListIcon className={"w-5 absolute "+styles.remove} />
                        </div>
                    </div>
                    {
                        showReactions &&
                        <div className={'order-3 flex items-center ' + (track.trackStatus == 'processing' ? 'pointer-events-none opacity-10 ' : '')}>
                            <TrackReact track={track} className="flex-none flex" />      
                            { 
                                track.token &&
                                <Link to={`/tracks/${track.id}/nft`} className='flex items-center ml-2' style={{color:'rgb(149, 168, 243)'}}>
                                    <Trident className='w-auto h-8' style={{fill: 'rgb(149, 168, 243)', width: '2.25rem', filter: 'drop-shadow(rgba(255, 255, 255, 0.4) 0px 0px 3px)'}} />
                                    <div className='text-center text-sm'>
                                        { 
                                            listing && 
                                                (
                                                    listing.is_auction != true 
                                                    || 
                                                    (
                                                        listing.is_auction == true && 
                                                        (listing.start_at == null || new Date(parseInt(listing.start_at)) <= new Date()) && 
                                                        (listing.end_at == null || new Date(parseInt(listing.end_at)) >= new Date())
                                                    )
                                                )
                                            ? 'Buy It' : 'Minted' 
                                        }
                                    </div> 
                                </Link>
                            }

                            <div className={`ml-4 flex-none flex items-center ${styles.comment} ${addCommentHasFocus ? styles.commentHasFocus : ''}`}>
                                <img src={sessionUser ? sessionUser.avatarUrl : '/img/userDefault.png'} alt='User' className='h-8 w-8 rounded-full'
                                    style={{ border: "2px solid transparent", boxShadow: "0 0 0 2px #6fcc5e" }}></img>
                                <form onSubmit={submitComment} className="flex-grow flex flex-row border-independence items-center ml-4">
                                    {
                                        sessionUser
                                            ? <>
                                                <textarea onFocus={() => setAddCommentHasFocus(true)}
                                                    onBlur={() => setAddCommentHasFocus(false)}
                                                    onChange={(e) => setComment(e.target.value)}
                                                    value={comment}
                                                    placeholder='Post a comment'
                                                    className="appearance-none w-56 flex-grow px-1.5 py-1.5 h-8 mr-4 rounded shadow text-black text-sm max-h-32"
                                                />
                                                <button type='submit' className="bg-mandarin hover:bg-mandarin-dark text-white font-bold py-1 px-4 rounded m-0">Comment</button>
                                            </>
                                            : <NavLink exact to='/login' className={`whitespace-nowrap`}>Sign In</NavLink>
                                    }
                                </form>
                                {
                                    usersComments.length > 0 && <span className='-ml-1'>{usersComments[0].content}</span>
                                }
                            </div>
                            <div className='flex-grow'></div>
                            <div className={`flex-none justify-end flex items-center px-2 rounded h-8 ${styles.stats}`} style={{ color: '#6fcc5e', background: '#fff1' }} >
                                <span>
                                    <Link to={`/tracks/${track.id}`}>
                                        {formatNumbers(track.Comments.length)} comment{track.Comments.length === 1 ? '' : 's'} &nbsp;<span className='opacity-40 text-xs'>&#9679;</span>&nbsp;&nbsp;
                                        {formatNumbers(track.Reactions.length)} reaction{track.Reactions.length === 1 ? '' : 's'} &nbsp;<span className='opacity-40 text-xs'>&#9679;</span>&nbsp;&nbsp;
                                    </Link>
                                </span>
                                {formatNumbers(track.playCount)}&nbsp;<PlayIcon height="12" width="12" fill="#6fcc5e" />
                            </div>
                        </div>
                    }
                </div>
            </div>
            {
                showReactions &&
                <div className='relative'>
                    {
                        track.trackStatus == 'processing' && <div className='dotty block sm:hidden' style={{ position: 'absolute', bottom: '40%', fontSize: 18, left: '40%' }}>Processing</div>
                    }
                    <div className={'flex flex-grow pt-4 pb-6 sm:hidden ' + (track.trackStatus == 'processing' ? 'pointer-events-none opacity-10' : '')} style={{ borderBottom: "1px solid #ffffff22" }} >
                        <TrackReact track={track} height={30} className={`flex whitespace-nowrap overflow-hidden ${addCommentHasFocus ? 'max-w-0 pr-0' : 'max-w-full'}`} />
                        <div className={'cursor-pointer mx-3 relative w-6 h-6 mt-1 '+styles.queueIcons+(isInQueue?' '+styles.inQueue:'')} onClick={toggleQueue}>
                            <AddPlayListIcon className={"cursor-pointer w-6 absolute "+styles.add} />
                            <AddedPlayListIcon className={"w-6 absolute "+styles.added} />
                            <RemovePlayListIcon className={"w-6 absolute "+styles.remove} />
                        </div>
                        { 
                            track.token &&
                            <Link to={`/tracks/${track.id}/nft`} className='flex items-center' style={{color:'rgb(149, 168, 243)'}}>
                                <Trident className='w-auto h-7' style={{fill: 'rgb(149, 168, 243)', width: '2.25rem', filter: 'drop-shadow(rgba(255, 255, 255, 0.4) 0px 0px 3px)'}} />
                                <div className='text-center text-sm'>
                                    { 
                                        listing && 
                                            (
                                                listing.is_auction != true 
                                                || 
                                                (
                                                    listing.is_auction == true && 
                                                    (listing.start_at == null || new Date(parseInt(listing.start_at)) <= new Date()) && 
                                                    (listing.end_at == null || new Date(parseInt(listing.end_at)) >= new Date())
                                                )
                                            )
                                        ? 'Buy It' : 'Minted' 
                                    }
                                </div> 
                            </Link>
                        }
                        <div className={`hidden flex-grow flex items-center max-w-full pl-4 ${styles.comment} ${addCommentHasFocus ? styles.commentHasFocus : ''}`}
                            onClick={() => { setAddCommentHasFocus(true); if (commentInputRef.current) { commentInputRef.current.focus(); } }}>
                            <img src={sessionUser ? sessionUser.avatarUrl : '/img/userDefault.png'}
                                alt='User' className='h-7 w-7 rounded-full'
                                style={{ border: "2px solid transparent", boxShadow: "0 0 0 2px #6fcc5e" }} />
                            <form onSubmit={submitComment} className="flex-grow flex flex-row border-independence items-center ml-4">
                                {
                                    sessionUser
                                        ? <>
                                            <textarea ref={commentInputRef}
                                                onFocus={() => setAddCommentHasFocus(true)}
                                                onBlur={() => setAddCommentHasFocus(false)}
                                                onChange={(e) => setComment(e.target.value)}
                                                value={comment}
                                                placeholder='Post a comment'
                                                className="appearance-none w-56 flex-grow px-1.5 py-1.5 h-8 mr-4 rounded shadow text-black text-sm max-h-32"
                                            />
                                            <button type='submit' className="bg-mandarin hover:bg-mandarin-dark text-white font-bold py-1 px-4 rounded m-0">Comment</button>
                                        </>
                                        : <NavLink exact to='/login' className={`whitespace-nowrap`}>Sign In</NavLink>
                                }
                            </form>
                            {
                                usersComments.length > 0 && <span className='-ml-1'>{usersComments.length} comments</span>
                            }
                        </div>
                        <div className='flex-grow'></div>
                        <div className={`flex-none justify-end flex items-center px-2 rounded ${styles.stats} ${addCommentHasFocus ? 'hidden' : ''}`} style={{ color: '#6fcc5e', background: '#fff1' }} >
                            {track.Comments.length}&nbsp;<CommentIcon height="11" width="11" fill="#6fcc5e" />&nbsp;&nbsp;
                            {track.playCount}&nbsp;<PlayIcon height="12" width="12" fill="#6fcc5e" />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

TrackCard.defaultProps = {
    track: false,
    showControls: true,
    showReactions: true,
    style: {},
    trackImageSize: 'w-16 sm:w-64 h-16 sm:h-64',
    darkMode: true,
    showTrackImage: true,
    canDrag: false,
    onMove: () => { },
    waveformHeight: 80,
}

export default TrackCard;
