import {isSafari, browserName, browserVersion} from 'react-device-detect';

export default function Footer() {
    return (
        <footer className='w-full bottom-0 flex justify-around text-silver h-40'>
            
            <div className="flex py-2 flex-col justify-center items-center">
            </div>
            <div className="flex py-2 flex-col justify-center items-center">                 
            </div>
        </footer>
    );
}
  