import { Link } from 'react-router-dom';
import TrackWaveForm from './TrackWaveForm';
import { useDispatch, useSelector } from 'react-redux';
import SkullIcon from '../../img/icons/skullz.png'
import BombIcon from '../../img/icons/bomb.png'
import ChestIcon from '../../img/icons/chest.png'
import { ReactComponent as LogoCrescent } from '../../img/tamago.svg';
import { ReactComponent as Trident } from '../../img/icons/trident.svg';
import { ReactComponent as LogoCrescentWhite } from '../../img/tamago-white.svg';
import { playTrack, playAudioTrack, stopTrack } from '../../store/player';

import TrackReact from "../Common/TractReact";
import { timeSince } from '../../utils/getTimeSince';
import { formatNumbers } from '../../utils/formatNumbers';
import { useEffect, useState } from 'react';
import { getTrack } from '../../store/track';
import Tooltip from '../Common/Tooltip';

const TrackMainInfo = ({ track, className, imagePos, showEditControls, titlePrefix, desktopTitleHeight }) => {
  const dispatch = useDispatch();
  const sessionUser = useSelector(state => state.session.user);
  // const uploadDateSplit = track.createdAt.split('-');
  // const uploadDate = `${uploadDateSplit[1]}/${uploadDateSplit[2].slice(0,2)}/${uploadDateSplit[0]}`
  const currentTrack = useSelector(state => state.player.currentTrack);
  const isPlaying = useSelector(state => state.player.isPlaying);
  const wavesurferPlayer = useSelector(state => state.player.wavesurfer);
  const nftContract = useSelector(state => state.near.nftContract);
  const marketContract = useSelector(state => state.near.marketContract);
  const [listing, setListing] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (nftContract && marketContract && track?.token) {
      nftContract.nft_token({ token_id: track.token }).then(t => setToken(t));
    }
  }, [nftContract, track]);

  useEffect(() => {
    if (nftContract && marketContract && token) {
      marketContract.get_sale({ nft_contract_token: nftContract.contractId + '||' + token.token_id }).then(s => { setListing(s); });
    }
  }, [nftContract, marketContract, token])

  const togglePlay = async (e) => {
    e.preventDefault();

    if (isPlaying && currentTrack.id === track.id) {
      wavesurferPlayer.pause();
      dispatch(stopTrack());
    } else {
      wavesurferPlayer.pause();
      dispatch(playTrack(track.id)).then(() => {
        dispatch(playAudioTrack());
        wavesurferPlayer.play();
      });
    }
  }

  useEffect(() => {
    let timer1;
    if (track.trackStatus == 'processing') {
      timer1 = setTimeout(() => {
        dispatch(getTrack(track.id));
      }, 5000);
    }
    return () => {
      clearTimeout(timer1);
    }

  }, [track]);

  return (
    <>
      {track.User && (
        <div className={'m-2 '+className}>
          <div className={'flex flex-col sm:flex-row '+(imagePos=='left'?'sm:flex-row-reverse':'')}>
            <div className='flex-grow flex flex-col justify-center order-2 sm:order-none'>
              <div className='flex'>
                <div className='flex-grow flex flex-col mt-5 p-2 sm:p-0 sm:mt-0'>
                  { titlePrefix }
                  <Link to={`/user/${track.User.username}`} className='text-md md:text-lg text-center sm:text-left ml-1' style={{ color: '#6fcc5e' }}>{track.User.username}</Link>
                  <div className={'text-3xl md:text-5xl text-center sm:text-left '+desktopTitleHeight}>
                    <div className='flex items-center flex-col-reverse sm:flex-row'>
                      {
                        track.token &&
                        <Link to={`/tracks/${track.id}/nft`} className='flex flex-col justify-center sm:mr-2 sm:-ml-1 mt-3 sm:mt-1' style={{ color: 'rgb(149, 168, 243)' }}>
                          <Trident className='w-auto h-10' style={{ fill: 'rgb(149, 168, 243)', width: '3.25rem', filter: 'drop-shadow(rgba(255, 255, 255, 0.4) 0px 0px 3px)' }} />
                          <div className='text-center text-sm'>
                            {
                              listing &&
                                (
                                  listing.is_auction != true
                                  ||
                                  (
                                    listing.is_auction == true &&
                                    (listing.start_at == null || new Date(parseInt(listing.start_at)) <= new Date()) &&
                                    (listing.end_at == null || new Date(parseInt(listing.end_at)) >= new Date())
                                  )
                                )
                                ? 'Buy It' : 'Minted'
                            }
                          </div>
                        </Link>
                      }
                      <div>{track.title}</div>

                      {track.token == null && sessionUser && track.User.id === sessionUser.id && showEditControls &&
                        <Link to={`/tracks/${track.id}/edit`} className='flex-grow inline-block sm:inline sm:text-right text-sm sm:w-auto text-white py-2 px-4 rounded my-0 mt-3 sm:mt-0' style={{ color: 'rgb(111, 204, 94)' }}>Edit Track</Link>
                      }
                    </div>

                  </div>
                  <div className='text-silver mb-4 sm:mb-4 mt-2 text-center sm:text-left'>{track.Genre.name} - {timeSince(Date.parse(track.createdAt))} ago</div>
                </div>
              </div>
              <div className='relative'>
                {
                  track.trackStatus == 'processing' && <div className='dotty' style={{ position: 'absolute', bottom: '62%', fontSize: 18, left: 'calc(50% - 40px)' }}>Encoding</div>
                }
                <div className={' ' + (track.trackStatus == 'processing' ? 'pointer-events-none opacity-10 ' : '')}>
                  <div className='flex justify-center'>
                    <button disabled={track.trackStatus == 'processing'} onClick={togglePlay}
                      className='sm:hidden bg-mandarin hover:bg-mandarin-dark text-white font-bold h-14 w-14 rounded-full justify-center items-center focus:outline-none m-0 mb-6'>
                      <i className={`fas fa-${isPlaying && currentTrack && currentTrack.id === track.id ? 'pause' : 'play'} fa-lg`} style={{ fontSize: '26px', marginLeft: '4px', marginTop: '5px' }}></i>
                    </button>
                  </div>
                  <TrackReact track={track} height={30} showCount={false} className="flex-none sm:hidden flex w-full justify-center mb-4" />
                  <TrackWaveForm track={track} />
                </div>
                <div className={'flex mt-8 mb-4 ' + (track.trackStatus == 'processing' ? 'pointer-events-none opacity-10 ' : '')}>
                  <TrackReact track={track} height={30} showCount={false} className="flex-none justify-start hidden sm:flex" />
                  <div className='flex-grow flex items-start gap-3 justify-center sm:justify-end' style={{ color: 'rgb(111, 204, 94)' }}>
                    <div className='items-center flex flex-row gap-1 ml-2'>
                      <img src={BombIcon} alt='bomb-icon' className='h-7 w-auto -mt-1' />
                      <span data-tip data-for='bombCount'>{formatNumbers(track.Reactions.filter(reaction => reaction.reactionType === 'bomb').length)}</span>
                      {track.Reactions.filter(reaction => reaction.reactionType === 'bomb').length > 1000 && <Tooltip id='bombCount' value={track.Reactions.filter(reaction => reaction.reactionType === 'bomb').length} />}
                    </div>
                    <div className='items-center flex flex-row gap-1'>
                      <img src={SkullIcon} alt='skull-icon' className='h-6 w-auto' />
                      <span data-tip data-for='skullCount'>{formatNumbers(track.Reactions.filter(reaction => reaction.reactionType === 'skull').length)}</span>
                      {track.Reactions.filter(reaction => reaction.reactionType === 'skull').length > 1000 && <Tooltip id='skullCount' value={track.Reactions.filter(reaction => reaction.reactionType === 'skull').length} />}
                    </div>
                    <div className='items-center flex flex-row gap-1'>
                      <img src={ChestIcon} alt='chest-icon' className='h-6 w-auto' />
                      <span data-tip data-for='gemCount'>{formatNumbers(track.Reactions.filter(reaction => reaction.reactionType === 'gem').length)}</span>
                      {track.Reactions.filter(reaction => reaction.reactionType === 'gem').length > 1000 && <Tooltip id='gemCount' value={track.Reactions.filter(reaction => reaction.reactionType === 'gem').length} />}
                    </div>
                    <div className='items-center flex flex-row gap-1'>
                      <i className={`fas fa-play`} style={{ fontSize: '13px' }}></i>
                      <span data-tip data-for='playCount'>{formatNumbers(track.playCount)}</span>
                      {track.playCount > 1000 && <Tooltip id='playCount' value={track.playCount} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'flex flex-col items-center justify-center order-1 sm:order-none flex-none '+(imagePos=='left'?'sm:mr-8':'sm:ml-8')}>
              <img src={track.imageUrl} alt='Track' className='w-80 h-80 shadow-2xl rounded'></img>
            </div>
          </div>
          <div className='flex justify-center sm:justify-start'>

            {track.token == null && sessionUser && track.User.id === sessionUser.id && showEditControls &&
              <Link to={`/tracks/${track.id}/mint`}
                className='flex sm:float-right text-sm bg-mandarin sm:w-auto hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded my-0 mt-3 sm:mt-4'
                style={{ backgroundColor: 'rgb(149, 168, 243)', color: 'white', filter: 'drop-shadow(rgba(255, 255, 255, 0.4) 0px 0px 3px)' }}
              >
                <Trident className='w-auto h-5 mr-2' fill="#fff" />
                <span>Mint Track</span>
              </Link>
            }
          </div>
        </div>
      )}
    </>
  );
}

TrackMainInfo.defaultProps = {
  track: null, 
  className: null, 
  imagePos: null,
  showEditControls: true,
  titlePrefix: null,
  desktopTitleHeight: 'sm:my-3'
}

export default TrackMainInfo;
