import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { timeSince } from "../../utils/getTimeSince";
import { deleteComment, postCommentReaction, deleteCommentReaction } from '../../store/track';
import SkullIcon from '../../img/icons/skullz.png'
import BombIcon from '../../img/icons/bomb.png'
import { ReactComponent as DeleteIcon } from "../../img/icons/delete.svg";
import styles from '../Common/TrackReact.module.css'

const CommentReplies = ({ comment, sessionUser, track, replies }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [parentId, setParentId] = useState(null);
  const [showingReplies, setShowingReplies] = useState(false)

  const commentId = comment.id;
  const trackId = track.id;

  const like = async (type, liked, commentId) => {
    const newComment = {
      trackId,
      commentId,
    }

    if (sessionUser) {
      if (sessionUser.accountStatus != 'ok') {
        history.push('/verify');
      } else {
        if (liked)
          dispatch(deleteCommentReaction(track.id, commentId)).catch(err => {
            if (err?.status == 401) {
                history.push('/verify');
            }
          });
        else
          dispatch(postCommentReaction(track.id, commentId, newComment, type)).catch(err => {
            if (err?.status == 401) {
                history.push('/verify');
            }
          });
      }
    } else {
      history.push('/login');
    }
  }

  const showReplies = (commentId) => {
    setShowingReplies(!showingReplies);
    setParentId(commentId);
  }

  const hideReplies = () => {
    setShowingReplies(!showingReplies);
    setParentId(null);
  }  

  return (
    <>
      {replies && replies.length > 0 && 
        <div className="flex flex-col px-2">
          <div className="flex flex-col w-full my-2 ml-2">
            <span
              className='sm:ml-8 text-gray-400 text-sm hover:underline cursor-pointer'
              onClick={() => { showingReplies ? hideReplies() : showReplies(commentId) }}>{showingReplies ? 'Hide replies' : 'View all replies'}</span>
          </div>
          {showingReplies && <div className="flex flex-col sm:ml-8 text-white">
            {replies.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map((reply) => {
              const content = reply.content.split(' ');
              const firstWord = content[0];
              const restOfWords = content.slice(1);
              const highlightedContent = <span className="text-blue-400 text-sm">{firstWord}</span>;
              const restOfContent = restOfWords.join(' ');

              return (
                <div className="flex flex-col w-full ml-2" key={reply.id}>
                  <div className="flex flex-grow flex-row items-center space-x-1 sm:space-x-2">
                    <div className="py-4 sm:py-2 flex flex-row items-center space-x-1 sm:space-x-2">
                      <div className="">
                        <img className="h-5 w-5 max-w-none rounded-full" src={reply.User.avatarUrl} alt="user avatar"></img>
                      </div>
                      <div className="">
                        <div className="text-link text-xs sm:text-sm">
                          <Link to={`/user/${reply.User.username}`}>{reply.User.firstName}</Link>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <div className="px-2 py-2 w-2/3 sm:w-3/4">
                        <div className="flex flex-row items-center space-x-2">
                          {highlightedContent}
                          <p className="whitespace-nowrap text-white text-sm truncate">{restOfContent}</p>
                        </div>
                      </div>
                      <div className='items-right text-sm whitespace-nowrap opacity-50'>
                        {timeSince(Date.parse(reply.createdAt), true)}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-start mb-2">
                    <div className='flex-grow flex items-center justify-start ml-4' style={{ color: 'rgb(111, 204, 94)' }}>
                      {
                        ['bomb', 'skull'].map(type => {

                          const reactions = track.Reactions &&
                            track.Reactions.filter(reaction => Number(reaction.commentId) === reply.id && reaction.reactionType === type)

                          const liked = sessionUser && reactions && reactions.find(curVal => curVal.userId === sessionUser.id);                          

                          let likeIcon = '';
                          switch (type) {
                            case ('bomb'):
                              likeIcon = <div className={`w-10 items-center flex flex-row gap-1 ml-2 cursor-pointer `} onClick={(e) => { like(type, liked, reply.id) }}>
                                <img src={BombIcon} alt='bomb-icon' className={`h-5 w-auto -mt-1 ${liked?styles.likedsmall:''}`} />
                                <span className="hover:text-white text-sm font-bold">{track.Reactions.filter(reaction => Number(reaction.commentId) === reply.id && reaction.reactionType === 'bomb').length}</span>
                              </div>
                              break;
                            case ('skull'):
                              likeIcon = <div className={`w-10 items-center flex flex-row gap-1 cursor-pointer`} onClick={(e) => { like(type, liked, reply.id) }}>
                                <img src={SkullIcon} alt='skull-icon' className={`h-5 w-auto -mt-1 ${liked?styles.likedsmall:''}`} />
                                <span className="hover:text-white text-sm font-bold">{track.Reactions.filter(reaction => Number(reaction.commentId) === reply.id && reaction.reactionType === 'skull').length}</span>
                              </div>
                              break;
                            default:
                              break;
                          }

                          return (
                            <div key={type}>
                              {likeIcon}
                            </div>
                          )
                        })
                      }
                      {
                        sessionUser && reply.User.id === sessionUser.id &&
                        <>
                          <div className='flex flex-row items-center cursor-pointer hover:text-white' 
                               onClick={() => { 
                                  dispatch(deleteComment(track.id, comment.id)).catch(err => { 
                                    if (err?.status == 401) { 
                                      history.push('/verify'); 
                                    } 
                                  }); 
                               }}>
                            <DeleteIcon className='w-4 h-4 ml-2 -mt-1' />
                            <span className='ml-2 text-sm'>Delete</span>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              );
            })}
          </div>}
        </div>
      }
    </>
  );
}

export default CommentReplies;
