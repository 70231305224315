import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link, useParams } from 'react-router-dom';
import { forgotPassword } from '../../store/session';
import { fetch } from '../../store/csrf';
import configData from '../../config.json';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useDocumentTitle } from '../Common/useMeta';

const ResetPasswordPage = () => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const sessionUser = useSelector(state => state.session.user);
    const [ password, setPassword ] = useState('');    
    const [ inputErrors, setInputErrors ] = useState([]);
    const [ isTokenInvalid, setIsTokenInvalid ] = useState(false);
    const [ isTokenValid, setIsTokenValid ] = useState(false);
    const [ passwordReset, setPasswordReset ] = useState(false);
    const [ resetting, setResetting ] = useState(false);
    const [ user, setUser ] = useState(null);
    const { trackPageView } = useMatomo();

    useDocumentTitle('Reset Password');

    // Track page view
    useEffect(() => {
        trackPageView()
    }, []);

    useEffect(() => {
        if (token) {
            fetch(configData.API_URL + `/api/session/reset`, {
                method: 'POST',
                body: JSON.stringify({
                    token              
                })
            }).then(res => {
                setIsTokenValid(true);
                setIsTokenInvalid(false);
                setUser(res.data.user);
            }).catch(res => {
                setIsTokenInvalid(true);
                setIsTokenValid(false);
                setUser(null);
            });
        }
    }, [token])

    if (sessionUser) return (
        <Redirect to='/' />
    )

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setInputErrors([]);
        setResetting(true);

        return fetch(configData.API_URL + `/api/session/updatePassword`, {
            method: 'POST',
            body: JSON.stringify({
                token,
                userId: user.id,
                password
            })
        }).then(res => {
            setResetting(false);
            setPasswordReset(true); 
        }).catch(res => {
            setResetting(false);
            setInputErrors(['Error updating password']);
        });           
    }; 

    return (
        <div className="w-full mx-auto absolute min-h-full pb-32 py-5 flex-1 flex flex-col justify-center items-center bg-background2 bg-center bg-cover mt-12 sm:mt-24">            
            <h1 className="block text-2xl text-white my-2 text-center">Reset Password</h1>
            <form onSubmit={handleSubmit} className="px-8 py-6 w-5/6 sm:w-96 mb-4 shadow-lg rounded bg-white glow">                
                { inputErrors.length > 0 && 
                    <ul id="login-errors" className="block my-2 text-center text-red-600 font-bold">
                        {inputErrors.map((error, idx) => <li key={idx}>{error}</li>)}
                    </ul>
                }
                {
                    !isTokenInvalid && !isTokenValid && <div className='font-bold text-black text-lg text-center'>Validating...</div>
                }
                {
                    isTokenInvalid && !isTokenValid && <div className='text-black text-lg text-center'>Password reset link is invalid or expired.  Please try again or <Link to='/login' className="text-mandarin">sign in</Link></div>
                }
                {  
                    !isTokenInvalid && isTokenValid && passwordReset && 
                    <div className='text-black text-lg text-center'>Your password has been successfully reset.  You can now <Link to='/login' className="text-mandarin">sign in</Link>.</div> 
                }
                {  
                    !isTokenInvalid && isTokenValid && !passwordReset && 
                    <>
                        <label className="block font-bold text-black">
                            New Password
                            <input
                                type='password'
                                placeholder='Enter your new password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 " 
                                style={{background: '#1d50b322'}}
                                autoComplete="new-password"
                            />
                        </label>                              
                        <div className='mt-2 flex justify-around flex-col sm:flex-row'>
                            <button type='submit' disabled={resetting} className={"sm:w-1/2 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded mt-4 mb-0 "+(resetting?'dotty':'')}>{resetting?'Processing':'Update Password'}</button>
                        </div>
                    </>
                }
            </form>             
        </div>
    );
}

export default ResetPasswordPage;
