import { getConfiguredCache } from 'money-clip'

import { fetch } from './csrf';
import configData from '../config.json'

const tipsCache = getConfiguredCache({ version: 1, maxAge: 60000 }); // 60 second cache

const INIT_NEAR = 'near/init';
const SET_TIPS = 'near/setTips';

const initialState = { api: null, config: null, wallet: null, tipContract: null, nftContract: null, marketContract: null, tipData: [] };

export const initNear = ({ api, config, wallet, tipContract, nftContract, marketContract }) => {    
    return {
        type: INIT_NEAR,
        payload: {
            api, config, wallet, tipContract, nftContract, marketContract
        },
    };
}

const setTips = (trackId, tips, syncing = false) => {
    return {
        type: SET_TIPS,
        payload: {
            trackId,
            tips,
            syncing
        },
    }
}

export const getTips = (trackId) => async(dispatch, getState) => {
    const state = getState();    
    if (state.near.tipData && state.near.tipData[trackId]?.syncing) {
        return; // do nothing, currently syncing track tip data
    }

    // set to syncing
    dispatch(setTips(trackId, {count: 0, total: 0}, true));
    
    // check local db cache
    const tips = await tipsCache.get(trackId);    
    if (tips) {
        dispatch(setTips(trackId, tips, false));
    } else {        
        const res = await state.near.tipContract.getTipsTrackTotal({ trackId: ''+trackId });
        if (res?.success && res.data) {
            // update cache and store data
            await tipsCache.set(trackId, res.data);
            dispatch(setTips(trackId, res.data, false));            
        } else {
            // error getting data from contract, set to zeroes
            dispatch(setTips(trackId, {count: 0, total: 0}, false));
        }        
    }

}

export default function nearReducer(state = initialState, action) {
    let newState;
    switch (action.type) {
        case INIT_NEAR:
            newState = Object.assign({}, state);
            newState.api = action.payload.api;
            newState.config = action.payload.config;
            newState.wallet = action.payload.wallet;
            newState.tipContract = action.payload.tipContract;
            newState.nftContract = action.payload.nftContract;
            newState.marketContract = action.payload.marketContract;
            return newState;
        case SET_TIPS: 
            newState = Object.assign({}, state);
            newState.tipData[action.payload.trackId] = {
                tips: action.payload.tips,
                syncing: action.payload.syncing
            };
            return newState;
        default:
            return state;
    };
};