import NeonWave1 from '../img/icons/NeonWave1.svg';
import NeonWave2 from '../img/icons/NeonWave2.svg';
import NeonWave3 from '../img/icons/NeonWave3.svg';
import NeonWave4 from '../img/icons/NeonWave4.svg';
import NeonWave5 from '../img/icons/NeonWave5.svg';
import NeonWave6 from '../img/icons/NeonWave6.svg';
import NeonWave7 from '../img/icons/NeonWave7.svg';
import NeonWave8 from '../img/icons/NeonWave8.svg';
import NeonWave9 from '../img/icons/NeonWave9.svg';
import NeonWave10 from '../img/icons/NeonWave10.svg';
import NeonWave11 from '../img/icons/NeonWave11.svg';
import NeonWave12 from '../img/icons/NeonWave12.svg';
import NeonWave13 from '../img/icons/NeonWave13.svg';
import NeonWave14 from '../img/icons/NeonWave14.svg';
import NeonWave15 from '../img/icons/NeonWave15.svg';
import NeonWave16 from '../img/icons/NeonWave16.svg';
import NeonWave17 from '../img/icons/NeonWave17.svg';
import NeonWave18 from '../img/icons/NeonWave18.svg';
import NeonWave19 from '../img/icons/NeonWave19.svg';
import NeonWave20 from '../img/icons/NeonWave20.svg';
import NeonWave21 from '../img/icons/NeonWave21.svg';
import NeonWave22 from '../img/icons/NeonWave22.svg';
import NeonWave23 from '../img/icons/NeonWave23.svg';
import NeonWave24 from '../img/icons/NeonWave24.svg';
import NeonWave25 from '../img/icons/NeonWave25.svg';

// return random image
const getGenreIcon = (ind) => {
  const icons = [
    NeonWave1,
    NeonWave2,
    NeonWave3,
    NeonWave4,
    NeonWave5,
    NeonWave6,
    NeonWave7,
    NeonWave8,
    NeonWave9,
    NeonWave10,
    NeonWave11,
    NeonWave12,
    NeonWave13,
    NeonWave14,
    NeonWave15,
    NeonWave16,
    NeonWave17,
    NeonWave18,
    NeonWave19,
    NeonWave20,
    NeonWave21,
    NeonWave22,
    NeonWave23,
    NeonWave24,
    NeonWave25,
  ];

  return icons[ind % icons.length];

  // adds up the numeric value of each character in categoryName. This will prevent the icon from changing on every render
  //const val = Array.from(categoryName).reduce((p,c) => { return p + c.codePointAt(0); }, 0);  

  //return icons[val % icons.length];

  /*
  const randomIndex = Math.floor(Math.random() * (icons.length));
  return icons[randomIndex];
  */
}

export default getGenreIcon;
