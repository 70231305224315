import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { ThreeCircles, Audio, BallTriangle, MutatingDots } from 'react-loader-spinner'
import * as nearAPI from 'near-api-js';

import { getTrack, postReaction } from '../../store/track';

import { ReactComponent as NearIcon } from '../../img/icons/icon_nm.svg'
import { ReactComponent as NearType } from '../../img/icons/near_type.svg'
import RecordStoreIcon from '../../img/icons/record-store.png';
import { assignWallet } from "../../store/session";

import { timeSince } from '../../utils/getTimeSince';
import { getTextWidth } from '../../utils/getTextWidth';

import { useDocumentTitle } from "../Common/useMeta";
import TrackCard from "../Common/TrackCard";
import { useQuery } from "../../utils/useQuery";

const SendTip = () => {

    const { utils } = nearAPI;
    const { id } = useParams();
    const query = useQuery();
    const history = useHistory();
    const dispatch = useDispatch();
    const track = useSelector(state => state.track.track);
    const sessionUser = useSelector(state => state.session.user);
    const nearWallet = useSelector(state => state.near.wallet);
    const nearConfig = useSelector(state => state.near.config);
    const nearTipContract = useSelector(state => state.near.tipContract);
    const [tipAmt, setTipAmt] = useState(1);
    const [trackOwner, setTrackOwner] = useState(null);
    const [trackTips, setTrackTips] = useState([]);
    const [showTipHistory, setShowTipHistory] = useState(false);
    const [minTip, setMinTip] = useState(null);
    const [minTipError, setMinTipError] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const [usd, setUsd] = useState(null);

    const isConnected = nearWallet?.getAccountId() != '';

    useEffect(() => {
        fetch('https://helper.mainnet.near.org/fiat')
            .then(response => {
                return response.json();
            })
            .then(d => {
                setUsd(d?.near?.usd);
            });
    }, []);

    useEffect(async () => {
        if (sessionUser?.id && nearWallet?.getAccountId() && nearWallet.getAccountId() != sessionUser.nearAccount) {
            const nearAccount = nearWallet.account();
            const accessKey = await nearAccount.accessKeyForTransaction();
            dispatch(assignWallet({
                walletId: nearAccount.accountId,
                keyInfo: accessKey
            }));

        }
    }, [sessionUser, nearWallet.getAccountId()]);

    useEffect(() => {
        dispatch(getTrack(id)).then(() => setIsFetched(true));
    }, [id, dispatch]);

    useEffect(() => {
        if (nearTipContract && track) {
            nearTipContract.getTrackOwner({ trackId: '' + track.id }).then((trackOwner) => {
                setTrackOwner(trackOwner);
            });
            nearTipContract.getTipsTrack({ trackId: '' + track.id }).then((trackTips) => {
                setTrackTips(trackTips);
            });
            nearTipContract.getMinTip({}).then((minTip) => setMinTip(parseFloat(utils.format.formatNearAmount(minTip))));
        }
    }, [nearTipContract, track]);

    useEffect(() => {
        if (query && query.get('transactionHashes') && track?.id) {
            const tx = query.get('transactionHashes');

            const { providers } = nearAPI;
            const provider = new providers.JsonRpcProvider(nearConfig.archivalUrl);
            provider.txStatus(tx, nearWallet.getAccountId()).then(r => {
                if (r?.status?.SuccessValue) {
                    const result = JSON.parse(atob(r.status.SuccessValue));
                    if (result.success) {
                        if (track.Reactions.find(reaction => reaction.referenceId == tx)) {
                        } else {
                            dispatch(postReaction(track.id, 'gem', { referenceId: tx, referenceData: result.data.amount }));
                        }
                    } else if (result?.error_code == 'MIN_TIP') {
                        setMinTipError(true);
                    } else if (result?.error_code == 'NO_OWNER') {
                    }
                }
            });
        }
    }, [query, track]);

    useEffect(() => {
        if (history) {
            if (!sessionUser) {
                history.replace(`/login`);
            }
        }
    }, [history, sessionUser]);

    const connectWallet = () => {
        nearWallet.requestSignIn(
            nearConfig.logIntoAccount,
            'Tamago',
            null,
            null
        );
    }

    const disconnectWallet = () => {
        nearWallet.signOut();
        dispatch(assignWallet({ walletId: null }));
    }

    const sendTip = async () => {
        const { utils } = nearAPI;

        const amt = parseFloat(tipAmt);
        if (amt < minTip) {
            setMinTipError(true);
            return;
        }

        nearTipContract.addTip(
            { trackId: '' + track.id, },
            "100000000000000", // attached GAS (optional)
            utils.format.parseNearAmount(tipAmt.toString()) // attached deposit in yoctoNEAR (optional)
        );
    }

    useDocumentTitle('Send Tip');

    const tipsSent = trackTips && trackTips.filter(tip => tip.sender == nearWallet?.getAccountId()).sort((a, b) => parseInt(b.createdAt) - parseInt(a.createdAt));
    const tipSuccess = query?.get('transactionHashes') && !minTipError && trackTips && tipsSent.length > 0;

    /*
    if (tipSuccess){
        // Call notification endpoint
        fetch(configData.API_URL + '/api/track/' + track.id + '/sendTipNotification/' + sessionUser.id, {
            method: 'POST',
            body: JSON.stringify({
                credential
            }),
        });
    }
    */


    return (
        <>
            {
                isFetched && track &&
                <div className="w-full mx-auto absolute min-h-full pb-32 py-5 flex-1 flex flex-col justify-center items-center bg-background2 bg-center bg-cover mt-12 sm:mt-24">
                    <form className="p-6 sm:p-8 w-5/6 sm:w-1/4 sm:min-w-max mb-4 shadow-lg rounded bg-white glow">
                        <div className='flex flex-col justify-center items-center text-black'>
                            <TrackCard darkMode={false} track={track} showReactions={false} showControls={false} trackImageSize='w-16 h-16 sm:h-48 sm:w-48' style={{ paddingTop: 0, marginBottom: '1.5rem' }} />
                            {
                                track.User.nearAccount == null &&
                                <div className="text-xl my-4">
                                    {track.User && <Link className='text-tama-purple-dark' to={`/user/${track.User.username.replace('?', '%3F')}`}>{track.User.username}</Link>}
                                    &nbsp;has not connected their wallet yet
                                </div>
                            }

                            {
                                tipSuccess && <div className='text-2xl mt-2 flex items-center'>
                                    You successfully sent a tip of {utils.format.formatNearAmount(tipsSent[0].amount)} <NearType className='mx-1 h-2.5 inline' />
                                    to&nbsp;<Link className="text-tama-purple-dark" to={'/user/' + track.User.username}>@{track.User.username}</Link>
                                </div>
                            }

                            {
                                isConnected && track.User.nearAccount && track.User.nearAccount == trackOwner && !tipSuccess &&
                                <div className="mb-2">
                                    {minTipError && <div className="text-red block mb-2 text-xl" style={{ color: 'red' }}>Minimum tip amount is {minTip}</div>}
                                    <div className="flex justify-center items-center">
                                        <input type='text' value={tipAmt} onChange={(e) => { setMinTipError(false); setTipAmt(e.target.value.replace(/([^0-9.]+)/, '')); }}
                                            className="rounded w-10 h-10 text-center text-xl"
                                            style={{ border: '#6fcc5e solid 2px', outline: 'none', minWidth: '2.5rem', width: getTextWidth(tipAmt, '20px "NoyhRLight", sans-serif') + 15 }}
                                        />
                                        <NearType className='ml-2 h-4' />
                                    </div>
                                    <div className="flex text-sm justify-center whitespace-nowrap w-full mt-4" >{usd && <>≈{(tipAmt * usd).toFixed(2)} USD</>}</div>
                                    <div className="flex justify-center">
                                        <button onClick={sendTip} type='button' className="w-32 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded mb-0 mt-0">Send Tip</button>
                                    </div>
                                </div>
                            }
                            {
                                !isConnected &&
                                <div className="mb-2">
                                    <div className="mb-1 text-center text-xl">Connect your wallet to send tips</div>
                                    <div onClick={connectWallet} className="flex flex-col items-center justify-center text-black border rounded px-4 py-2 bg-white shadow-lg cursor-pointer w-36 mx-auto mt-4">
                                        <NearIcon height={60} className='m-4' /> Connect Wallet
                                    </div>
                                </div>
                            }


                            {
                                trackTips && showTipHistory && tipsSent.length > 0 && tipsSent.map((tip, ind) => {
                                    var created = new Date(1640995200000 + (parseInt(tip.createdAt) * 1000));
                                    return <div key={'tip' + ind} className='text-xl mt-2 flex items-center'>
                                        You successfully sent a tip of {utils.format.formatNearAmount(tip.amount)} <NearType className='mx-1 h-2 inline' />
                                        <span className="text-sm">- {timeSince(created)} ago</span>
                                    </div>
                                })
                            }
                            {
                                tipSuccess && trackTips && tipsSent.length > 0 &&
                                <span className="mt-4 cursor-pointer text-xl text-tama-purple-dark" onClick={() => setShowTipHistory(!showTipHistory)}>{showTipHistory ? 'Hide' : 'Show'} history</span>
                            }
                        </div>
                    </form>
                    <Link to={'/tracks/' + track.id} className='flex flex-col items-center text-xl mt-8'><img src={RecordStoreIcon} width={30} className='mb-2' /> Back to track</Link>
                </div>
            }
            {
                !isFetched &&
                <div className='mt-40 flex-1'>
                    <div className='flex items-center justify-center h-full'>
                        <BallTriangle color="#6fcc5e33" height={70} width={70} ariaLabel="three-circles-rotating" />
                    </div>
                </div>
            }
        </>
    );
}

export default SendTip;