import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams, Link } from 'react-router-dom';
import * as nearAPI from 'near-api-js';
import { getTrack, mintTrack, uploadProgress, clearUploadInfo } from '../../store/track';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { ReactComponent as NearType } from '../../img/icons/near_type.svg'
import { Application, DateTimePicker } from 'react-rainbow-components';

import { useDocumentTitle } from '../Common/useMeta';
import TrackCard from '../Common/TrackCard';
import { getTextWidth } from '../../utils/getTextWidth';
import { useQuery } from '../../utils/useQuery';
import { util } from 'wavesurfer.js';

function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
  
    return (hrs ? (hrs + ' hr ') : '') + (mins ? (mins + ' mins ') : '') + secs + ' secs';
}

const ListTrack = ({ }) => {

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate()+1);

    const { id } = useParams();
    const query = useQuery();
    const dispatch = useDispatch();
    const history = useHistory();
    const { utils } = nearAPI;
    const sessionUser = useSelector(state => state.session.user);
    const nearWallet = useSelector(state => state.near.wallet);
    const nearConfig = useSelector(state => state.near.config);
    const nftContract = useSelector(state => state.near.nftContract);
    const marketContract = useSelector(state => state.near.marketContract);    
    const track = useSelector(state => state.track.track);

    const [inputErrors, setInputErrors] = useState([]);
    const [accessDenied, setAccessDenied] = useState(false);
    const [token, setToken] = useState(null);
    const [listing, setListing] = useState(null);
    const [usd, setUsd] = useState(null);
    const [listingFee, setListingFee] = useState(0.01);
    const [deposited, setDeposited] = useState(0);
    const [sellingPrice, setSellingPrice] = useState(1);
    const [listingType, setListingType] = useState('fixed');
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);
    const [nftType, setNftType] = useState(null);
    const [nftBlob, setNftBlob] = useState(null);

    const { trackPageView } = useMatomo();

    useDocumentTitle('List Track');

    // Track page view
    useEffect(() => {
        trackPageView();
        fetch('https://helper.mainnet.near.org/fiat')
            .then(response => {
                return response.json();
            })    
            .then(d => {    
                setUsd(d?.near?.usd);
            });
    }, []);    

    useEffect(() => {
        if (nftContract && track) {
            if (track.token == null) {
                setAccessDenied(true);
            } else {
                nftContract.nft_token({ token_id: track.token }).then(t => {
                    if (t.owner_id != sessionUser.nearAccount) setAccessDenied(true);
                    setToken(t);
                });
            }
        }
    }, [track, nftContract]);

    // get listing fee from market contract
    useEffect(() => {
        if (marketContract && token && nftContract) {
            marketContract.storage_minimum_balance().then(f => setListingFee(f));
            marketContract.storage_balance_of({ account_id: sessionUser.nearAccount }).then(f => setDeposited(f));
            marketContract.get_sale({ nft_contract_token: nftContract.contractId+'||'+token.token_id }).then(s => {
                if (s) {
                    setListing(s);
                    setListingType(s?.is_auction == true ? 'auction' : 'fixed');
                    setSellingPrice( utils.format.formatNearAmount(s?.sale_conditions?.near));      
                    if (s?.is_auction == true) {
                        if (s.start_at != '0') setStartDateTime(new Date(parseInt(s.start_at)));
                        if (s.end_at != '0') setEndDateTime(new Date(parseInt(s.end_at)));
                    }
                }
            });            
        }
    }, [marketContract, token, nftContract]);

    useEffect(() => {        
        if (id) dispatch(getTrack(id)).then((t) => {
            if (sessionUser.id !== t.User.id) {
                
            } else {
                
            }            
        });
    }, [id, dispatch]);

    useEffect(() => {
        let errors = [];
        setInputErrors(errors);
    }, []);

    useEffect(() => {
        if (query && query.get('transactionHashes') && track?.id ) {            
            const tx = query.get('transactionHashes');

            const { providers } = nearAPI;
            const provider = new providers.JsonRpcProvider(nearConfig.archivalUrl);
            provider.txStatus(tx, sessionUser.nearAccount).then(r => {    
                if (r?.status?.SuccessValue) {
                    const result = JSON.parse(atob(r.status.SuccessValue));
                    if (result.success) {                        
                    }
                }
            });
        }
    }, [query, track]); 

    useEffect(() => {
        if (token) {
            fetch(token.metadata.media.replace('https://cloudflare-ipfs.com/ipfs/','https://ipfs.io/ipfs/')).then(r => { 
                if (r.headers.get("Content-Type").startsWith('image')) {
                    setNftType('image');
                } else if (r.headers.get("Content-Type").startsWith('video')) {
                    setNftType('video');
                }                
                return r.blob();
            }).then(b => {
                setNftBlob(window.URL.createObjectURL(b));                
            });
        }
    }, [token]);

    if (!sessionUser) {
        return (<Redirect to='/login' />);
    }

    if (accessDenied) {
        return (<Redirect to={`/tracks/` + id} />);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (deposited < listingFee) {
            return;            
        }

        if (listingType == 'fixed' && sellingPrice <= 0) {
            return;
        }

        if (listing) {
            if (listingType == 'fixed') {
                marketContract.update_price({
                    args: {
                        nft_contract_id: nftContract.contractId,
                        token_id: token.token_id,
                        ft_token_id: "near",
                        price: utils.format.parseNearAmount(sellingPrice.toString())
                    },
                    gas: "300000000000000",
                    amount: "1"
                });
            }
        } else {
            if (listingType == 'fixed') {
                nftContract.nft_approve({
                    args: {
                        token_id: token.token_id,
                        account_id: marketContract.contractId,
                        msg: '{"sale_conditions":{"near":"'+utils.format.parseNearAmount(sellingPrice.toString())+'"},"is_auction":false}'
                    },
                    gas: "300000000000000",
                    amount: utils.format.parseNearAmount('0.01')
                });
            } else if (listingType == 'auction') {      
                const msg = { sale_conditions: { near: '' } };
                msg.is_auction = true;
                msg.sale_conditions.near = (sellingPrice <= 0) ? "0" : utils.format.parseNearAmount(sellingPrice.toString());

                if (startDateTime) msg.start_at = startDateTime.getTime().toString();
                if (endDateTime)   msg.end_at   = endDateTime.getTime().toString();
                
                nftContract.nft_approve({
                    args: {
                        token_id: token.token_id,
                        account_id: marketContract.contractId,
                        msg: JSON.stringify(msg)
                    },
                    gas: "300000000000000",
                    amount: utils.format.parseNearAmount('0.01')
                });                 
                
            }
        }
    };

    const handleRemoveListing = async (e) => {
        e.preventDefault();
        if (listing) {
            marketContract.remove_sale({
                args: {
                    nft_contract_id: nftContract.contractId,
                    token_id: token.token_id
                },
                gas: "300000000000000",
                amount: "1"
            });
        }
    }

    return ( 
        <div className="w-full mx-auto absolute min-h-full pb-32 sm:pb-48 py-5 flex-1 flex flex-col justify-center items-center bg-background2 bg-center bg-cover mt-12 sm:mt-24">
            <form onSubmit={handleSubmit} className="mx-12 px-12 sm:px-0 sm:mx-0 w-full sm:w-2/3 sm:max-w-screen-lg">
                <div className={`w-full rounded shadow-lg bg-white max-h-full glow`} style={{ transition: 'all .3s ease' }}>
                    <div className={`px-6 sm:px-8 py-4`}>
                        <div className='flex flex-col text-black'>
                            <div className='flex flex-col sm:flex-row'>
                                <div className='w-72 h-full mr-8 max-w-full'>
                                    { nftType == 'image' && <img src={nftBlob}  /> }
                                    { nftType == 'video' && <video autoPlay={true} loop={true} src={nftBlob}  /> }
                                </div>
                                { track && <TrackCard darkMode={false} track={track} showReactions={false} showControls={false} showTrackImage={false} /> }
                            </div>
                            {
                                sessionUser?.nearAccount == null &&
                                <div className='text-space-cadet sm:ml-4 my-8 text-xl text-center'><Link className='text-tama-dark-green underline' to={'/user/'+sessionUser.username+'/wallet'}>Connect Wallet</Link> to enable selling</div>
                            }
                            {

                                <div className='w-full sm:w-2/5 mx-auto mt-8 relative'>
                                    {
                                        (deposited<listingFee) && listing == null && <div className='absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center' style={{zIndex: 10}}>
                                            <Link to={`/user/${sessionUser.username.replace('?','%3F')}/wallet/balance`} className='flex items-center bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded text-xl m-0 cursor-pointer opacity-1'>
                                                <NearType height={11} fill='#fff' className='mr-1' />
                                                <span>Deposit required</span>
                                            </Link>
                                        </div>
                                    }
                                    <div style={(deposited<listingFee)?{opacity: 0.2, pointerEvents: 'none'}:{}}>
                                        <div className='flex'>
                                            {   
                                                (listing == null || listing?.is_auction === false) &&
                                                <div className='flex-1 text-center cursor-pointer p-2' 
                                                    style={{ backgroundColor: listingType == 'fixed' ? '#fff' : '#eee', borderTopLeftRadius: '0.25rem', borderWidth: 1, borderBottom: 'none' }}
                                                    onClick={() => setListingType('fixed')}
                                                >
                                                    Fixed Price
                                                </div>
                                            }
                                            {   
                                                (listing == null || listing?.is_auction === true) &&
                                                <div className='flex-1 text-center cursor-pointer p-2' 
                                                    style={{ backgroundColor: listingType == 'auction' ? '#fff' : '#eee', borderTopRightRadius: '0.25rem', borderWidth: 1, borderBottom: 'none' }} 
                                                    onClick={() => setListingType('auction')}
                                                >
                                                    Auction
                                                </div>
                                            }
                                        </div>
                                        <div className='flex flex-col items-center flex-grow justify-center border rounded p-2 h-auto' style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 'none', minHeight: 330 }}>
                                            {
                                                listingType == 'fixed' && 
                                                <>                                                
                                                    <label className='text-space-cadet font-bold cursor-pointer text-xl'>{ listing ? 'Update Price' : 'Selling Price' }</label>
                                                    <input type='text' 
                                                        className="block appearance-none w-full p-2 rounded text-black mt-2 mb-4 text-center" 
                                                        style={{ background: '#1d50b322', minWidth: 40, width: getTextWidth(sellingPrice, '16px "NoyhRLight", sans-serif')+17 }} 
                                                        value={sellingPrice} 
                                                        onChange={(e) => setSellingPrice(e.target.value.replace(/([^0-9.]+)/, ''))}
                                                        />
                                                    <NearType height={12} fill='#333' />
                                                    { sellingPrice > 0 && usd && <div className='text-space-cadet ml-1 text-sm'>≈{(sellingPrice*usd).toFixed(2)} USD</div>}   
                                                    { sellingPrice <= 0 && <div className='text-space-cadet ml-1 text-sm'>Price must be greater then zero</div>}   
                                                </>
                                            }
                                            {
                                                listingType == 'auction' && 
                                                <div className='flex flex-col p-4 w-full items-center'>               

                                                    <label className='text-space-cadet font-bold cursor-pointer text-xl text-center' style={{lineHeight: 1}}>Reserve Price<br /><span className='font-normal text-base'>(optional)</span></label>
                                                    <input type='text' 
                                                        className="block appearance-none w-full p-2 rounded text-black mt-2 mb-4 text-center" 
                                                        style={
                                                            listing 
                                                            ? 
                                                                { 
                                                                    background: '#fff', 
                                                                    minWidth: 40, 
                                                                    fontSize: 28, 
                                                                    fontWeight: 'bold', 
                                                                    padding:0, 
                                                                    margin:0,
                                                                    width: getTextWidth(sellingPrice, '28px "NoyhRLight", sans-serif')+17 
                                                                } 
                                                            : 
                                                                { 
                                                                    background: '#1d50b322', 
                                                                    minWidth: 40, 
                                                                    width: getTextWidth(sellingPrice, '16px "NoyhRLight", sans-serif')+17 
                                                                }
                                                        } 
                                                        value={sellingPrice} 
                                                        disabled={listing ? true : false}
                                                        readOnly={listing ? true : false}
                                                        onChange={(e) => setSellingPrice(e.target.value.replace(/([^0-9.]+)/, ''))}
                                                    />
                                                    <NearType height={12} fill='#333' />
                                                    { sellingPrice != '' && usd && <div className='text-space-cadet ml-1 text-sm'>≈{(sellingPrice*usd).toFixed(2)} USD</div>}   
                                                    { sellingPrice == '' && <div className='text-space-cadet ml-1 text-sm'>Open to all offers</div>}   

                                                    <label className='text-space-cadet font-bold text-left mt-4 w-full text-left'>Starting <span className='font-normal'>(optional)</span></label>
                                                    <DateTimePicker 
                                                        formatStyle='large' 
                                                        className='datetimePicker'
                                                        minDate={new Date()} 
                                                        value={startDateTime} 
                                                        readOnly={listing ? true : false}
                                                        onChange={(d) => setStartDateTime(d) } 
                                                    />                                                

                                                    <label className='text-space-cadet font-bold text-left mt-4 w-full text-left'>Ending <span className='font-normal'>(optional)</span></label>
                                                    <DateTimePicker 
                                                        formatStyle='large' 
                                                        className='datetimePicker'
                                                        minDate={startDateTime || new Date()} 
                                                        value={endDateTime} 
                                                        readOnly={listing ? true : false}
                                                        onChange={(d) => setEndDateTime(d)} 
                                                    />

                                                    { 
                                                        !listing && (startDateTime != null || endDateTime != null) &&
                                                        <div className='mt-6 rounded border px-2 py-1 text-sm cursor-pointer' onClick={() => { setStartDateTime(null); setEndDateTime(null); }}>Clear Dates</div>
                                                    }

                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>                                
                        
                        
                        <ul id="login-errors" className="block my-2 text-center text-red-600 font-bold">
                            {inputErrors.map((error, idx) => <li key={idx}>{error}</li>)}
                        </ul>
                        { 
                            sessionUser?.nearAccount && 
                            <div className='my-2 mt-6 flex flex-col items-center' style={(deposited<listingFee)?{opacity: 0.2, pointerEvents: 'none'}:{}}>
                                {
                                    (listing == null || (listing && listingType == 'fixed')) &&                                
                                    <button type='submit' 
                                            disabled={inputErrors.length || (listingType == 'fixed' && sellingPrice <= 0) } 
                                            className={`w-72 sm:w-2/6 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded text-xl m-0 ${inputErrors.length || (listingType == 'fixed' && sellingPrice <= 0) ? 'opacity-50' : ''}`}>
                                        { listing ? 'Update Listing' : 'List Track' }
                                    </button>
                                }
                                {
                                    listing == null &&
                                    <div className='text-space-cadet flex mt-2 text-sm'>
                                        Listing fee is { utils.format.formatNearAmount(listingFee) } <NearType height={8} fill='#333' className='mx-1 mt-1.5' />
                                        { usd && <div className='text-sm'>≈ {(utils.format.formatNearAmount(listingFee)*usd).toFixed(2)} USD</div>}   
                                    </div>
                                }
                                {
                                    listing &&
                                    <div className='flex justify-center mt-8 mb-2 cursor-pointer' style={ listingType == 'fixed' ? { color: 'red', marginTop: '2rem' } : { color: 'red', marginTop: 0 }} onClick={handleRemoveListing}>Remove Listing</div>
                                }
                            </div>
                        }            
                    </div>
                </div>                

            </form>
        </div>
    );
};

ListTrack.defaultProps = {
    track: null
}

export default ListTrack;
