import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer,createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk';
import sessionReducer from './session';
import uiReducer from './ui';
import nearReducer from './near';
import trackReducer from './track';
import playerReducer from './player';

const rootReducer = combineReducers({
    session: sessionReducer,
    ui: uiReducer,
    track: trackReducer,
    player: playerReducer,
    near: nearReducer
});

const playerTransform = createTransform(

    // transform state on its way to being serialized and persisted.
    (inboundState, key) => {
      // convert mySet to an Array.
      return { ...inboundState, wavesurfer: null, currentTrack: null, isPlaying: false, nextTrack: null };
    },

    // transform state being rehydrated
    (outboundState, key) => {
      // convert mySet back to a Set.
      return outboundState;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['player'] }
);
  

const persistConfig = {
    key: 'root',    
    storage,
    transforms: [playerTransform],
    whitelist: ['player']
}

let enhancer;
if (process.env.NODE_ENV === 'production') {
    enhancer = applyMiddleware(thunk);
} else {
    const logger = require('redux-logger').default;
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ 
        trace: true, 
        traceLimit: 25 
    }) || compose; 
    enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}



const configureStore = (preloadedState) => {
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    let store = createStore(persistedReducer, preloadedState, enhancer);
    let persistor = persistStore(store);
    return { store, persistor }
}

export default configureStore;
