import Cookies from 'js-cookie';
import configData from '../config.json'

export async function fetch(url, options = {}) {
    // set options.headers to an empty object if there is no headers
    options.headers = options.headers || {};
    // set options.method to 'GET' if there is no method
    options.method = options.method || 'GET';

    // if the options.method is not 'GET', then set the "Content-Type" header to
    // "application/json", and set the "XSRF-TOKEN" header to the value of the
    // "XSRF-TOKEN" cookie
    if (options.method.toUpperCase() !== "GET") {
        if (options.headers["Content-Type"] === "multipart/form-data") {
            delete options.headers["Content-Type"];
        } else {
            options.headers["Content-Type"] =
                options.headers["Content-Type"] || "application/json";
        }
        options.headers["XSRF-Token"] = Cookies.get("XSRF-TOKEN");
    }

    options.withCredentials = 'include';

    // call the default window's fetch with the url and the options passed in
    const res = await window.fetch(url, options);

    // if the response's body is JSON, then parse the JSON body and set it to a
    // key of `data` on the response
    const contentType = res.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
        const data = await res.json();
        res.data = data;
    }

    // if the response status code is 400 or above, then throw an error with the
    // error being the response
    if (res.status >= 400) throw res;

    // if the response status code is under 400, then return the response to the
    // next promise chain
    return res;
}

export function restoreCSRF() {
    return fetch(configData.API_URL + '/api/csrf/restore').catch((error) => {
        localStorage.removeItem('XSRF-TOKEN');
        console.log(error)
    }); //.then((res) => console.log('restoreCSRF', res, res.headers.get('set-cookie'), Cookies.get()));
}