import React, { useEffect, useState, useRef } from 'react';
import { playTrack, playAudioTrack, stopTrack, setTrack, setNextTrack } from '../../store/player';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { Helmet } from "react-helmet";
import TrackCard from '../Common/TrackCard';
import {
  getRecentTracks,
  getRecommendedTracks,
  getPopularTracks,
  selectGenre,
  getDownloadableTracks,
  getCryptoTracks,
  getNftTracks,
  getForsaleTracks,
} from '../../store/track';
import Select from 'react-select';
// import Typewriter from 'typewriter-effect';
import { EffectFade, Autoplay, EffectCube, EffectFlip } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import InfiniteScroll from "react-infinite-scroll-component";
// import Carousel from "react-simply-carousel";
import { BallTriangle, Audio } from 'react-loader-spinner'
import { isIOS } from 'react-device-detect';
import { useMatomo } from '@datapunt/matomo-tracker-react'
// import Logo from '../../img/logo.png';

import EGAEventImg from '../../img/events/ega-2022/EG-Flyer-Duke-(Artists)-FB-Events.png';
import ArtOfCuisineImg from '../../img/events/the-art-of-cuisine-industry-dinner.jpg';

import leftArrow from '../../img/icons/left-svgrepo-com.svg';
import rightArrow from '../../img/icons/right-svgrepo-com.svg';
import NeonWave1 from '../../img/icons/NeonWave1.svg'
import NeonWave2 from '../../img/icons/NeonWave2.svg'
import NeonWave3 from '../../img/icons/NeonWave3.svg'
import NeonWave4 from '../../img/icons/NeonWave4.svg'
import NeonWave5 from '../../img/icons/NeonWave5.svg'
import NeonWave6 from '../../img/icons/NeonWave6.svg'
import NeonWave8 from '../../img/icons/NeonWave8.svg'
import NeonWave9 from '../../img/icons/NeonWave9.svg'
import NeonWave10 from '../../img/icons/NeonWave10.svg'
import NeonWave19 from '../../img/icons/NeonWave19.svg'
import NeonWave25 from '../../img/icons/NeonWave25.svg'

import styles from './LandingPage.module.css'

import { useDocumentTitle, useMetaTags } from '../Common/useMeta';

import getGenreIcon from '../../utils/getGenreIcon';

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

const LandingPage = () => {
  const { genre, listtype } = useParams();
  const listType = [
    { value: 'recent', label: 'FRESH TRACKS' },
    { value: 'popular', label: 'TOP OF THE CHARTS' },
    { value: 'recommended', label: 'RECOMMENDED TRACKS' },
    { value: 'downloadable', label: 'CONTENT FOR CRYPTO' },
    { value: 'nfts', label: 'MINTED TRACKS' },
    //{ value: 'forsale', label: 'Minted Tracks For Sale (NFTs)' },
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const genres = useSelector(state => state.ui.genres);
  const queue = useSelector(state => state.player.queue);
  const queueLength = useSelector(state => state.player.queue?.length);
  // const sessionUser = useSelector(state => state.session.user);
  const trackList = useSelector(state => state.track.trackList);
  const trackListType = useSelector(state => state.track.trackListType);
  const stateGenre = useSelector(state => state.track.genre);
  const currentTrack = useSelector(state => state.player.currentTrack);
  const [selectedTrackListType, setSelectedTrackListType] = useState(listType.find(ele => ele.value === listtype) ?? listType[0]);
  const [swiperItems, setSwiperItems] = useState([]);
  const [isFetched, setIsFetched] = useState(true);
  const { trackPageView } = useMatomo();
  // const [showAllGenres, setShowAllGenres] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const [documentTitle, setDocumentTitle] = useDocumentTitle(selectedTrackListType.value == 'recent' ? null : selectedTrackListType.label, 'TAMAGO | ', ' ');
  const [metaTags, setMetaTags] = useMetaTags({}, 'TAMAGO | ', ' ');

  const genreSliderRef = useRef();
  const genreSearchRef = useRef();
  const [genreSearchStr, setGenreSearchStr] = useState('');
  const [showGenreSearch, setShowGenreSearch] = useState(false);

  const [daysSelected, setDaysSelected] = useState(30);
  const [downloadFilter, setDownloadFilter] = useState(false);

  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 500)
    window.addEventListener('resize', debouncedHandleResize)
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  });

  // Track page view
  useEffect(() => {
    trackPageView()
  }, []);

  /*
  useEffect(() => {
    if (stateGenre != genre) {
      setIsFetched(false);
      dispatch(selectGenre(genre)).then(() => {
        reloadTracks();
      });
    }
  }, [genre, stateGenre]);
  */

  useEffect(() => {
    if (isFetched) {      
      setIsFetched(false);
      if (stateGenre != genre) {
        dispatch(selectGenre(genre)).then(() => {
          reloadTracks();
        });
      } else {
        reloadTracks();
      }
    }
  }, [daysSelected, selectedTrackListType, genre, stateGenre])

  const reloadTracks = () => {
    switch (selectedTrackListType.value) {
      case 'recent':
        dispatch(getRecentTracks(null, daysSelected)).then(() => setIsFetched(true));
        break;
      case 'recommended':
        dispatch(getRecommendedTracks(null, daysSelected)).then(() => setIsFetched(true));
        break;
      case 'popular':
        dispatch(getPopularTracks(null, daysSelected)).then(() => setIsFetched(true));
        break;
      case 'downloadable':
        dispatch(getCryptoTracks(null, daysSelected)).then(() => setIsFetched(true));
        break;
      case 'nfts':
        dispatch(getNftTracks(null, daysSelected)).then(() => setIsFetched(true));
        break;
      case 'forsale':
        dispatch(getForsaleTracks(null, daysSelected)).then(() => setIsFetched(true));
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (trackListType !== '' && selectedTrackListType === '') {
      setSelectedTrackListType(listType.find(ele => ele.value === trackListType));
    }
  }, [trackListType])

  useEffect(() => {
    if (trackListType) {
      if (genre) {
        setDocumentTitle((listType.find(lt => lt.value === trackListType)?.label) + ' [' + genre + ']');
        setMetaTags({ ...metaTags, title: (listType.find(lt => lt.value === trackListType)?.label) + ' [' + genre + ']' });
      } else {
        setDocumentTitle(listType.find(lt => lt.value === trackListType)?.value != 'recent' && listType.find(lt => lt.value === trackListType)?.label);
        setMetaTags({ ...metaTags, title: listType.find(lt => lt.value === trackListType)?.label });
      }
    }
  }, [trackListType, genre]);

  useEffect(() => {
    if (queue && trackList?.rows && currentTrack == null) {
      if (queue.length > 0) {
        dispatch(playTrack(queue[0].id));
      }
      else if (trackList && trackList?.rows) {
        if (trackList?.rows.length) {
          dispatch(playTrack(trackList.rows[0].id));
        }
      }
      if (trackList && trackList?.rows) {
        setIsFetched(true);
      }
    }
  }, [trackList, queue, queueLength]);



  useEffect(() => {
    if (currentTrack && trackList && trackList.rows && trackList.rows.length) {
      const currentInd = trackList.rows.findIndex(ele => ele.id === currentTrack.id);
      if (trackList.rows.length === currentInd + 1) {
        dispatch(setNextTrack(trackList.rows[0]));
      } else if (currentInd !== -1) {
        dispatch(setNextTrack(trackList.rows[currentInd + 1]));
      }
    }
  }, [currentTrack, trackList]);

  useEffect(() => {
    async function fetchSwiperItems() {
      try {
        const res = await fetch("https://api.npoint.io/3f7d6da16e75d5fed4e1", {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        });
        const data = await res.json();
        setSwiperItems(data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchSwiperItems();
  }, []);

  const fetchMoreTracks = () => {
    switch (selectedTrackListType.value) {
      case 'recent':
        dispatch(getRecentTracks(trackList?.rows ? trackList.rows.length : null, daysSelected));
        break;
      case 'recommended':
        dispatch(getRecommendedTracks(trackList?.rows ? trackList.rows.length : null, daysSelected));
        break;
      case 'popular':
        dispatch(getPopularTracks(trackList?.rows ? trackList.rows.length : null, daysSelected));
        break;
      case 'downloadable':
        dispatch(getCryptoTracks(trackList?.rows ? trackList.rows.length : null, daysSelected));
        break;
      case 'nfts':
        dispatch(getNftTracks(trackList?.rows ? trackList.rows.length : null, daysSelected));
        break;
      case 'forsale':
        dispatch(getForsaleTracks(trackList?.rows ? trackList.rows.length : null, daysSelected));
        break;
      default:
        break;
    }
  }

  // handle change selectedDays
  const handleChangeDays = (days) => {
    setDaysSelected(days);
  }

  let hasMoreTrack = true;
  if (trackList && trackListType) {
    if (trackList?.rows?.length >= trackList.count) {
      hasMoreTrack = false;
    }
    if (trackListType === 'popular' && trackList?.rows?.length >= 100) {
      hasMoreTrack = false;
    }
  }

  const genreFilters = [
    { genre: '', name: 'All', icon: NeonWave25 },
    { genre: 'hiphop', name: 'Hip Hop', icon: NeonWave2 },
    { genre: 'house', name: 'House', icon: NeonWave5 },
    { genre: 'pop', name: 'Pop', icon: NeonWave4 },
    { genre: 'trance', name: 'Trance', icon: NeonWave6 },
    { genre: 'ambient', name: 'Ambient', icon: NeonWave3 },
    { genre: 'podcasts', name: 'Podcasts', icon: NeonWave1 },
    { genre: 'techno', name: 'Techno', icon: NeonWave8 },
    { genre: 'afro', name: 'Afro', icon: NeonWave9 },
    { genre: 'indie', name: 'Indie', icon: NeonWave10 },
    { genre: 'other', name: 'Other', icon: NeonWave19 },
  ];

  let genreStr = '';
  let genreEle = genreFilters.find(genreFilter => genreFilter.genre === genre);
  if (genreEle) genreStr = genreEle.name;
  else if (genre) genreStr = genre;

  const genreTileWidth = genreSliderRef.current?.firstChild.getBoundingClientRect().width;
  const genreTileDisplayed = (genreSliderRef.current?.offsetWidth / genreTileWidth) - 1;

  const prevGenreSlide = () => {
    if (activeSlideIndex === 0) {
      setActiveSlideIndex(genres.filter(genreFilterCB).length - genreTileDisplayed)
    } else {
      let moveTo = activeSlideIndex - genreTileDisplayed;
      if (moveTo < 0) moveTo = 0;
      setActiveSlideIndex(moveTo);
    }
  }

  const nextGenreSlide = () => {
    let moveTo = activeSlideIndex + genreTileDisplayed;
    if (moveTo >= genres.filter(genreFilterCB).length) {
      setActiveSlideIndex(0);
    } else {
      if (moveTo > (genres.filter(genreFilterCB).length - genreTileDisplayed)) {
        moveTo = genres.filter(genreFilterCB).length - genreTileDisplayed;
      }
      setActiveSlideIndex(moveTo);
    }
  }

  const genreFilterCB = (genre) => {
    if (genreSearchStr !== '') { return genre.name.toLowerCase().includes(genreSearchStr.toLowerCase()); } else { return true; }
  }

  useEffect(() => {
    setTimeout(() => {
      let ind = genres.filter(genreFilterCB).sort((a, b) => b.trackCount - a.trackCount).findIndex(g => g.name === genre);
      if (ind !== -1) {
        //ind++;
        ind = ind - (genreTileDisplayed / 2);
        if (ind < 0) ind = 0;
        if (ind > (genres.filter(genreFilterCB).length - genreTileDisplayed)) {
          setActiveSlideIndex(Math.ceil(genres.filter(genreFilterCB).length - genreTileDisplayed));
        } else {
          setActiveSlideIndex(Math.ceil(ind));
        }
      }
    }, 250);
  }, [genre, genres, genreTileDisplayed]);

  // filter tracks that have downloadPrice if downloadFilter is true
  const filteredTracks = trackList?.rows?.filter(track => {
    if (downloadFilter) {
      return track.downloadPrice;
    } else {
      return true;
    }
  });

  return (
    <>
      <div className='mt-10 flex-1 pb-20'>
        <div className='h-12'></div>
        <div className='flex flex-col sm:grid sm:grid-cols-2 mb-10 sm:m-6 px-6 sm:px-16 gap-2' style={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto' }}>
          <div className="flex items-center flex-col justify-center" >
            <h2 className="text-white text-3xl sm:text-4xl mt-8" style={{ fontFamily: 'Raleway' }}>A Decentralized
              <span className="block font-bold" style={{ textShadow: '0 0 30px rgb(60, 74, 187)' }}> Audio Streaming Platform</span>
            </h2>
            <span className='flex mt-8 text-left w-full sm:ml-16'>
              <span className='text-xl mx-2'>
                Underground Vibes Only<br />
                <span className='text-tama-green'>No</span> Gatekeeping, <span className='text-tama-green'>No</span> Advertising
              </span>              
            </span>
            {
              /*
              <div className='w-full mt-8 sm:ml-20 mb-8 sm:mb-0 ml-3 sm:ml-0'>              
                <a href="https://docs.google.com/forms/d/181YZ5rpA_cYI7mv7PIz1-qQw6Yca525lSlaaPw2jeFY/edit" 
                  target="_blank" 
                  className='rounded p-2 w-44 whitespace-nowrap flex items-center bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 cursor-pointer'
                  style={{filter: 'drop-shadow(0 0 20px rgb(129, 135, 200))'}}
                  >Convert Audio to NFT</a>                            
              </div>
              */
            }
          </div>
          <div className='flex flex-col justify-center'>
            <div className='w-full text-center text-xl mb-2'>The "Art of Cuisine" Industry Dinner - <a href="https://www.eventbrite.ca/e/the-art-of-cuisine-industry-dinner-art-basel-edition-tickets-462048819457" target="_blank" rel='noreferrer' className='text-tama-green'>Get Tickets</a></div>
            <div className='flex glow'>
              {isIOS && <a href='https://www.eventbrite.ca/e/the-art-of-cuisine-industry-dinner-art-basel-edition-tickets-462048819457' target='_blank' rel='noreferrer' className='flex w-full justify-center'><img className='sm:h-80 w-auto' style={{ marginLeft: 'auto', marginRight: 'auto' }} alt='preview' src={ArtOfCuisineImg} /></a>}
              {!isIOS &&
                <Swiper
                  modules={[Autoplay]}
                  loop={true}
                  speed={1500}
                  slidesPerView={1}
                >
                  <SwiperSlide><a href='https://www.eventbrite.ca/e/the-art-of-cuisine-industry-dinner-art-basel-edition-tickets-462048819457' target='_blank' rel="noreferrer"><img className='sm:h-80 w-auto' alt='preview' style={{ marginLeft: 'auto', marginRight: 'auto' }} src={ArtOfCuisineImg} /></a></SwiperSlide>
                  {
                    /*
                    swiperItems.map((swiperItem, ind) =>
                      <SwiperSlide key={'item' + ind}>
                        <a href={swiperItem.url} target='_blank' rel="noreferrer" className='h-full w-full bg-cover bg-center flex justify-center items-center relative ' style={{ backgroundImage: 'url(' + swiperItem.background + ')' }}>
                          <div className='' dangerouslySetInnerHTML={{ __html: swiperItem.html }}></div>
                        </a>
                      </SwiperSlide>)
                      */
                  }
                </Swiper>
              }
            </div>
          </div>
        </div>
        <div className='flex justify-center mx-4'>
          <div className='inline-flex flex-col mt-3 relative w-full sm:w-auto'>
            <img src={leftArrow} alt="left-arrow" className={`absolute cursor-pointer ${styles.leftArrow} ${genres.filter(genreFilterCB).length + 1 > genreTileDisplayed ? '' : 'hidden'}`} onClick={prevGenreSlide} />
            <img src={rightArrow} alt="right-arrow" className={`absolute cursor-pointer ${styles.rightArrow} ${genres.filter(genreFilterCB).length + 1 > genreTileDisplayed ? '' : 'hidden'}`} onClick={nextGenreSlide} />
            <div className='w-full mb-4 text-2xl flex items-center' style={{ textShadow: 'rgb(60, 74, 187) 0px 0px 6px', fontSize: '1.7rem' }}>
              Trending Genres
              <span
                className="cursor-pointer flex items-center"
                onMouseEnter={() => { setShowGenreSearch(true); }}
                onMouseLeave={() => { if (document.activeElement !== genreSearchRef.current) { setShowGenreSearch(false); } }}
                onFocus={() => { setShowGenreSearch(true); }}
                onBlur={() => { setShowGenreSearch(false); }}
              >
                <img src={rightArrow} alt="right-arrow" className={`cursor-pointer mx-1 ${styles.rightArrow}`} style={{ height: '14px', marginTop: '3px', filter: 'invert(22%) sepia(23%) saturate(1004%) hue-rotate(64deg) brightness(96%) contrast(85%)' }} />
                <input ref={genreSearchRef} type="text" className='rounded text-base px-2 py-1 text-left'
                  placeholder="Search by genres"
                  onKeyUp={(e) => { setActiveSlideIndex(0); if (e.keyCode === 27) { setShowGenreSearch(false); } else { setGenreSearchStr(e.target.value); } }}
                  style={{ transition: 'all 0.3s ease-in-out', backgroundColor: '#00000033', maxWidth: showGenreSearch || genreSearchStr !== '' ? '150px' : 0, padding: showGenreSearch || genreSearchStr !== '' ? '0.25rem 0.5rem' : '0' }} />
              </span>
            </div>
            <div className={styles.genresSlider} ref={genreSliderRef} >
              <Link to={selectedTrackListType.value === 'recent' ? '/' : '/' + selectedTrackListType.value} style={{ left: 0, background: '#232332', zIndex: 22 }} className={`flex-none hidden sm:flex flex-col items-center sm:text-xl rounded px-1 my-6 absolute ${styles.genre} ${!genre ? styles.selectedGenre : ''}`}>
                <img className='h-16 w-full sm:h-28' src={getGenreIcon(24)} alt="genre" />
                <div className='flex w-full h-14 text-center justify-center pb-2 leading-tight'>All</div>
              </Link>
              <div className='flex flex-row justify-start' style={{ transition: 'all 0.3s ease-in-out', marginLeft: (activeSlideIndex * (genreTileWidth * -1)) + 'px' }}>
                <Link to={selectedTrackListType.value === 'recent' ? '/' : '/' + selectedTrackListType.value} style={{ left: 0, background: '#232332' }} className={`flex-none flex sm:hidden flex-col items-center sm:text-xl rounded px-1 my-6 absolute ${styles.genre} ${!genre ? styles.selectedGenre : ''}`}>
                  <img className='h-16 w-full sm:h-28' src={getGenreIcon(24)} alt="genre" />
                  <div className='flex w-full h-14 text-center justify-center pb-2 leading-tight'>All</div>
                </Link>
                {
                  genres.filter(genreFilterCB).sort((a, b) => b.trackCount - a.trackCount).map((g, ind) =>
                    <Link key={'genreFilter' + ind} to={selectedTrackListType.value === 'recent'
                      ? (g.name === '' ? '/' : '/genre/' + g.name)
                      : '/' + selectedTrackListType.value + (g.name === '' ? '' : '/' + g.name)
                    } className={`flex-none flex flex-col items-center sm:text-xl px-1 rounded my-6 ${styles.genre} ${genre === g.name || (!genre && g.name === '') ? styles.selectedGenre : ''}`}>
                      <img className='h-16 w-full sm:h-28' src={getGenreIcon(ind)} alt="genre" />
                      <div className='block w-full h-14 text-center justify-center pb-2 leading-tight overflow-ellipsis overflow-hidden hover:overflow-auto' style={{}}>
                        <div style={{backgroundColor: '#232332'}}></div>
                        {g.name}
                      </div>
                    </Link>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <Select
          isSearchable={false}
          options={listType}
          maxMenuHeight={260}
          styles={{
            indicatorSeparator: (provided) => ({
              display: 'hidden'
            }),
            control: (provided) => ({
              ...provided,
              border: 'none !important',
              boxShadow: 'none',
              background: '#1c1c28',
              borderRadius: '0.25rem'
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: '4px 18px'
            }),
            singleValue: (provided) => ({
              ...provided,
              color: '#ffffff'
            }),
            menu: (provided) => ({
              ...provided,
              marginTop: '2px',
              marginRight: '2px',
              zIndex: '10',
              background: '#1c1c28',
            }),
            option: (provided, state) => ({
              ...provided,
              padding: '4px 8px',
              background: '#1c1c28',
              borderBottom: '1px solid #24253b',
            })
          }}
          value={selectedTrackListType}
          onChange={(e) => {
            setIsFetched(false);
            setSelectedTrackListType(e);
            switch (e.value) {
              case 'recent':
                if (genre) {
                  history.push('/genre/' + genre);
                } else {
                  history.push('/');
                }
                dispatch(getRecentTracks(null, daysSelected)).then(() => setIsFetched(true));
                break;
              case 'recommended':
                if (genre) history.push('/recommended/' + genre); else history.push('/recommended');
                dispatch(getRecommendedTracks(null, daysSelected)).then(() => setIsFetched(true));
                break;
              case 'popular':
                if (genre) history.push('/popular/' + genre); else history.push('/popular');
                dispatch(getPopularTracks(null, daysSelected)).then(() => setIsFetched(true));
                break;
              case 'downloadable':
                if (genre) history.push('/downloadable/' + genre); else history.push('/downloadable');
                dispatch(getCryptoTracks(null, daysSelected)).then(() => setIsFetched(true));
                break;
              case 'nfts':
                if (genre) history.push('/nfts/' + genre); else history.push('/nfts');
                dispatch(getNftTracks(null, daysSelected)).then(() => setIsFetched(true));
                break;
              case 'forsale':
                if (genre) history.push('/forsale/' + genre); else history.push('/forsale');
                dispatch(getForsaleTracks(null, daysSelected)).then(() => setIsFetched(true));
                break;
              default:
                break;
            }
          }}
          className="p-2 mx-4 mt-8 sm:mx-10 text-2xl md:text-4xl bg-tama-brown-trans rounded"
        />
        <div className='mx-2 pl-1 pr-2 sm:px-5 sm:mx-10'>
          <div className='flex flex-row w-full items-center justify-between'>
            {
              selectedTrackListType && selectedTrackListType.value === 'popular' && <div className='w-full mt-4 sm:mt-8 text-lg sm:text-4xl sm:ml-2'>
                <span className="text-tama-green">Tamago</span> Top 100 {genreStr}
              </div>
            }

            {
              selectedTrackListType && selectedTrackListType.value !== 'recent' &&
              <div className='flex w-full justify-end'>
                <div className='flex flex-row items-center py-1 mt-5 sm:mt-6'>
                  <span
                    className={`ml-2 inline-block rounded text-md sm:text-2xl py-1 px-3 cursor-pointer tracking-wide hover:bg-mandarin-black ${daysSelected === null && 'bg-mandarin-black'} ${daysSelected === null ? 'text-mandarin' : 'text-gray-400'}`}
                    style={daysSelected === null ? { textShadow: 'rgba(254, 254, 254, 0.2) 0px 0px 8px', color: 'rgb(156, 91, 224)' } : {}}
                    onClick={() => handleChangeDays(null)}
                  >
                    All Time
                  </span>
                  <span
                    className={`ml-2 inline-block rounded text-md sm:text-2xl py-1 px-3 cursor-pointer tracking-wide hover:bg-mandarin-black ${daysSelected === 30 && 'bg-mandarin-black'} ${daysSelected === 30 ? 'text-mandarin' : 'text-gray-400'}`}
                    style={daysSelected === 30 ? { textShadow: 'rgba(254, 254, 254, 0.2) 0px 0px 8px', color: 'rgb(156, 91, 224)' } : {}}
                    onClick={() => handleChangeDays(30)}
                  >
                    Monthly
                  </span>
                </div>
              </div>
            }
          </div>
          {isFetched && trackList && trackList.rows && trackList.rows.length > 0 &&
            <InfiniteScroll
              dataLength={trackList && trackList.rows ? trackList.rows.length : 0}
              next={fetchMoreTracks}
              hasMore={hasMoreTrack}
              loader={<div className='flex flex-col items-center justify-center p-8'><Audio color="#6fcc5e99" height={40} width={40} ariaLabel="three-circles-rotating" />loading</div>}
            >
              {trackList && filteredTracks.map(((track, ind) =>
                <div key={'track' + ind} className='flex flex-row'>
                  {
                    selectedTrackListType && selectedTrackListType.value === 'popular' &&
                    <div className={`flex px-2 sm:px-0 text-3xl justify-center text-white font-extrabold items-center pb-12 sm:justify-start sm:pb-0 sm:ml-2 sm:text-6xl sm:mt-12 sm:items-start ${ind >= 9 ? styles.chartPosition10 : styles.chartPosition}`}>{ind + 1}</div>
                  }
                  <div className='flex flex-col flex-grow'>
                    <TrackCard key={track.id} track={track} />
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          }
          {
            isFetched && trackList && trackList.rows && trackList.rows.length === 0 &&
            <div className='mt-12 flex-1 flex items-center justify-center text-3xl'>No tracks yet!</div>
          }
          {
            !isFetched &&
            <div className='mt-20 flex-1'>
              <div className='flex items-center justify-center h-full'>
                <BallTriangle color="#6fcc5e33" height={70} width={70} ariaLabel="three-circles-rotating" />
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default LandingPage;
