import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSelector } from 'react-redux';

import TrackCard from '../Common/TrackCard';
import styles from './Player.module.css'

import { ReactComponent as MoveIcon } from '../../img/icons/drag-svgrepo-com.svg'

export function QueueItem({track,index,showIndex}) {

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: track.id});      
    const dragStyle = { transform: CSS.Transform.toString(transform), transition };        
    const currentTrack = useSelector(state => state.player.currentTrack);

    if (attributes['aria-pressed']) {
        dragStyle.opacity = 0.5;
        dragStyle.cursor = 'grabbing';
    }

    if (currentTrack.id === track.id) {
        dragStyle.backgroundColor = '#0003';
    }

    const dragAttributes = {
        ...attributes,
        ...listeners,
        style: dragStyle
    };        

    return <div className='flex flex-row p-3 pr-5 ' ref={setNodeRef} {...dragAttributes} >
        <div className='flex flex-col items-center'>
            { showIndex && <div className={`flex px-0 text-3xl justify-left text-white font-extrabold items-center sm:pb-12 sm:justify-start sm:pb-0 sm:ml-0 sm:text-3xl sm:items-start ${index >= 9 ? styles.chartPosition10 : styles.chartPosition}`}>{index + 1}</div> }
            <div className='flex-grow'></div>
            <MoveIcon className='w-5 h-5 mb-2 opacity-50 hover:opacity-100 hover:text-tama-green' style={{cursor: attributes['aria-pressed'] ? 'grabbing' : 'grab'}} />
        </div>
        <div className='flex flex-col flex-grow -mt-6'>            
            <TrackCard track={track} index={index} waveformHeight={40} showReactions={false} trackImageSize='w-16 sm:w-32 h-16 sm:h-32' />
        </div>
    </div>;
}

export default QueueItem;