import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, useHistory } from "react-router-dom";
import { assignSkaleWallet, logout } from '../../store/session';
import { ReactComponent as NearIcon } from '../../img/icons/icon_nm.svg'
import { ReactComponent as NearType } from '../../img/icons/near_type.svg'
import styles from './Navigation.module.css';
import { utils } from "near-api-js";

export default function ProfileButton({ user, isFixedHeader }) {
    const dispatch = useDispatch();
    const history = useHistory();    
    const sessionUser = useSelector(state => state.session.user);
    const nearWallet = useSelector(state => state.near.wallet);
    const marketContract = useSelector(state => state.near.marketContract);    
    
    const [ showMenu, setShowMenu ] = useState(false);
    const [listingFee, setListingFee] = useState(0.01);
    const [deposited, setDeposited] = useState(0);        

    const openMenu = () => {
        if(showMenu) return;
        setShowMenu(true);
    }

    useEffect(() => {
        // listen to account switching in metamask
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', (accounts) => {                                
                dispatch(assignSkaleWallet({ walletId: accounts[0] }));                
            });
        }
    }, []);

    useEffect(() => {
        if(!showMenu) return;

        const closeMenu = () => {
            setShowMenu(false);
        };

        document.addEventListener('click', closeMenu);

        return () => document.removeEventListener('click', closeMenu);
    }, [showMenu]);

    useEffect(() => {
        if (marketContract) {
            marketContract.storage_minimum_balance().then(f => setListingFee(f));
            if (sessionUser?.nearAccount) marketContract.storage_balance_of({ account_id: sessionUser.nearAccount }).then(f => setDeposited(f));
        }
    }, [marketContract, sessionUser]);

    const logoutUser = (e) => {
        e.preventDefault();
        nearWallet.signOut();
        dispatch(logout());
        history.push(`/login`);        
    }

    return (
        <>                        
            <div onClick={openMenu} className={`flex-none h-full px-2 w-auto flex justify-center items-center cursor-pointer relative text-white ${styles.usermenu}`}>
                <img src={sessionUser.avatarUrl} className="w-7 rounded-full" />
                <span className='sm:ml-2 sm:mr-14 relative w-0 sm:w-auto'>
                    <span className="hidden sm:flex">
                        { /* sessionUser.username */}                        
                        { nearWallet?.getAccountId() && <span className={`ml-1 mt-2.5 inline w-2 h-2 rounded-full ${nearWallet?.getAccountId() ? "bg-tama-green" : "bg-white opacity-30"}`} style={{boxShadow: 'rgba(144, 241, 126, 0.8) 0px 0px 5px', marginTop: '0.55rem', width: '0.4rem', height: '0.4rem'}}></span> }
                    </span>
                    {showMenu && (
                        <ul className={`${isFixedHeader? styles.isfixed :''} profile-dropdown bg-tama-brown text-silver absolute text-xl glow right-full sm:-right-10 sm:left-auto`} style={{maxWidth: '267px'}}>
                            {
                                /*                            
                                <li className="hover:text-tama-green cursor-pointer">
                                    <Link to={`/user/${sessionUser.username.replace('?','%3F')}/wallet`+(nearWallet?.getAccountId()?'/balance':'')} className='flex px-4 pt-4'>
                                        <NearIcon width={19} className="mr-3" style={{minWidth: '19px'}} />
                                        {
                                            nearWallet?.getAccountId() && 
                                            <div className="overflow-hidden whitespace-nowrap flex flex-col items-right" style={{textOverflow: 'ellipsis'}}>
                                                <span>{ nearWallet.getAccountId() }</span>
                                                <span className="text-sm flex items-center"><span>Balance { utils.format.formatNearAmount(deposited) }</span> <NearType className='ml-1 h-2' /></span>
                                            </div>
                                        }
                                        {
                                            !nearWallet?.getAccountId() && <div>Connect</div>
                                        }
                                    </Link>
                                </li>
                                */
                                }
                            <li className="hover:text-tama-green cursor-pointer"><Link className="flex p-4 items-center" to={`/user/${sessionUser.username.replace('?','%3F')}`}><i className="fas fa-user-circle mr-3"></i>My Profile</Link></li>
                            <li onClick={logoutUser} className="p-4 hover:text-tama-green cursor-pointer flex items-center"><i className="fas fa-sign-out-alt mr-3"></i>Log Out</li>
                        </ul>
                    )}
                </span>
            </div>                                  
        </>
    );
}
