import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getTrack } from '../../store/track';
import { playTrack } from '../../store/player';
import TrackMainInfo from './TrackMainInfo';
import TrackComments from './TrackComments';
import UserCard from './UserCard';
import CommentNew from './CommentNew';
import CommentEdit from './CommentEdit';
import { ThreeCircles, Audio, BallTriangle, MutatingDots } from 'react-loader-spinner'
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useDocumentTitle, useMetaTags } from '../Common/useMeta';

const TrackPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const storeTrack = useSelector(state => state.track.track);        
    const sessionUser = useSelector(state => state.session.user);
    const currentTrack = useSelector(state => state.player.currentTrack);
    const [editActive, setEditActive] = useState(false);
    const [updatedComment, setUpdatedComment] = useState('');
    const [updatedCommentId, setUpdatedCommentId] = useState('');
    const [isFetched, setIsFetched] = useState(false);
    const { trackPageView } = useMatomo();
    const [documentTitle, setDocumentTitle] = useDocumentTitle(null);
    const [metaTags, setMetaTags] = useMetaTags({});    

    useSelector(state => state.track.track && state.track.track.Reactions);

    // Track page view
    useEffect(() => {
        trackPageView()
    }, []);

    //HIT BACKEND ROUTE TO LOAD TRACK INTO STORE
    useEffect(() => {
        dispatch(getTrack(id)).then(() => setIsFetched(true));
    }, [id, dispatch]);

    useEffect(() => {
        if (currentTrack == null && storeTrack) {
            dispatch(playTrack(storeTrack.id));
        }
        if (storeTrack) {
            setDocumentTitle(storeTrack.title + ' by ' + storeTrack.User.username);
            setMetaTags({ ...metaTags, title: storeTrack.title + ' by ' + storeTrack.User.username, description: storeTrack.description, image: storeTrack.imageUrl });
        }
    }, [storeTrack]);

    // console.log(metaTags);

    /*
    useEffect(() => {
        if (currentTrack && targetUser && targetUser.Tracks) {      
            const sortedTracks = targetUser.Tracks.sort((track1, track2) => { return track1.createdAt > track2.createdAt ? -1 : 1 });
            const currentInd = sortedTracks.findIndex(ele => ele.id == currentTrack.id);          

            if (sortedTracks.length == currentInd+1) {
                console.log('set next track', sortedTracks[0].title);
                dispatch(setNextTrack(sortedTracks[0]));
            } else if (currentInd != -1)  {
                console.log('set next track', sortedTracks[currentInd+1].title);
                dispatch(setNextTrack(sortedTracks[currentInd+1]));
            }
        }
    }, [currentTrack]);
    */    

    return (
        <>
            {isFetched && storeTrack && (
                <div className='mt-20 flex-1 p-4 sm:p-16'>
                    <TrackMainInfo track={storeTrack} />
                    <div className='flex sm:flex-row'>
                        <div className='flex-grow sm:pr-8 w-full'>
                            <p className='text-lg mt-2 whitespace-pre-wrap text-center sm:text-left'>{storeTrack.description}</p>
                            <div className='mb-8'></div>
                            {
                                editActive 
                                ? <CommentEdit setEditActive={setEditActive} updatedComment={updatedComment} setUpdatedComment={setUpdatedComment} updatedCommentId={updatedCommentId} setUpdatedCommentId={setUpdatedCommentId} track={storeTrack} sessionUser={sessionUser} /> 
                                : <CommentNew track={storeTrack} sessionUser={sessionUser} />
                            }
                            <TrackComments setUpdatedComment={setUpdatedComment} setUpdatedCommentId={setUpdatedCommentId} setEditActive={setEditActive} track={storeTrack} sessionUser={sessionUser} />
                        </div>
                        <div className='flex-none w-80 hidden sm:block'>
                            <UserCard track={storeTrack} />
                        </div>
                    </div>
                </div>
            )}
            {
                !isFetched &&
                <div className='mt-40 flex-1'>
                    <div className='flex items-center justify-center h-full'>
                        <BallTriangle color="#6fcc5e33" height={70} width={70} ariaLabel="three-circles-rotating" />
                    </div>
                </div>
            }
        </>
    );
}

export default TrackPage;
