// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tooltip_extraClass__Epdnc {\n  font-size: 20px !important;\n  letter-spacing: 1px;\n}", "",{"version":3,"sources":["webpack://src/components/Common/Tooltip.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,mBAAmB;AACrB","sourcesContent":[".extraClass {\n  font-size: 20px !important;\n  letter-spacing: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extraClass": "Tooltip_extraClass__Epdnc"
};
export default ___CSS_LOADER_EXPORT___;
