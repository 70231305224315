import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import ProfileButton from './ProfileButton';
import Logo from '../../img/logo.png';
import LogoCrescent from '../../img/logo-crescent.png';
import ShovelIcon from '../../img/icons/shovel.png';
import RecordStoreIcon from '../../img/icons/record-store.png';
import UploadIcon from '../../img/icons/upload.png';
import { ReactComponent as SignInIcon } from '../../img/icons/sign-in-svgrepo-com.svg'
import { ReactComponent as SearchIcon } from '../../img/icons/search-svgrepo-com.svg'
import { ReactComponent as PickAxeIcon } from '../../img/icons/pickaxe-svgrepo-com.svg'
import { ReactComponent as VinylIcon } from '../../img/icons/vinyls-svgrepo-com.svg'
import styles from './Navigation.module.css';

export default function Navigation({ isLoaded }) {

    const searchRef = useRef();
    const sessionUser = useSelector(state => state.session.user);
    const uploadProgressPct = useSelector(state => state.track.uploadProgress);
    const uploadInfo = useSelector(state => state.track.uploadInfo);
    const uploadErrors = useSelector(state => state.track.uploadErrors);    
    const history = useHistory();
    const location = useLocation();
    const [isSearching, setSearching] = useState(false);
    const [isFixedHeader, setFixedHeader] = useState(window.pageYOffset > 100);
    const [searchTO, setSearchTO] = useState();
    const [searchStr, setSearchStr] = useState('');

    let sessionLinks;
    if (sessionUser) {
        sessionLinks = (
            <ProfileButton user={sessionUser} isFixedHeader={isFixedHeader} />
        );
    } else {
        sessionLinks = (
            <NavLink exact to='/login' className={`flex-none w-12 sm:w-24 h-full mr-4 sm:mr-12 flex items-center justify-center`}>
                <SignInIcon style={{ height: "28px", width: "28px", fill: "#6fcc5e" }} />
                <span className='ml-2 hidden sm:inline' style={{ fontSize: "16px", letterSpacing: "1px", maxWidth: "120px", color: "#6fcc5e" }}>Sign In</span>
            </NavLink>
        );
    }

    const clearSearch = (e) => {
        setSearchStr('');
        history.goBack();
    }

    useEffect(() => {
        clearTimeout(searchTO);
        if (searchStr) {
            setSearching(true);
            setSearchTO(setTimeout(() => {
                if (history.location.pathname !== '/search')
                    history.push(`/search?${searchStr}`);
                else
                    history.replace(`/search?${searchStr}`);
            }, 1000));
        }
    }, [searchStr]);

    useEffect(() => {
        const onScroll = () => {
            setFixedHeader(window.pageYOffset > 100);
        }
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const isUploading = uploadProgressPct && (uploadProgressPct.loaded / uploadProgressPct.total);
    const uploadComplete = uploadProgressPct && uploadProgressPct.loaded == uploadProgressPct.total;    

    return (
        <>
            <nav className={`w-full grid grid-cols-3 h-13 z-10 items-center ${isFixedHeader ? 'fixed shadow tama-gradient fixed-header' : 'absolute'}`}>
                <div className='pl-4 sm:pl-0 sm:ml-12 flex h-full'>
                    <div className={`flex-none h-full flex justify-start ${styles.navlink} ${styles.search} ${isSearching ? styles['search-focused'] : ''}`}>
                        <form className="w-full flex items-center relative" autoComplete='off' onSubmit={(e) => { e.preventDefault() }}>
                            <SearchIcon className="search-icon" fill="#ffffff" style={{ height: "24px", width: "24px", strokeWidth: "4px", stroke: "#000000" }} />
                            <input
                                ref={searchRef}
                                className="w-full h-6/6 px-1 py-2 rounded text-space-cadet text-xl"
                                type='text'
                                placeholder='Search'
                                onChange={(e) => setSearchStr(e.target.value)}
                                onFocus={() => setSearching(true)}
                                onMouseEnter={() => setSearching(true)}
                                onMouseLeave={() => !searchStr && document.activeElement !== searchRef.current && setSearching(false)}
                                onBlur={() => !searchStr && setSearching(false)}
                                value={searchStr}
                            />
                            {
                                searchStr && <i className='fas fa-times fa-lg absolute right-2 text-space-cadet cursor-pointer' onClick={clearSearch}></i>
                            }
                        </form>
                    </div>
                    <NavLink exact to='/' className={`flex-none w-24 h-full flex items-center ${styles.navlink} ${styles['navlinkdig']}`} activeClassName={styles.navlinkactive}>
                        <img src={ShovelIcon} alt='dig' className='mx-2' style={{ height: "32px", marginTop: "-5px" }} />
                        <span className='hidden sm:inline' style={{ letterSpacing: "2px" }}>Dig</span>
                    </NavLink>
                    {
                        sessionUser &&
                        <NavLink exact to={sessionUser ? `/user/${sessionUser.username.replace('?', '%3F')}` : '/login'} className={`flex-1 w-24 h-full items-center hidden sm:flex ${styles.navlink} ${styles.navlinkstore}`} activeClassName={styles.navlinkactive}>
                            <img src={RecordStoreIcon} alt='record store' className='mx-2' style={{ height: "26px" }} />
                            <span className='hidden sm:inline' style={{ letterSpacing: "1px" }}>Record Store</span>
                        </NavLink>
                    }
                </div>
                <NavLink exact to='/' className={`flex-grow h-full flex items-center justify-center ${styles.navlink} ${styles.logo}`}>
                    <img src={isFixedHeader ? LogoCrescent : Logo} alt='logo' className={`hidden sm:block ${isSearching ? 'opacity-0' : ''} my-2`} style={isFixedHeader ? { height: "45px" } : { height: "85px" }} />
                    <img src={LogoCrescent} alt='crescent logo' className={`block sm:hidden ${isSearching ? 'opacity-0' : ''} my-2`} style={isFixedHeader ? { height: "45px" } : { height: "45px" }} />
                </NavLink>

                <div className='flex justify-end h-full'>
                    <NavLink exact to={'/upload'} className={`flex-none w-auto mr-3 sm:mr-6 h-full flex items-center justify-center ${styles.navlink}`} activeClassName={styles.navlinkactive}>
                        <img src={UploadIcon} alt='upload' style={{ height: "22px" }} />                        
                        <span className='hidden sm:inline ml-2' style={isUploading||uploadInfo?{ letterSpacing: "1px", maxWidth: 120 }:{ letterSpacing: "1px" }}>
                            { !isUploading && !uploadInfo && <>Upload</> } 
                            { uploadInfo && !isUploading && uploadErrors.length == 0 && <>Finished</> }
                            { uploadInfo && uploadErrors.length != 0 && <span style={{color:'#e05959'}}>Error</span> }
                            { isUploading && !uploadComplete && <>Uploading</> }                            
                            { isUploading && <div className="overflow-hidden w-18 h-2 mb-1 text-xs flex rounded" style={{backgroundColor: '#6fcc5e66'}}>
                                    <div style={{ width: (uploadProgressPct.loaded / uploadProgressPct.total * 100) + "%", backgroundColor: '#6fcc5e' }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"></div>
                                </div> }
                        </span>                            
                    </NavLink>
                    {isLoaded && sessionLinks}
                </div>
            </nav>
        </>
    );
}
