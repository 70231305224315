import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link, useParams } from 'react-router-dom';

import configData from '../../config.json';
import { fetch } from '../../store/csrf';
import { forgotPassword } from '../../store/session';

import { ReactComponent as ShieldX } from '../../img/icons/shield-x-svgrepo-com.svg'
import { ReactComponent as ShieldCheck } from '../../img/icons/shield-check-svgrepo-com.svg'

const VerifyAccount = () => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const sessionUser = useSelector(state => state.session.user);  
    const [ inputErrors, setInputErrors ] = useState([]);
    const [ isEmailSent, setIsEmailSent ] = useState(false);  
    const [ isEmailSending, setIsEmailSending ] = useState(false);    
    const [ isVerifying, setIsVerifying ] = useState(false);  
    const [ isVerified, setIsVerified ] = useState(false);  

    useEffect(() => {
        if (token) {
            setIsVerifying(true);
            fetch(configData.API_URL + `/api/session/verify`, {
                method: 'POST',
                body: JSON.stringify({ token })
            }).then(res => {  
                setIsVerified(res?.data?.verified ? true : false);
                setIsVerifying(false);
            }).catch(res => {
                setInputErrors([['Error verifying']]);
                setIsVerifying(false);
            });
        }
    }, [token])

    if (!sessionUser) return (
        <Redirect to='/login' />
    )

    const sendVerificationEmail = async(e) => {
        if (isEmailSending) return;
        setIsEmailSending(true);
        fetch(configData.API_URL + `/api/session/verify-email`, {
            method: 'POST',
            body: JSON.stringify({})
        }).then(res => {
            setIsEmailSent(true);
            setIsEmailSending(false);
        }).catch(res => {
            setInputErrors([['Error sending verification email']]);
            setIsEmailSending(false);
        });
    }

    return (
        <div className="w-full mx-auto absolute min-h-full pb-48 py-5 flex-1 flex flex-col justify-center items-center bg-background2 bg-center bg-cover mt-12 sm:mt-24">            
            <h1 className="block text-2xl text-white my-2 text-center">Account Verification</h1>
            <div className="px-8 py-6 w-5/6 sm:4/6 mx-4 mb-4 shadow-lg rounded bg-white glow flex flex-col items-center" style={{maxWidth: 700}}>          
                {
                    isVerifying && <>
                        <ShieldX className='w-16 mb-4' />
                        <p className='text-black text-xl text-center dotty'>Verifying your account</p>
                    </>
                }         
                {
                    isVerified && <>
                        <ShieldCheck className='w-16 mb-4' />
                        <p className='text-black text-xl text-center'>Your account has been verified.</p>
                        <Link to={'/'} className="sm:96 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded mt-4 mb-0 cursor-pointer">{ 'Continue' }</Link>                            
                    </>
                }
                {   !isVerified && !isVerifying &&
                    <>
                        <ShieldX className='w-16 mb-4' />
                        <p className='text-black text-xl text-center' style={{lineHeight: 1.5}}>Your account requires verification to perform some actions.<br />Complete one of the actions below to verify your account.</p>
                        { inputErrors.length > 0 && 
                            <ul id="login-errors" className="block my-2 text-center text-red-600 font-bold">
                                {inputErrors.map((error, idx) => <li key={idx}>{error}</li>)}
                            </ul>
                        }  

                        {
                            !sessionUser.nearAccount &&
                            <>
                                <div className='mt-2 flex justify-around flex-col sm:flex-row'>
                                    <Link to={'/user/'+sessionUser.username+'/wallet'} className="sm:96 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded mt-4 mb-0 cursor-pointer">{ 'Connect NEAR Wallet' }</Link>                            
                                </div>
                            </>
                        }
                        {                            
                            <>
                                <div className='mt-2 flex justify-around flex-col sm:flex-row'>
                                    { !isEmailSent && <span onClick={sendVerificationEmail} className={"sm:96 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded mt-4 mb-0 cursor-pointer "+(isEmailSending?'dotty':'')}>{ isEmailSending ? 'Sending Email' : 'Send Verification Email' }</span> }                                                                        
                                    { isEmailSent && <span className='text-black text-center text-xl mt-6'>Verification email sent</span>}
                                </div>
                            </>
                        }                        
                    </>
                }
                
            </div> 
            <div className="p-1">                 
                <Link to='/' className="text-mandarin font-large hover:font-bold">Back</Link>
            </div>            
        </div>
    );
}

export default VerifyAccount;
