import { useEffect, useState, useRef } from "react";

const useDocumentTitle = (title, prefix, postfix) => {

    const documentDefined = typeof document !== 'undefined';
    const originalTitle = useRef(documentDefined ? document.title : null);
    const [documentTitle, setDocumentTitle] = useState(title);

    useEffect(() => {

        if (documentTitle) {
            document.title = (prefix || '') + documentTitle + (postfix || ' | TAMAGO'); 
        } else {
            document.title = 'TAMAGO';
        }
        
        return () => {
            document.title = originalTitle.current;
        }
        
    }, [documentTitle]);

    return [documentTitle, setDocumentTitle];

};

useDocumentTitle.defaultProps = {
    postfix: ' | TAMAGO',
    prefix: ''
}

const useMetaTags = ({title, description, image, url}, prefix, postfix) => {
    const documentDefined = typeof document !== 'undefined';

    const originalMetaTags = useRef({
        ogTitle: documentDefined ? document.querySelector('[property="og:title"]')?.getAttribute('content') : null,
        ogUrl: documentDefined ? document.querySelector('[property="og:url"]')?.getAttribute('content') : null,
        ogType: documentDefined ? document.querySelector('[property="og:type"]')?.getAttribute('content') : null,
        ogDescription: documentDefined ? document.querySelector('[property="og:description"]')?.getAttribute('content') : null,
        ogImage: documentDefined ? document.querySelector('[property="og:image"]')?.getAttribute('content') : null,
        twitterTitle: documentDefined ? document.querySelector('[name="twitter:title"]')?.getAttribute('content') : null,
        twitterDescription: documentDefined ? document.querySelector('[name="twitter:description"]')?.getAttribute('content') : null,
        twitterImage: documentDefined ? document.querySelector('[name="twitter:image"]')?.getAttribute('content') : null,
        twitterCard: documentDefined ? document.querySelector('[name="twitter:card"]')?.getAttribute('content') : null,
    }); 

    const [ metaTags, setMetaTags ] = useState({
        title,
        url,
        description,
        image,
        ogTitle: title,
        ogUrl: url,
        ogType: 'article',
        ogDescription: description,
        ogImage: image,
        twitterTitle: title,
        twitterDescription: description,
        twitterImage: image,
        twitterCard: 'summary_large_image',
    });
    useEffect(() => {
        document.querySelector('[property="og:title"]')?.setAttribute('content', metaTags.ogTitle ? (prefix || '') + metaTags.ogTitle + (postfix||' | TAMAGO') : (metaTags.title ? (prefix || '') + metaTags.title+(postfix||' | TAMAGO') : 'TAMAGO'));
        document.querySelector('[property="og:url"]')?.setAttribute('content', metaTags.ogUrl ? metaTags.ogUrl : (metaTags.url ? metaTags.url : window.location.href));
        document.querySelector('[property="og:type"]')?.setAttribute('content', metaTags.ogType ? metaTags.ogType : 'article');
        document.querySelector('[property="og:description"]')?.setAttribute('content', metaTags.ogDescription ? metaTags.ogDescription : (metaTags.description ? metaTags.description : 'A Decentralized Audio Streaming Platform'));
        document.querySelector('[property="og:image"]')?.setAttribute('content', metaTags.ogImage ? metaTags.ogImage : (metaTags.image ? metaTags.image : document.location.protocol + '//' + document.location.host + '/img/tamago-630.png')); // need default
        document.querySelector('[name="twitter:title"]')?.setAttribute('content', metaTags.twitterTitle ? (prefix || '') + metaTags.twitterTitle + (postfix||' | TAMAGO') : (metaTags.title ? (prefix || '') + metaTags.title+(postfix||' | TAMAGO') : 'TAMAGO'));
        document.querySelector('[name="twitter:description"]')?.setAttribute('content', metaTags.twitterDescription ? metaTags.twitterDescription : (metaTags.description ? metaTags.description : 'A Decentralized Audio Streaming Platform'));        
        document.querySelector('[name="twitter:image"]')?.setAttribute('content', metaTags.twitterImage ? metaTags.twitterImage : (metaTags.image ? metaTags.image : document.location.protocol + '//' + document.location.host + '/img/tamago-628.png')); // need default
        document.querySelector('[name="twitter:card"]')?.setAttribute('content', metaTags.twitterCard ? metaTags.twitterCard : 'summary_large_image');

        return () => {
            document.querySelector('[property="og:title"]')?.setAttribute('content', originalMetaTags.current.ogTitle);
            document.querySelector('[property="og:url"]')?.setAttribute('content', originalMetaTags.current.ogUrl);
            document.querySelector('[property="og:type"]')?.setAttribute('content', originalMetaTags.current.ogType);
            document.querySelector('[property="og:description"]')?.setAttribute('content', originalMetaTags.current.ogDescription);
            document.querySelector('[property="og:image"]')?.setAttribute('content', originalMetaTags.current.ogImage);
            document.querySelector('[name="twitter:title"]')?.setAttribute('content', originalMetaTags.current.twitterTitle);
            document.querySelector('[name="twitter:description"]')?.setAttribute('content', originalMetaTags.current.twitterDescription);
            document.querySelector('[name="twitter:image"]')?.setAttribute('content', originalMetaTags.current.twitterImage);
            document.querySelector('[name="twitter:card"]')?.setAttribute('content', originalMetaTags.current.twitterCard);
        }
    }, [JSON.stringify(metaTags)]);

    return [metaTags, setMetaTags];
}

useMetaTags.defaultProps = {
    postfix: ' | TAMAGO',
    prefix: ''
}

export {useDocumentTitle, useMetaTags};