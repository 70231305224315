import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import { playTrack, playAudioTrack, stopTrack } from '../../store/player';
import { deleteTrack } from '../../store/track';
import WaveForm from "../Common/WaveForm";

export function TrackWaveForm({ track, size }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const sessionUser = useSelector(state => state.session.user);
    const currentTrack = useSelector(state => state.player.currentTrack);
    const wavesurferPlayer = useSelector(state => state.player.wavesurfer);
    const isPlaying = useSelector(state => state.player.isPlaying);

    const togglePlay = async (e) => {
        e.preventDefault();

        if (isPlaying && currentTrack.id == track.id) {
            wavesurferPlayer.pause();
            dispatch(stopTrack());
        } else {
            wavesurferPlayer.pause();
            dispatch(playTrack(track.id)).then(() => {
                dispatch(playAudioTrack());
                wavesurferPlayer.play();
            });
        }
    }

    let buttonSize = 'h-14 w-14';
    let playSize = {  };
    let iconSize = 'fa-lg';
    switch(size) {
        case 'small':
            buttonSize = 'h-12 w-12';
            playSize = { minWidth: 36, height: 36 };
            iconSize = '';
            break;
    }

    return (
        <div>
            <div className="flex flex-row items-center">
                <button onClick={togglePlay}
                    className={'hidden sm:flex bg-mandarin hover:bg-mandarin-dark text-white font-bold mr-5 rounded-full justify-center items-center focus:outline-none m-0 '+buttonSize}
                    style={playSize}>
                    <i className={`fas fa-${isPlaying && currentTrack && currentTrack.id == track.id ? 'pause' : 'play'} ${iconSize}`}></i>
                </button>
                <WaveForm track={track} hideTimeMobile={true} height={size ? 40 : null} />
            </div>
        </div>
    );
}

TrackWaveForm.defaultProps = {
    track: null,
    size: 'normal'
}

export default TrackWaveForm;

