import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams, Link } from 'react-router-dom';
import * as nearAPI from 'near-api-js';
import { getTrack, mintTrack, uploadProgress, clearUploadInfo } from '../../store/track';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Toggle from 'react-toggle'
import "react-toggle/style.css"

import { useDocumentTitle } from '../Common/useMeta';
import TrackCard from '../Common/TrackCard';

function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
  
    return (hrs ? (hrs + ' hr ') : '') + (mins ? (mins + ' mins ') : '') + secs + ' secs';
}

const MintTrack = ({ }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { utils } = nearAPI;
    const sessionUser = useSelector(state => state.session.user);
    const nearWallet = useSelector(state => state.near.wallet);
    const genres = useSelector(state => state.ui.genres);
    const tags = useSelector(state => state.ui.tags);
    const track = useSelector(state => state.track.track);
    const uploadProgressPct = useSelector(state => state.track.uploadProgress);
    const uploadInfo = useSelector(state => state.track.uploadInfo);
    const uploadErrors = useSelector(state => state.track.uploadErrors);
    const uploadMSRemaining = useSelector(state => state.track.uploadMSRemaining);
    const nearTipContract = useSelector(state => state.near.tipContract);    

    const [unlockableContentDescription, setUnlockableContentDescription] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [imageBlob, setImageBlob] = useState(null);
    const [imageBlobType, setImageBlobType] = useState('image');
    const [imageFile, setImageFile] = useState(null);
    const [unlockableFile, setUnlockableFile] = useState(null);
    const [inputErrors, setInputErrors] = useState([]);
    const [accessDenied, setAccessDenied] = useState(false);
    const [hasUnlockableContent, setUnlockableContent] = useState(false);
    const [resaleRoyalityOwner, setResaleRoyalityOwner] = useState(48.5);
    const [resaleRoyalityCreator, setResaleRoyalityCreator] = useState(48.5);
    const [streamingRoyalityOwner, setStreamingRoyalityOwner] = useState(48.5);
    const [streamingRoyalityCreator, setStreamingRoyalityCreator] = useState(48.5);

    const { trackPageView } = useMatomo();

    useDocumentTitle('Mint Track');

    // Track page view
    useEffect(() => {
        trackPageView()
    }, []);    

    useEffect(() => {
        if (uploadInfo) {     
            
            //waveformRef?.current?.replaceChildren();
            setUnlockableContentDescription(uploadInfo.unlockableContentDescription);
            setImageUrl(uploadInfo.imageUrl);
            if (uploadInfo.unlockableFile) {
                setUnlockableContent(true);
                setUnlockableFile(uploadInfo.unlockableFile);
            }
        }        
    }, [uploadInfo, genres]);

    useEffect(() => {
        // is finished uploading, clear redux state and redirect to track page
        if (uploadInfo?.success) {
            const trackURL = `/tracks/${uploadInfo.id}`;
            dispatch(clearUploadInfo());
            history.push(trackURL);
            return;
        }
    }, [uploadInfo?.success]);

    useEffect(() => {                
        if (uploadErrors && uploadErrors.length) {
            const errors = inputErrors.concat(uploadErrors);
            setInputErrors(errors);
        }
    }, [uploadErrors])

    useEffect(() => {        
        if (id) dispatch(getTrack(id)).then((t) => {
            if (sessionUser.id !== t.User.id) {
                setAccessDenied(true);
            } else {
                if (t.token) setAccessDenied(true);
                setImageUrl(t.imageUrl);
                fetch(t.imageUrl).then(r => r.blob()).then(b => {
                    setImageBlob(window.URL.createObjectURL(b));
                    setImageBlobType('image');
                });
            }            
        });
    }, [id, dispatch]);

    useEffect(() => {

        let errors = [];
        if (unlockableFile && unlockableFile.size && unlockableFile.size > (1024 * 1024 * 1024)) {
            // 1GB limit
            alert('Your file is larger then 1GB');
            setUnlockableFile(null);
        }

        if (uploadErrors && uploadErrors.length) {            
            errors = errors.concat(uploadErrors);            
        }

        setInputErrors(errors);

    }, [imageUrl, unlockableFile, hasUnlockableContent, uploadErrors ]);

    if (!sessionUser) {
        return (<Redirect to='/login' />);
    }

    if (accessDenied) {
        return (<Redirect to={`/tracks/` + id} />);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        dispatch(uploadProgress({ loaded: 0, total: 100 }));
        // VALID FILE EXTENSIONS
        // WAV, FLAC, AIFF, ALAC, OGG, MP2, MP3, AAC, AMR, WMA

        const mintData = {
            imageUrl: imageUrl || '/img/trackDefault.png',     
            imageFile: imageFile,
            unlockableFile: hasUnlockableContent ? unlockableFile : null,
            unlockableContentDescription: unlockableContentDescription,
            unlockableFilename: hasUnlockableContent ? unlockableFile?.name : null,
            unlockableContentType: hasUnlockableContent ? unlockableFile?.type : null,
            royalites: {
                resale: {
                    owner: resaleRoyalityOwner,
                    creator: resaleRoyalityCreator
                },
                streaming: {
                    owner: streamingRoyalityOwner,
                    creator: streamingRoyalityCreator
                }
            }            
        }

        setUnlockableContentDescription(mintData.unlockableContentDescription);

        const trackDispatch = await dispatch(mintTrack(track.id, mintData));

        if (trackDispatch) {
            setUnlockableContentDescription('');
            setImageUrl('');
            setImageBlob(null);
            setImageBlobType('image');
            setImageFile(null);
            setUnlockableFile(null);
            setInputErrors([]);
            setUnlockableContent(false);
        }
    };

    const handleResaleRoyality = (e) => {     
        let pct = e.target.value.replace(/[^\d.]/g, '');
        if (pct > 97) pct = 97;
        else if (pct < 0) pct = 0;

        const remaining = 97 - pct;

        switch(e.target.name) {
            case 'setResaleRoyalityOwner':
                setResaleRoyalityOwner(pct);
                setResaleRoyalityCreator(remaining);
                break;
            case 'setResaleRoyalityCreator':
                setResaleRoyalityOwner(remaining);
                setResaleRoyalityCreator(pct);
                break;
        }
    }

    const handleStreamingRoyality = (e) => {        
        let pct = e.target.value.replace(/[^\d.]/g, '');
        if (pct > 97) pct = 97;
        else if (pct < 0) pct = 0;

        const remaining = 97 - pct;

        switch(e.target.name) {
            case 'setStreamingRoyalityOwner':
                setStreamingRoyalityOwner(pct);
                setStreamingRoyalityCreator(remaining);
                break;
            case 'setStreamingRoyalityCreator':
                setStreamingRoyalityOwner(remaining);
                setStreamingRoyalityCreator(pct);
                break;
        }
    }

    return ( 
        <div className="w-full mx-auto absolute min-h-full pb-32 sm:pb-48 py-5 flex-1 flex flex-col justify-center items-center bg-background2 bg-center bg-cover mt-12 sm:mt-24">
            <form onSubmit={handleSubmit} className="mx-12 px-12 sm:px-0 sm:mx-0 w-full sm:w-2/3 sm:max-w-screen-lg" style={uploadProgressPct?{width:'auto'}:{}}>
                <div className={`w-full rounded shadow-lg bg-white max-h-full glow`} style={{ transition: 'all .3s ease' }}>
                    <div className={`px-6 sm:px-8 py-4 ${uploadProgressPct ? '' : 'mb-4'}`}>
                        {uploadProgressPct
                            ?
                            <div className="relative pt-1 w-96 text-center text-black">
                                <div className='mb-2 text-xl dotty'>{uploadProgressPct.loaded == uploadProgressPct.total ? 'Minting' : 'Uploading'}</div>
                                    { imageBlobType == 'image' && <img src={imageBlob} className="w-48 mb-2" style={{marginLeft: 'auto', marginRight: 'auto'}} /> }
                                    { imageBlobType == 'video' && <video autoPlay={true} loop={true} src={imageBlob} className="w-48 mb-2" style={{marginLeft: 'auto', marginRight: 'auto'}} /> }
                                
                                <div className="mb-2 font-bold">{ track?.title }</div>
                                <div className="overflow-hidden h-2 mb-1 text-xs flex rounded bg-blue-200">
                                    <div style={{ width: (uploadProgressPct.loaded / uploadProgressPct.total * 100) + "%" }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
                                </div>
                                <span className='dotty'>{uploadProgressPct.loaded == uploadProgressPct.total ? 'Please wait' : msToTime(uploadMSRemaining)+' remaining' }</span>
                                {/*<span className='dotty'>{uploadProgressPct.loaded / uploadProgressPct.total * 100 > 99 ? 'Processing' : 'Uploading'}</span>*/}
                            </div>
                            :
                            <>
                                {
                                    sessionUser?.nearAccount == null &&
                                    <div className='flex flex-col text-black'>
                                        { track && <TrackCard darkMode={false} track={track} showReactions={false} showControls={false} showTrackImage={false} /> }
                                        <div className='text-space-cadet sm:ml-4 my-8 text-xl text-center'><Link className='text-tama-dark-green underline' to={'/user/'+sessionUser.username+'/wallet'}>Connect Wallet</Link> to enable minting</div>
                                    </div>
                                }
                                {
                                    sessionUser?.nearAccount &&
                                    <>
                                        <div className='flex flex-col text-black'>
                                            { track && <TrackCard darkMode={false} track={track} showReactions={false} showControls={false} showTrackImage={false} /> }
                                            <div className='hidden sm:grid grid-cols-3 gap-6 text-space-cadet font-bold mt-8'>
                                                <div className='flex items-center'>NFT Image</div>
                                                <div className='flex items-center'>Royalities</div>                                        
                                                <div className='flex items-center'>
                                                    Unlockable Content
                                                    <div className='flex flex-grow items-center justify-center sm:justify-end h-10 whitespace-nowrap'>
                                                        <Toggle disabled={sessionUser?.nearAccount == null} id='allow-downloads' checked={hasUnlockableContent} onChange={(e) => setUnlockableContent(e.target.checked)} />                                                
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grid sm:grid-cols-3 gap-2 sm:gap-6'>
                                            <div className='flex sm:hidden items-center text-space-cadet font-bold mt-4'>NFT Image</div>
                                                <div className='border rounded p-2 flex flex-col'>
                                                    <div className='relative w-full h-auto flex-grow'>
                                                        { imageBlobType == 'image' && <img src={imageBlob ? imageBlob : '/img/trackDefault.png'} /> }
                                                        { imageBlobType == 'video' && <video autoPlay={true} loop={true} src={imageBlob ? imageBlob : '/img/trackDefault.png'} /> }
                                                    </div>

                                                    <label className="block bg-white text-mandarin hover:bg-mandarin-dark hover:text-white font-bold text-center py-2 px-4 rounded cursor-pointer mt-2 border border-mandarin text-sm">
                                                        Custom NFT Image
                                                        <input
                                                            accept="image/*,video/mp4"
                                                            type='file'
                                                            onChange={(e) => {
                                                                setImageBlobType(e.target.files[0].type == 'video/mp4' ? 'video' : 'image');
                                                                setImageBlob(window.URL.createObjectURL(e.target.files[0]));
                                                                setImageFile(e.target.files[0]);
                                                                /*
                                                                const reader = new FileReader();
                                                                reader.readAsDataURL(e.target.files[0]);
                                                                reader.onloadend = function () {
                                                                    setImageUrl(reader.result);
                                                                }
                                                                */
                                                            }}
                                                            className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4 hidden"
                                                            style={{ background: '#1d50b322' }}
                                                        />
                                                    </label> 


                                                </div>

                                                <div className='flex sm:hidden items-center text-space-cadet font-bold'>Royalities</div>
                                                <div className='border rounded p-2 flex flex-col sm:h-auto'>
                                                    <label className='text-space-cadet font-bold'>Resale Royalities</label>
                                                    <p style={{fontSize: 14}}>These are the royalities for the resale of this NFT.</p>
                                                    <div className='flex w-full mt-2  items-center'>
                                                        <div className=''>NFT Owner</div>
                                                        <div className='text-right flex-grow'>
                                                            <input name='setResaleRoyalityOwner'
                                                                type='text' 
                                                                className='text-center mr-1 mb-2' 
                                                                style={{width: 40, outline: '#6fcc5e solid 2px'}} 
                                                                value={resaleRoyalityOwner} 
                                                                onChange={handleResaleRoyality} 
                                                            />
                                                            %
                                                        </div>
                                                    </div>
                                                    <div className='flex w-full mt-1 items-center'>   
                                                        <div>{sessionUser?.nearAccount}</div>
                                                        <div className='text-right flex-grow'>
                                                            <input name='setResaleRoyalityCreator'
                                                                type='text' 
                                                                className='text-center mr-1 mb-2' 
                                                                style={{width: 40, outline: '#6fcc5e solid 2px'}} 
                                                                value={resaleRoyalityCreator} 
                                                                onChange={handleResaleRoyality} 
                                                            />
                                                            %
                                                        </div>
                                                    </div>
                                                    <div className='flex w-full items-center mt-1'>
                                                        <div>Tamago</div>
                                                        <div className='text-right flex-grow'><input className='text-center mr-1' type='text' value={3} style={{width: 40}} readOnly={true} disabled={true} />%</div>
                                                    </div>
                                                    
                                                    <label className='text-space-cadet font-bold mt-6'>Streaming Royalities</label>
                                                    <p style={{fontSize: 14}}>These are the royalities for other transactions on the Tamago platform (tips, downloads, etc).</p>
                                                    <div className='flex w-full mt-2 items-center'>
                                                        <div className=''>NFT Owner</div>
                                                        <div className='text-right flex-grow'>
                                                            <input name='setStreamingRoyalityOwner'
                                                                type='text' 
                                                                className='text-center mr-1 mb-2' 
                                                                style={{width: 40, outline: '#6fcc5e solid 2px'}} 
                                                                value={streamingRoyalityOwner} 
                                                                onChange={handleStreamingRoyality} 
                                                            />
                                                            %
                                                        </div>
                                                    </div>
                                                    <div className='flex w-full  items-center'>   
                                                        <div>{sessionUser?.nearAccount}</div>
                                                        <div className='text-right flex-grow'>
                                                            <input name='setStreamingRoyalityCreator' 
                                                                type='text' 
                                                                className='text-center mr-1 mb-2' 
                                                                style={{width: 40, outline: '#6fcc5e solid 2px'}} 
                                                                value={streamingRoyalityCreator} 
                                                                onChange={handleStreamingRoyality} 
                                                            />
                                                            %
                                                        </div>
                                                    </div>
                                                    <div className='flex w-full items-center mt-1'>
                                                        <div>Tamago</div>
                                                        <div className='text-right flex-grow'><input className='text-center mr-1' type='text' value={3} style={{width: 40}} readOnly={true} disabled={true} />%</div>
                                                    </div>

                                                </div>                                        

                                                <div className='flex sm:hidden items-center text-space-cadet font-bold mt-4'>
                                                    Unlockable Content
                                                    <div className='flex flex-grow items-center justify-end sm:justify-end h-10 whitespace-nowrap'>
                                                        <Toggle disabled={sessionUser?.nearAccount == null} id='allow-downloads' checked={hasUnlockableContent} onChange={(e) => setUnlockableContent(e.target.checked)} />                                                
                                                    </div>
                                                </div>
                                                <div className={'border rounded p-2 transition-all '+(hasUnlockableContent?'':'bg-gray-100')}>                                                
                                                    {
                                                        sessionUser?.nearAccount == null && 
                                                        <div className='text-space-cadet sm:ml-4'><Link className='text-tama-dark-green underline' to={'/user/'+sessionUser.username+'/wallet'}>Connect Wallet</Link> to enable downloading</div>
                                                    }
                                                    {
                                                        sessionUser?.nearAccount && 
                                                        <>
                                                            <div className='transition-all w-full h-full flex flex-col' style={hasUnlockableContent?{opacity: 1}:{ opacity: 0 }}>                                                        
                                                                <textarea
                                                                    placeholder='Description of unlockable content'
                                                                    value={unlockableContentDescription}
                                                                    onChange={(e) => setUnlockableContentDescription(e.target.value)}                                                            
                                                                    className="block appearance-none w-full px-2 py-2 rounded text-black my-2 flex-grow"
                                                                    style={{ background: '#1d50b322', height: '60px' }}
                                                                />
                                                                <div className='text-space-cadet text-sm text-center font-bold'>
                                                                    { unlockableFile == null && track?.unlockableFilename != null && track?.unlockableFilename }                        
                                                                    { unlockableFile != null && unlockableFile.name }                                                                                    
                                                                </div>
                                                                {
                                                                    /*
                                                                <div className='flex flex-col items-center my-2 flex-grow justify-center'>
                                                                    <label className='text-space-cadet font-bold cursor-pointer text-xl'>Download Price</label>
                                                                    <input type='text' 
                                                                        className="block appearance-none w-full p-2 rounded text-black mt-2 mb-4 text-center" 
                                                                        style={{ background: '#1d50b322', minWidth: 40, width: getTextWidth(downloadPrice, '16px "NoyhRLight", sans-serif')+17 }} 
                                                                        value={downloadPrice} 
                                                                        onChange={(e) => setDownloadPrice(e.target.value.replace(/([^0-9.]+)/, ''))}
                                                                        />
                                                                    <NearType height={12} fill='#333' />
                                                                    { usd && <div className='text-space-cadet ml-1 text-sm'>≈{(downloadPrice*usd).toFixed(2)} USD</div>}   
                                                                    { hasUnlockableContent && !priceOk && <div className='ml-1 text-sm' style={{color: 'red'}}>Minimum price is { minPrice }</div> } 
                                                                </div>
                                                                    */
                                                                }                                                                 
                                                                <label className="block bg-white text-mandarin hover:bg-mandarin-dark hover:text-white font-bold text-center py-2 px-4 rounded cursor-pointer border border-mandarin text-sm mt-2">
                                                                    Upload File
                                                                    <input
                                                                        type='file'
                                                                        onChange={(e) => setUnlockableFile(e.target.files[0])}
                                                                        className="block appearance-none w-full bg-white px-2 py-2 rounded text-black mt-1 mb-4 hidden"
                                                                        style={{ background: '#1d50b322' }}
                                                                        id="download-file"
                                                                    />
                                                                </label>
                                                            </div>                                                                                       
                                                        </>
                                                    }
                                                </div> 
                                            </div>

                                        </div>                                

                                        <ul id="login-errors" className="block my-2 text-center text-red-600 font-bold">
                                            {inputErrors.map((error, idx) => <li key={idx}>{error}</li>)}
                                        </ul>

                                        <div className='my-2 mt-6 flex justify-around'>
                                            <button type='submit' 
                                                    disabled={inputErrors.length || (hasUnlockableContent && unlockableFile == null && unlockableContentDescription == null)} 
                                                    className={`w-72 sm:w-2/6 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded text-xl m-0 ${inputErrors.length || (hasUnlockableContent && unlockableFile == null && unlockableContentDescription == null) ? 'opacity-50' : ''}`}>
                                                { 'Mint Track' }
                                            </button>
                                        </div>
                                    </>
                                }                                
                            </>
                        }                        
                    </div>
                </div>

                {!uploadProgressPct &&
                    <div className='text-silver flex text-center justify-center mt-6'>
                        <p>
                            By uploading, you confirm that your sounds comply with our <a href="/Tamago-Terms-Of-Use.pdf" target="_blank" className='text-white'>Terms of Use</a><br />and you don't infringe anyone else's rights.<br /><br />
                            Please note that by adding rightsholder information, the respective rightsholder<br />will be able to see monetization and availability settings of this track.
                        </p>
                    </div>
                }
                {
                    uploadProgressPct && <div className='text-silver flex text-center justify-center mt-6'>
                        <p>Upload times may vary depending on your internet connection<br />and the size of the content you are uploading.</p>
                    </div>
                }

            </form>
        </div>
    );
};

MintTrack.defaultProps = {
    track: null
}

export default MintTrack;
