import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import configData from '../config.json'

const TipsState = ({}) => {

    const nearTipContract = useSelector(state => state.near.tipContract);
    const near = useSelector(state => state.near.api);
    const [accountToId, setAccountToId] = useState([]); // ai
    const [idToAccount, setIdToAccount] = useState([]); // ia
    const [trackOwner, setTrackOwner] = useState([]); // to
    const [tips, setTips] = useState([]); // t

    useEffect(() => {
        if (nearTipContract) {

        }
    }, [nearTipContract]);

    useEffect(async() => {
        if (near) {
            const tippingAccount = await near.account(configData.NEAR_TIPPING_CONTRACT);            
            const response = await tippingAccount.viewState('');            

            const ai = [...accountToId];

            response?.map((ele,ind) => {                
                const key = decodeURIComponent(escape(String.fromCharCode(...ele.key))).split('::');
                const val = decodeURIComponent(escape(String.fromCharCode(...ele.value)));
                //console.log(key,val);
                if (key.length > 0) {
                    switch(key[0]) {
                        case 'ai':                                   
                            ai.push({account: key[1], id: val})
                            //console.log(accountToId);                            
                            break;
                    }
                }
                return;
            });

            setAccountToId(ai);
        }
    }, [near]);

    return <div></div>
}

export default TipsState;