import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { forgotPassword } from '../../store/session';

const ForgotPasswordFormPage = () => {
    const dispatch = useDispatch();
    const sessionUser = useSelector(state => state.session.user);
    const [ credential, setCredential ] = useState('');    
    const [ inputErrors, setInputErrors ] = useState([]);
    const [ isEmailSent, setIsEmailSent ] = useState(false);  
    const [ isEmailSending, setIsEmailSending ] = useState(false);

    if (sessionUser) return (
        <Redirect to='/' />
    )

    const handleSubmit = async (e) => {
        e.preventDefault();
        setInputErrors([]);
        setIsEmailSending(true);

        return dispatch(forgotPassword({ credential }))
            .then(() => {
                setIsEmailSent(true);
                setIsEmailSending(false);
            })
            .catch((res) => {
                if (res.data && res.data.errors) setInputErrors(res.data.errors);
                else {
                    setInputErrors(['Error sending password reset link']);
                }
                setIsEmailSending(false);
            });
    }; 

    return (
        <div className="w-full mx-auto absolute min-h-full pb-32 py-5 flex-1 flex flex-col justify-center items-center bg-background2 bg-center bg-cover mt-12 sm:mt-24">            
            <h1 className="block text-2xl text-white my-2 text-center">Forgot Password</h1>
            <form onSubmit={handleSubmit} className="px-8 py-6 w-5/6 sm:w-96 mb-4 shadow-lg rounded bg-white glow">                
                { inputErrors.length > 0 && 
                    <ul id="login-errors" className="block my-2 text-center text-red-600 font-bold">
                        {inputErrors.map((error, idx) => <li key={idx}>{error}</li>)}
                    </ul>
                }                
                { isEmailSent && <div className='font-bold text-black text-lg text-center'>Password reset email sent</div>}
                { !isEmailSent &&
                    <>
                    <label className="block font-bold text-black mb-2">
                        Username or E-mail
                        <input
                            type='text'
                            placeholder='Enter your username or e-mail'
                            value={credential}
                            onChange={(e) => setCredential(e.target.value)}
                            required
                            className="block appearance-none w-full px-2 py-2 rounded text-black mt-1 mb-4"
                            style={{background: '#1d50b322'}}
                        />
                    </label>                                
                    <div className='mt-2 flex justify-around flex-col sm:flex-row'>
                        <button type='submit' disabled={isEmailSending} className="sm:w-1/2 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded mt-4 mb-0">{ isEmailSending ? 'Processing...' : 'Reset Password' }</button>                    
                    </div>
                    </>
                }
            </form> 
            <div className="p-1">                 
                <Link to='/login' className="text-mandarin font-large hover:font-bold">Sign In</Link>
            </div>
            {
                /*
                <div className='text-center mt-4 absolute bottom-0'>
                    <span onClick={() => {dispatch(login({ credential: 'DemoUser', password: 'password' }))}} className="cursor-pointer py-2 px-4 my-4">Sign in as Demo User</span>
                </div>
                */
            }
        </div>
    );
}

export default ForgotPasswordFormPage;
