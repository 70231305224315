import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { postFollow, deleteFollow } from '../../store/session';

const UserCard = ({track}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const sessionUser = useSelector(state => state.session.user);
    const following = sessionUser && sessionUser.following && sessionUser.following.find(curVal => curVal.followUserId == track.User.id)

    const follow = async() => {
        if (sessionUser) {
            if (following) 
                dispatch(deleteFollow(track.User.id));
            else 
                dispatch(postFollow(track.User.id));
        } else {
            history.push('/login');
        }
    } 

    return (
        <>
            {
                track.User &&
                <div className='flex flex-col items-center px-2 mt-10'>
                    <div className='flex-initial'>
                        <Link to={`/user/${track.User.username.replace('?','%3F')}`}>
                            <img src={track.User.avatarUrl} alt='User' className='w-24 h-24 rounded-full'></img>
                        </Link>
                    </div>
                    <div className='flex flex-col text-center mt-2'>
                        <Link to={`/user/${track.User.username.replace('?','%3F')}`} className='text-lg' style={{ color: '#6fcc5e' }}>{track.User.username}</Link>
                        <div className='hover:text-mandarin'><a href={track.User.websiteUrl}>{track.User.websiteUrl}</a></div>

                        {
                            (sessionUser && sessionUser.id == track.User.id) ? '' : 
                            <button onClick={follow} type='button' className={`${following?'':'bg-mandarin-dark'}  hover:font-bold hover:bg-mandarin-dark hover:opacity-100 text-white px-4 py-2 rounded my-2`}>
                                <i className="fas fa-headphones  mr-2"></i>
                                {following ? 'Unfollow' : 'Follow' } Artist
                            </button>
                        }

                    </div>
                </div>            
            }
        </>
    );
}

export default UserCard;
