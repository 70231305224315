import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReactFitty } from "react-fitty";
import Countdown from 'react-countdown';
import { getTrack, saveTransactions } from '../../store/track';
import { playTrack, playAudioTrack, stopTrack } from '../../store/player';
import SkullIcon from '../../img/icons/skullz.png'
import BombIcon from '../../img/icons/bomb.png'
import ChestIcon from '../../img/icons/chest.png'
import { ReactComponent as Trident } from '../../img/icons/trident.svg';
import { ReactComponent as Keyhole } from '../../img/icons/keyhole-silhouette-svgrepo-com.svg';
import { fetch as fetchTamago } from "../../store/csrf";
import configData from '../../config.json'

import { BallTriangle } from 'react-loader-spinner'
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useDocumentTitle, useMetaTags } from '../Common/useMeta';
import TrackWaveForm from './TrackWaveForm';
import { formatNumbers } from '../../utils/formatNumbers';
import TrackReact from '../Common/TractReact';
import * as nearAPI from 'near-api-js';
import { ReactComponent as NearType } from '../../img/icons/near_type.svg'
import { useQuery } from '../../utils/useQuery';
import { baseDecode } from 'borsh';
import { getTextWidth } from '../../utils/getTextWidth';


const NFTTrackPage = () => {
    const today = new Date();
    const { id } = useParams();
    const query = useQuery();
    const { utils } = nearAPI;
    const dispatch = useDispatch();
    const nftContract = useSelector(state => state.near.nftContract);
    const marketContract = useSelector(state => state.near.marketContract);
    const track = useSelector(state => state.track.track);
    const sessionUser = useSelector(state => state.session.user);
    const currentTrack = useSelector(state => state.player.currentTrack);
    const nearConfig = useSelector(state => state.near.config);
    const isPlaying = useSelector(state => state.player.isPlaying);
    const wavesurferPlayer = useSelector(state => state.player.wavesurfer);
    const nearWallet = useSelector(state => state.near.wallet);

    const [isFetched, setIsFetched] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [token, setToken] = useState(false);
    const { trackPageView } = useMatomo();
    const [documentTitle, setDocumentTitle] = useDocumentTitle(null);
    const [metaTags, setMetaTags] = useMetaTags({});
    const [nftType, setNftType] = useState(null);
    const [nftBlob, setNftBlob] = useState(null);
    const [listingType, setListingType] = useState(null);
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);
    const [listing, setListing] = useState(null);
    const [usd, setUsd] = useState(null);
    const [unlockable, setUnlockable] = useState(null);
    const [preparingDownload, setPreparingDownload] = useState(false);
    const [highestBid, setHighestBid] = useState(null);
    const [bid, setBid] = useState('');
    const [redirect, setRedirect] = useState(null);
    const [makeOfferHidden, setMakeOfferHidden] = useState(true);
    const [ownNFT, setOwnNFT] = useState(false);
    const [isBidAccepted, setIsBidAccepted] = useState(false);
    const [isTokenTransferred, setIsTokenTransferred] = useState(false);
    const [resaleRoyalities, setResaleRoyalities] = useState(null);
    const [streamingRoyalities, setStreamingRoyalities] = useState(null);

    // Track page view
    useEffect(() => {
        trackPageView();
        fetch('https://helper.mainnet.near.org/fiat')
            .then(response => {
                return response.json();
            })
            .then(d => {
                setUsd(d?.near?.usd);
            });
    }, []);

    //HIT BACKEND ROUTE TO LOAD TRACK INTO STORE
    useEffect(() => {
        dispatch(getTrack(id)).then(() => setIsFetched(true));
    }, [id, dispatch]);

    useEffect(() => {
        if (currentTrack == null && track) {
            dispatch(playTrack(track.id));
        }
        if (track) {
            setDocumentTitle(track.title + ' by ' + track.User.username);
            setMetaTags({ ...metaTags, title: track.title + ' by ' + track.User.username, description: track.description, image: track.imageUrl });
        }
    }, [track]);

    useEffect(() => {
        if (query && query.get('transactionHashes') && track?.id) {
            const tx = query.get('transactionHashes');

            const { providers } = nearAPI;
            const provider = new providers.JsonRpcProvider(nearConfig.archivalUrl);
            provider.txStatus(tx, sessionUser?.nearAccount).then(r => {
                if (r?.status?.SuccessValue == "") {
                    // successful bid
                    const bidAct = r.transaction.actions.find(act => act.FunctionCall.method_name == 'offer');
                    if (bidAct) setIsBidAccepted(true);

                    const resolveAct = r.transaction.actions.find(act => act.FunctionCall.method_name == 'resolve_purchase');
                    if (resolveAct) setIsTokenTransferred(true);
                }
            });


            provider.txStatusReceipts(baseDecode(tx), sessionUser?.nearAccount).then(r => {
                fetchTamago(configData.API_URL + `/api/tracks/${track.id}/tx`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(r),
                });
            });

        }
    }, [query, track]);

    useEffect(() => {
        if (nftContract && track) {
            if (track.token == null) {
                setAccessDenied(true);
            } else {
                nftContract.nft_token({ token_id: track.token }).then(t => setToken(t));
            }
        }
    }, [track, nftContract]);

    useEffect(() => {
        if (token) {
            // use our gateway
            fetch(token.metadata.media.replace('https://cloudflare-ipfs.com/ipfs/', 'https://ipfs.tamastream.io/ipfs/')).then(r => {
                if (r.headers.get("Content-Type").startsWith('image')) {
                    setNftType('image');
                } else if (r.headers.get("Content-Type").startsWith('video')) {
                    setNftType('video');
                }
                return r.blob();
            }).then(b => {
                setNftBlob(window.URL.createObjectURL(b));
            }).catch(e => console.log(e));

            const sr = JSON.parse(token?.metadata?.extra);

            setResaleRoyalities(Object.entries(token?.royalty)?.reduce((prev, curr) => { return prev + curr[1] }, 0) / 100);
            setStreamingRoyalities(Object.entries(sr?.streaming_royalties)?.reduce((prev, curr) => { return prev + curr[1] }, 0) / 100);
        }
    }, [token]);

    useEffect(() => {
        if (marketContract && token && nftContract) {
            marketContract.get_sale({ nft_contract_token: nftContract.contractId + '||' + token.token_id }).then(s => {
                setListing(s);
                setListingType(s?.is_auction == true ? 'auction' : 'fixed');
                if (s?.is_auction == true) {
                    if (s.start_at != '0') setStartDateTime(new Date(parseInt(s.start_at)));
                    if (s.end_at != '0') setEndDateTime(new Date(parseInt(s.end_at)));
                }
            });
        }
    }, [marketContract, token, nftContract]);

    useEffect(() => {
        if (token && unlockable == null) {
            //if (token.owner_id == sessionUser.nearAccount) {
            fetchTamago(configData.API_URL + `/api/tracks/${track.id}/unlockable`)
                .then(res => { setUnlockable(res.data); })
                .catch(e => e);
            //}
        }
    }, [token]);

    useEffect(() => {
        if (listing && listing.bids?.near?.length && (bid == null || bid == '')) {
            setBid(
                parseFloat(
                    utils.format.formatNearAmount(
                        listing.bids.near.sort((a, b) => parseFloat(utils.format.formatNearAmount(a.price)) < parseFloat(utils.format.formatNearAmount(b.price)))[0].price
                    )
                ) + 1
            );
        } else if (listing && listing.sale_conditions.near && (bid == null || bid == '')) {
            const price = parseFloat(utils.format.formatNearAmount(listing.sale_conditions.near));
            setBid(listing.is_auction ? price + 1 : price);
        }
    }, [listing, bid]);

    useEffect(() => {
        if (token && sessionUser) {
            if (token.owner_id == sessionUser.nearAccount) {
                setOwnNFT(true);
            }
        }
    }, [token, sessionUser]);

    const handleDownload = () => {
        if (preparingDownload) return;
        if (!ownNFT) return;

        setPreparingDownload(true);
        fetch('https://cloudflare-ipfs.com/ipfs/' + unlockable.cid).then((d) => {
            const contentType = d.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return d.data;
            } else {
                return d.blob();
            }
        }).then(blob => {
            if (blob.url) {
                fetch(blob.url).then(newblob => newblob.blob()).then(newblob => {
                    var a = document.createElement('a');
                    a.href = window.URL.createObjectURL(newblob);
                    a.download = unlockable.filename;
                    a.dispatchEvent(new MouseEvent('click'))
                    setPreparingDownload(false);
                });
            } else {
                var a = document.createElement('a');
                a.href = blob.url ? blob.url : window.URL.createObjectURL(blob);
                a.download = unlockable.filename;
                a.dispatchEvent(new MouseEvent('click'))
                setPreparingDownload(false);
            }
        });
    }

    const handlePlaceBid = () => {
        if (!sessionUser) {
            setRedirect('/login?redirect=' + encodeURIComponent(window.location.pathname));
            return;
        }

        if (nearWallet?.isSignedIn()) {
            if (ownNFT) return; // cannot bid on own NFT
            if ((listingType == 'auction' && bid > 0) || (listingType == 'fixed')) {
                marketContract.offer({
                    args: {
                        nft_contract_id: nftContract.contractId,
                        token_id: token.token_id
                    },
                    gas: "300000000000000",
                    amount: listingType == 'fixed' ? listing.sale_conditions.near : utils.format.parseNearAmount(bid)
                }).then((r) => {

                }).catch((e) => console.log(e));
            }
        } else {
            setRedirect('/user/' + sessionUser.username.replace('?', '%3F') + '/wallet?redirect=' + encodeURIComponent(window.location));
            return;
        }
    }

    const handleMakeOffer = () => {
        if (!sessionUser) {
            setRedirect('/login?redirect=' + encodeURIComponent(window.location.pathname));
            return;
        }
        if (nearWallet?.isSignedIn()) {
            if (makeOfferHidden) {
                setMakeOfferHidden(false);
                return;
            }
            if (listingType == 'fixed' && !ownNFT) { // can only make offer on NFT you do NOT own
                marketContract.offer({
                    args: {
                        nft_contract_id: nftContract.contractId,
                        token_id: token.token_id
                    },
                    gas: "300000000000000",
                    amount: utils.format.parseNearAmount(bid)
                }).then((r) => {

                }).catch((e) => console.log(e));
            }
        } else {
            setRedirect('/user/' + sessionUser.username.replace('?', '%3F') + '/wallet?redirect=' + encodeURIComponent(window.location));
            return;
        }
    }

    const handleAcceptBid = () => {
        if (!sessionUser) {
            setRedirect('/login?redirect=' + encodeURIComponent(window.location.pathname));
            return;
        }
        if (nearWallet?.isSignedIn()) {
            if (listingType == 'auction' && ownNFT) { // must own NFT to accept bid
                marketContract.accept_offer({
                    args: {
                        nft_contract_id: nftContract.contractId,
                        token_id: token.token_id,
                        ft_token_id: 'near'
                    },
                    gas: "300000000000000"
                }).then((r) => {

                }).catch((e) => console.log(e));
            }
        } else {
            setRedirect('/user/' + sessionUser.username.replace('?', '%3F') + '/wallet?redirect=' + encodeURIComponent(window.location));
            return;
        }
    }

    const togglePlay = async (e) => {
        e.preventDefault();

        if (isPlaying && currentTrack.id === track.id) {
            wavesurferPlayer.pause();
            dispatch(stopTrack());
        } else {
            wavesurferPlayer.pause();
            dispatch(playTrack(track.id)).then(() => {
                dispatch(playAudioTrack());
                wavesurferPlayer.play();
            });
        }
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    if (accessDenied) {
        return (<Redirect to={`/tracks/` + track.id} />);
    }

    const isActiveAuction = listingType == 'auction' && listing && (startDateTime == null || startDateTime <= new Date()) && (endDateTime == null || endDateTime >= new Date());
    const isActiveFixedPrice = listingType == 'fixed' && listing;
    const winningBid = listing?.bids?.near ? listing.bids.near.sort((a, b) => parseFloat(utils.format.formatNearAmount(a.price)) < parseFloat(utils.format.formatNearAmount(b.price)))[0] : null;

    return (
        <>
            {isFetched && track && token && (
                <div className="flex flex-col items-center">

                    <div className='mt-20 w-full lg:w-11/12 max-w-7xl'>

                        <div className='p-10 sm:p-16 pb-2 sm:pb-4 text-center'>
                            <Link to={`/user/${track.User.username}`} className='text-md md:text-2xl text-center mt-4' style={{ color: '#6fcc5e' }}>{track.User.username}</Link>
                            <div className='flex items-center justify-center'>
                                <Link to={`/tracks/${track.id}`} className='text-3xl md:text-4xl sm:mb-3 text-center'>{token.metadata.title}</Link>
                            </div>
                        </div>

                        <div className='flex flex-col sm:flex-row p-10 sm:p-16 sm:gap-10 pt-0 sm:pt-0'>
                            <div className='sm:w-1/2'>
                                {nftType == 'image' && <img src={nftBlob} alt='nft-blob' className='max-w-fit mx-auto' style={{ filter: 'drop-shadow(rgba(149, 168, 243, 0.7) 0 0 9px)' }} />}
                                {nftType == 'video' && <video autoPlay={true} loop={true} src={nftBlob} className='max-w-fit mx-auto' style={{ filter: 'drop-shadow(rgba(149, 168, 243, 0.7) 0 0 9px)' }} />}

                                <div className='flex justify-center'>
                                    <button disabled={track.trackStatus == 'processing'} onClick={togglePlay}
                                        className='sm:hidden bg-mandarin hover:bg-mandarin-dark text-white font-bold h-16 w-16 rounded-full justify-center items-center focus:outline-none m-0 mt-6'
                                        style={{ minWidth: '64px' }}>
                                        <i className={`fas fa-${isPlaying && currentTrack && currentTrack.id === track.id ? 'pause' : 'play'} fa-lg`}
                                            style={{ fontSize: '26px', marginLeft: '4px', marginTop: '5px' }}></i>
                                    </button>
                                </div>
                                <div className="w-full mb-6 mx-auto mt-4" style={{ maxWidth: 550 }}>
                                    <TrackWaveForm track={track} size={'small'} />
                                </div>

                                <div className="flex mx-auto" style={{ maxWidth: 550 }}>
                                    <TrackReact track={track} height={30} className="flex justify-center mb-4" />
                                    <div className='flex-grow flex items-start gap-3 justify-center sm:justify-end mt-1' style={{ color: 'rgb(111, 204, 94)' }}>
                                        <div className='items-center flex flex-row gap-1 ml-2'>
                                            <img src={BombIcon} alt='bomb-icon' className='h-7 w-auto -mt-1' />
                                            <span>{formatNumbers(track.Reactions.filter(reaction => reaction.reactionType === 'bomb').length)}</span>
                                        </div>
                                        <div className='items-center flex flex-row gap-1'>
                                            <img src={SkullIcon} alt='skull-icon' className='h-6 w-auto' />
                                            <span>{formatNumbers(track.Reactions.filter(reaction => reaction.reactionType === 'skull').length)}</span>
                                        </div>
                                        <div className='items-center flex flex-row gap-1'>
                                            <img src={ChestIcon} alt='chest-icon' className='h-6 w-auto' />
                                            <span>{formatNumbers(track.Reactions.filter(reaction => reaction.reactionType === 'gem').length)}</span>
                                        </div>
                                        <div className='items-center flex flex-row gap-1'>
                                            <i className={`fas fa-play`} style={{ fontSize: '13px' }}></i>
                                            <span>{formatNumbers(track.playCount)}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='flex flex-col sm:w-2/4 mt-2'>
                                <div className='text-center sm:text-left text-xl font-bold'>Owner</div>
                                <div className='text-center sm:text-left mb-2 text-xl' style={{ color: '#6fcc5e' }}>{token.owner_id}</div>

                                {
                                    // if user owns nft allow liting
                                    sessionUser && token.owner_id == sessionUser.nearAccount &&
                                    <Link to={`/tracks/${track.id}/list`}
                                        className='flex text-sm w-32 bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 rounded my-0 mb-6 mx-auto sm:mx-0'
                                    >
                                        <Trident className='w-auto h-5 mr-2' fill="#fff" />
                                        <span>{listing ? 'Edit Listing' : 'Sell Track'}</span>
                                    </Link>
                                }

                                {
                                    token?.metadata?.reference &&
                                    <div className='flex items-center p-2 rounded mt-4' style={{ borderColor: '#000', backgroundColor: '#95a8f3' }}>
                                        <div className='flex flex-col w-full'>
                                            <div className='flex items-center gap-2'>
                                                <Keyhole className='' fill='#fff' style={{ filter: 'drop-shadow(rgba(255, 255, 255, 0.4) 0px 0px 3px)' }} />
                                                <div className='text-lg flex-1 mt-1'>Unlockable Content</div>
                                            </div>
                                            {
                                                unlockable &&
                                                <div className='flex flex-col mt-2 mx-2'>
                                                    <p>{unlockable.description}</p>
                                                    {
                                                        unlockable.filename &&
                                                        <div onClick={handleDownload} className={'bg-white rounded text-space-cadet p-2 w-28 text-center my-4 cursor-pointer ' + (preparingDownload ? 'dotty' : '')}>{preparingDownload ? 'Preparing' : 'Download'}</div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    resaleRoyalities && streamingRoyalities &&
                                    <div className='mt-4 flex flex-col sm:flex-row text-center sm:text-left'>
                                        <div>Resale Royality Fees <span style={{ color: 'rgb(111, 204, 94)' }}>{resaleRoyalities}%</span></div>
                                        <div className='sm:text-right flex-1'>Streaming Royality Fees <span style={{ color: 'rgb(111, 204, 94)' }}>{streamingRoyalities}%</span></div>
                                    </div>
                                }
                                {
                                    listing &&
                                    <>
                                        <div className='flex flex-col mt-6 p-4 rounded shadow-lg bg-white text-white items-center' style={{ background: 'rgb(28, 28, 40) none repeat scroll 0% 0%', filter: 'drop-shadow(rgba(110, 203, 94, 0.5) 0px 0px 7px)' }}>
                                            {
                                                listing.is_auction && startDateTime > today &&
                                                <div className='text-xl flex flex-col sm:flex-row gap-1 justify- text-center'>
                                                    <Countdown date={startDateTime} renderer={(c) => {
                                                        return <div className='flex gap-1 flex-col mb-3'>
                                                            <span>Auction starts in </span>
                                                            <div className='font-bold flex gap-3'>
                                                                {c.days && <div className='flex flex-col'><span style={{ color: 'rgb(111, 204, 94)' }}>{c.days}</span><span>{' day' + (c.days > 1 ? 's' : '')}</span></div>}
                                                                {(c.hours || c.days) && <div className='flex flex-col'><span style={{ color: 'rgb(111, 204, 94)' }}>{c.hours}</span> {' hour' + (c.hours > 1 ? 's' : '')}</div>}
                                                                {(c.hours || c.days || c.minutes) && <div className='flex flex-col'><span style={{ color: 'rgb(111, 204, 94)' }}>{c.minutes}</span> {' min' + (c.minutes > 1 ? 's' : '')}</div>}
                                                                {(c.hours || c.days || c.minutes || c.seconds) && <div className='flex flex-col'><span style={{ color: 'rgb(111, 204, 94)' }}>{c.seconds}</span> {' sec' + (c.seconds > 1 ? 's' : '')}</div>}
                                                            </div>
                                                        </div>
                                                    }} />
                                                </div>
                                            }
                                            {
                                                listing.is_auction && endDateTime < today && winningBid &&
                                                <div className='text-xl'>
                                                    <span>{'The highest bidder was'} <span style={{ color: 'rgb(111, 204, 94)' }}>{' '}{winningBid.owner_id}{' '}</span> {'with a bid of'}</span>
                                                    <div>
                                                        <span style={{ color: 'rgb(111, 204, 94)' }}>{utils.format.formatNearAmount(winningBid.price)}</span>
                                                        <NearType fill="#fff" className='h-2.5 inline mx-1 mb-1' />
                                                        {usd && <div className='ml-1 text-sm inline'>≈{parseFloat((utils.format.formatNearAmount(winningBid.price)) * usd).toFixed(2)} USD</div>}
                                                    </div>
                                                </div>
                                            }
                                            {
                                                listing.is_auction && endDateTime < today && winningBid == null &&
                                                <div className='text-xl'>
                                                    Auction has ended
                                                </div>
                                            }
                                            {
                                                listing.is_auction && endDateTime > today && (startDateTime == null || startDateTime <= today) &&
                                                <div className='text-xl flex flex-col sm:flex-row gap-1 justify- text-center'>

                                                    <Countdown date={endDateTime} renderer={(c) => {
                                                        if (c.completed && listing?.bids?.near?.length) {
                                                            const winningBid = listing.bids.near.sort((a, b) => parseFloat(utils.format.formatNearAmount(a.price)) < parseFloat(utils.format.formatNearAmount(b.price)))[0];
                                                            return <div className=''>
                                                                {'The highest bidder was'} {winningBid.owner_id}
                                                                {'with a bid of'} {utils.format.formatNearAmount(winningBid.price)} <NearType fill="#fff" className='h-2.5 inline' />
                                                                {usd && <div className='ml-1 text-sm inline'>≈{parseFloat((utils.format.formatNearAmount(winningBid.price)) * usd).toFixed(2)} USD</div>}
                                                            </div>;
                                                        }
                                                        return <div className='flex gap-1 flex-col mb-3'>
                                                            <span>Auction ends in </span>
                                                            <div className='font-bold flex gap-3'>
                                                                {c.days && <div className='flex flex-col'><span style={{ color: 'rgb(111, 204, 94)' }}>{c.days}</span><span>{' day' + (c.days > 1 ? 's' : '')}</span></div>}
                                                                {(c.hours || c.days) && <div className='flex flex-col'><span style={{ color: 'rgb(111, 204, 94)' }}>{c.hours}</span> {' hour' + (c.hours > 1 ? 's' : '')}</div>}
                                                                {(c.hours || c.days || c.minutes) && <div className='flex flex-col'><span style={{ color: 'rgb(111, 204, 94)' }}>{c.minutes}</span> {' min' + (c.minutes > 1 ? 's' : '')}</div>}
                                                                {(c.hours || c.days || c.minutes || c.seconds) && <div className='flex flex-col'><span style={{ color: 'rgb(111, 204, 94)' }}>{c.seconds}</span> {' sec' + (c.seconds > 1 ? 's' : '')}</div>}
                                                            </div>
                                                        </div>
                                                    }} />
                                                </div>
                                            }
                                            {
                                                isBidAccepted && !listing.is_auction &&
                                                <div className='font-bold text-2xl mb-2'>Your bid has been received</div>
                                            }
                                            {
                                                isTokenTransferred &&
                                                <div className='font-bold text-2xl mb-2'>The NFT has been transferred</div>
                                            }
                                            {
                                                isActiveAuction && listing?.owner_id !== sessionUser?.nearAccount &&
                                                <div className={'border rounded my-2 p-4 flex flex-col items-center w-48 bg-white ' + (ownNFT ? 'opacity-50' : '')} style={ownNFT ? { pointerEvents: 'none' } : {}}>
                                                    <input type='text'
                                                        className="block appearance-none w-full p-2 rounded text-black mt-2 mb-4 text-center"
                                                        style={{ background: '#1d50b322', minWidth: 40, width: getTextWidth(bid, '16px "NoyhRLight", sans-serif') + 17 }}
                                                        value={bid}
                                                        onChange={(e) => setBid(e.target.value.replace(/([^0-9.]+)/, ''))}
                                                    />
                                                    <NearType height={12} fill='#333' />
                                                    {usd && <div className='text-space-cadet ml-1 text-sm'>≈{(bid * usd).toFixed(2)} USD</div>}
                                                    <div
                                                        className='rounded p-2 w-36 flex items-center bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 mt-2 cursor-pointer'
                                                        style={{ backgroundColor: 'rgb(149, 168, 243)', color: 'white', filter: 'drop-shadow(rgba(255, 255, 255, 0.4) 0px 0px 3px)' }}
                                                        onClick={handlePlaceBid}
                                                    >
                                                        <Trident className='w-auto h-5 mr-4' fill="#fff" />
                                                        <span>Place Bid</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                isActiveFixedPrice &&
                                                <div className={'border rounded my-2 p-4 flex flex-col items-center w-48 bg-white text-space-cadet ' + (ownNFT ? 'opacity-50' : '')} style={ownNFT ? { pointerEvents: 'none' } : {}}>
                                                    <div className='text-4xl font-bold'>{listing?.sale_conditions?.near && utils.format.formatNearAmount(listing.sale_conditions.near)}</div>
                                                    <NearType height={12} fill='#333' />
                                                    {usd && <div className='text-space-cadet ml-1 text-sm'>≈{((listing?.sale_conditions?.near && utils.format.formatNearAmount(listing.sale_conditions.near)) * usd).toFixed(2)} USD</div>}
                                                    <div
                                                        className='rounded p-2 w-36 flex items-center bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 mt-2 cursor-pointer'
                                                        style={{ backgroundColor: 'rgb(149, 168, 243)', color: 'white', filter: 'drop-shadow(rgba(255, 255, 255, 0.4) 0px 0px 3px)' }}
                                                        onClick={handlePlaceBid}
                                                    >
                                                        <Trident className='w-auto h-5 mr-2 ml-2' fill="#fff" />
                                                        <span>Buy Now</span>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                isActiveFixedPrice &&
                                                <div className={'rounded my-2 p-4 flex flex-col items-center w-48 text-space-cadet ' + (ownNFT ? 'opacity-50 ' : '') + (makeOfferHidden ? '' : 'border bg-white')} style={ownNFT ? { pointerEvents: 'none' } : {}}>
                                                    <div className={'flex flex-col items-center ' + (makeOfferHidden ? 'hidden' : '')}>
                                                        <input type='text'
                                                            className="block appearance-none w-full p-2 rounded text-black mt-2 mb-4 text-center"
                                                            style={{ background: '#1d50b322', minWidth: 40, width: getTextWidth(bid, '16px "NoyhRLight", sans-serif') + 17 }}
                                                            value={bid}
                                                            onChange={(e) => setBid(e.target.value.replace(/([^0-9.]+)/, ''))}
                                                        />
                                                        <NearType height={12} fill='#333' />
                                                        {usd && <div className='text-space-cadet ml-1 text-sm mb-2'>≈{(bid * usd).toFixed(2)} USD</div>}
                                                    </div>
                                                    <div
                                                        className='rounded p-2 w-36 flex items-center font-bold py-2 px-4 cursor-pointer border'
                                                        style={{ color: 'rgb(149, 168, 243)', borderColor: 'rgb(149, 168, 243)' }}
                                                        onClick={handleMakeOffer}
                                                    >
                                                        <Trident className='w-auto h-5 mr-2' fill="rgb(149, 168, 243)" />
                                                        <span>{makeOfferHidden ? 'Make Offer' : 'Place Offer'}</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                sessionUser && token.owner_id == sessionUser.nearAccount && listing?.bids?.near?.length > 0 && (listing.is_auction && (endDateTime <= today || endDateTime == null) && (startDateTime <= today || startDateTime == null)) &&
                                                <div
                                                    className='rounded p-2 w-48 flex items-center bg-mandarin hover:bg-mandarin-dark text-white font-bold py-2 px-4 mt-2 cursor-pointer justify-center'
                                                    onClick={handleAcceptBid}
                                                >Accept Highest Bid</div>
                                            }
                                        </div>
                                        {
                                            listing?.bids?.near?.length > 0 &&
                                            <div className='text-lg mt-2'>
                                                <div className='text-xl font-bold mt-4'>Offers</div>
                                                <table className="table-auto text-left w-full">
                                                    <thead className='bg-gray-800'>
                                                        <tr>
                                                            <th className='py-2 px-2'>Bidder</th>
                                                            <th className='py-2 px-2'><NearType className='h-2.5' fill="#fff" /></th>
                                                            <th className='py-2 px-2'>≈ USD</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            listing.bids.near.sort((a, b) => parseFloat(utils.format.formatNearAmount(a.price)) < parseFloat(utils.format.formatNearAmount(b.price))).map((offer, ind) => {
                                                                const usdPrice = (utils.format.formatNearAmount(offer.price) * usd).toFixed(2)
                                                                return (
                                                                    <tr>
                                                                        <td className='py-2 px-2' style={{ color: '#6fcc5e' }}>{offer.owner_id}</td>
                                                                        <td className='py-2 px-2'>{utils.format.formatNearAmount(offer.price)}</td>
                                                                        <td className='py-2 px-2'>{usd && "≈" + usdPrice}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                    </tbody>
                                                </table>
                                                {/* {
                                                    listing.bids.near.sort((a, b) => parseFloat(utils.format.formatNearAmount(a.price)) < parseFloat(utils.format.formatNearAmount(b.price))).map((offer, ind) => {
                                                        const bid = <div key={'offer' + ind} className='flex items-center gap-1'>
                                                            <span style={{ color: '#6fcc5e' }}>{offer.owner_id}</span> for {utils.format.formatNearAmount(offer.price)}
                                                            <NearType className='h-2.5' fill="#fff" />
                                                            {usd && <div className='ml-1 text-md'>≈ {(utils.format.formatNearAmount(offer.price) * usd).toFixed(2)} USD</div>}
                                                        </div>
                                                        return bid
                                                    })
                                                } */}
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                    <div className='flex items-center justify-center'>

                    </div>
                </div>
            )}
            {
                !isFetched &&
                <div className='mt-40 flex-1'>
                    <div className='flex items-center justify-center h-full'>
                        <BallTriangle color="#6fcc5e33" height={70} width={70} ariaLabel="three-circles-rotating" />
                    </div>
                </div>
            }
        </>
    );
}

export default NFTTrackPage;
