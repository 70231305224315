import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from "antd";
import WaveSurfer from "wavesurfer.js";
import { isSafari, browserName, browserVersion } from 'react-device-detect';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, TouchSensor, DragOverlay, useDraggable } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { isMobile } from 'react-device-detect';

import { unloadTrack, playAudioTrack, stopTrack, setWaveSurfer, playTrack, setIsTrackLoading, removeFromQueue, moveInQueue, clearQueue } from "../../store/player";
import configData from '../../config.json'
import TrackReact from "../Common/TractReact";
import { ReactComponent as VinylSVG } from '../../img/icons/vinyl-svgrepo-com.svg'
import { ReactComponent as VolumeIcon } from '../../img/icons/volume-up-svgrepo-com.svg'
import { ReactComponent as QueueIcon } from '../../img/icons/queue-svgrepo-com.svg'
import { ReactComponent as CloseQueueIcon } from '../../img/icons/down-svgrepo-com.svg'
import { ReactComponent as MoveIcon } from '../../img/icons/drag-svgrepo-com.svg'
import styles from './Player.module.css'
import { style } from "wavesurfer.js/src/util";
import TrackCard from "../Common/TrackCard";
import TrackMainInfo from "../TrackPage/TrackMainInfo";
import { QueueItem } from "./QueueItem";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const formWaveSurferOptions = (ref) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let context, processor;
    if (isSafari && false) {
        // Safari 11 or newer automatically suspends new AudioContext's that aren't
        // created in response to a user-gesture, like a click or tap, so create one
        // here (inc. the script processor)
        let AudioContext = window.AudioContext || window.webkitAudioContext;
        context = new AudioContext();
        processor = context.createScriptProcessor(1024, 1, 1);
    }

    return {
        container: ref,
        audioContext: context || null,
        audioScriptProcessor: processor || null,
        waveColor: "#BFC0C0",
        progressColor: "#6fcc5e",
        cursorColor: "transparent",
        responsive: true,
        height: 50,
        normalize: true,
        partialRender: true,
        hideScrollbar: true,
        backend: "MediaElementWebAudio"
    }
};

export function Player() {
    const dispatch = useDispatch();
    const volumeRef = useRef(null);
    const location = useLocation();
    const sessionUser = useSelector(state => state.session.user);
    const currentTrack = useSelector(state => state.player.currentTrack);
    const queue = useSelector(state => state.player.queue);
    const queueLength = useSelector(state => state.player.queue.length);    
    const nextTrack = useSelector(state => state.player.nextTrack);
    const isPlaying = useSelector(state => state.player.isPlaying);
    const wavesurfer = useSelector(state => state.player.wavesurfer);
    const trackLoadingPlayer = useSelector(state => state.player.isTrackLoading);
    const waveformRef = useRef(null);
    const titleRef = useRef(null);
    const [volume, setVolume] = useState(0.75);
    const [timer, setTimer] = useState();
    const [scrollTitle, setScrollTitle] = useState(false);
    const [trackLoading, setTrackLoading] = useState(false);
    const [showVolumeControl, setShowVolumeControl] = useState(false);
    const [showQueue, setShowQueue] = useState(false);    
    const [showSaveInput, setShowSaveInput] = useState(false);
    const [playlistName, setPlaylistName] = useState('');    
    const [playerSize, setPlayerSize] = useState(false);
    const [isResizing, setIsResizing] = useState(false);

    let url;
    if (currentTrack) url = configData.API_URL + "/api/tracks/" + currentTrack.id + "/stream";

    const nextTrackCallback = useCallback(() => {
        if (queueLength > 0) {            
            dispatch(playTrack(queue[0].id)).then(() => {
                dispatch(playAudioTrack());
            });
        } else {
            if (nextTrack && currentTrack.id != nextTrack.id) {
                dispatch(playTrack(nextTrack.id)).then(() => {
                    dispatch(playAudioTrack());
                });
            } else {
                dispatch(stopTrack());
            }
        }
    }, [nextTrack, queue, queueLength]);

    useEffect(() => {

        if (waveformRef.current && !wavesurfer) {
            const options = formWaveSurferOptions(waveformRef.current);
            dispatch(setWaveSurfer(WaveSurfer.create(options)));
        } else if (wavesurfer) {
            wavesurfer.on("audioprocess", function (i) {
                if (i == 0) {                    
                    setTrackLoading(true);
                    wavesurfer.trackLoading = true;
                    dispatch(setIsTrackLoading(true));
                } else {                    
                    if (wavesurfer.trackLoading) {
                        setTrackLoading(false);
                        wavesurfer.trackLoading = false;
                        dispatch(setIsTrackLoading(false));                    
                    }
                }
            });
            wavesurfer.on("ready", function () {
                if (isPlaying && wavesurfer && !wavesurfer.isPlaying()) {
                    const isIOSSafari = typeof navigator.standalone === 'boolean';
                    if (isIOSSafari) {
                        dispatch(stopTrack());
                    } else {
                        wavesurfer.play();
                    }
                }

                // Make sure object still available when file loaded
                if (wavesurfer) {
                    wavesurfer.setVolume(volume);
                    setVolume(volume);
                }
            });
            wavesurfer.on('error', function (err) {
                console.log(err);
            });
            wavesurfer.on("finish", nextTrackCallback);
        }
        return () => {
            if (wavesurfer && wavesurfer.handlers) {
                wavesurfer.handlers['finish'] = [];
            }
        }

    }, [wavesurfer, waveformRef.current, nextTrackCallback]);

    // Create new WaveSurfer instance on component mount and when url changes
    useEffect(() => {
        if (waveformRef.current && wavesurfer && wavesurfer.backend) {

            const audioEle = new Audio(url);
            audioEle.crossOrigin = 'anonymous';

            if (wavesurfer.backend.sourceMediaElement) {
                if (!wavesurfer.backend.sourceMediaElement.mediaElement.src.includes(url)) wavesurfer.backend.sourceMediaElement.mediaElement.src = url;
                wavesurfer.backend.explicitDuration = currentTrack.duration;                                       
                wavesurfer.play(); 
                //wavesurfer.load(audioEle, JSON.parse(currentTrack.TrackPeak.peakData));
            } else {                

                if (currentTrack.TrackPeak && currentTrack.TrackPeak.peakData) {
                    wavesurfer.backend.destroy();
                    wavesurfer.drawer.destroy();
                    wavesurfer.init();
                    wavesurfer.backend.explicitDuration = currentTrack.duration;
                    wavesurfer.load(audioEle, JSON.parse(currentTrack.TrackPeak.peakData));
                } else {
                    wavesurfer.load(audioEle);
                }
            }
            wavesurfer.track_id = currentTrack.id;
        }

    }, [url, wavesurfer]);

    useEffect(() => {
        if (titleRef.current) {
            if (titleRef.current.offsetWidth < titleRef.current.firstChild.offsetWidth) {
                setScrollTitle(titleRef.current.offsetWidth)
            } else {
                setScrollTitle(false);
            }
        }

        if (navigator.mediaSession && currentTrack) {

            navigator.mediaSession.metadata = new window.MediaMetadata({
                title: currentTrack.title,
                artist: currentTrack.User.username,
                artwork: [
                    { 'src': currentTrack.imageUrl }
                ]
            });
        }

    }, [titleRef.current, currentTrack]);

    useEffect(() => {
        setShowQueue(false);
    }, [location]);

    useEffect(() => {
        const handleMouseMove = (event) => {      
            if (isResizing) {

                let top = event.clientY >= 0 ? event.clientY : 0;
                if(event.type == 'touchstart' || event.type == 'touchmove' || event.type == 'touchend' || event.type == 'touchcancel'){
                    const touch = event.touches[0] || event.changedTouches[0];
                    top = touch.clientY >= 0 ? touch.clientY : 0;
                } 
                
                if (top > window.innerHeight - 250) top = window.innerHeight - 250;

                setPlayerSize({
                    top: top,
                    height: 'auto',
                    transitionProperty: 'none'
                });
            }
        };

        const handleMouseUp = (event) => {            
            setIsResizing(false);
        }
      
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchmove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('touchend', handleMouseUp);
      
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchend', handleMouseUp);
        }
    }, [isResizing]);

    const handlePlayPause = () => {
        if (isPlaying) {
            wavesurfer.pause();
            dispatch(stopTrack())
        } else {
            wavesurfer.play();
            dispatch(playAudioTrack());
        }
    };

    const handleStop = () => {
        if (wavesurfer) {
            wavesurfer.pause();
            setWaveSurfer(null);
        }
        dispatch(stopTrack());
        dispatch(unloadTrack());
    }

    const onVolumeChange = (e) => {
        const { target } = e;
        const newVolume = +target.value;
        if (newVolume) {                        
            setVolume(newVolume);
            wavesurfer.setVolume(newVolume || 1);
            wavesurfer.backend.gainNode.gain.setValueAtTime(newVolume, 0);
        }
    };

    const sensors = useSensors(
        useSensor(isMobile ? TouchSensor : PointerSensor, {
            activationConstraint: {
              delay: 100,
              tolerance: 5,
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = ({ active, over }) => {        
        if (over && active.id !== over?.id) {
            const activeIndex = queue.findIndex(({ id }) => id === active.id);
            const overIndex = queue.findIndex(({ id }) => id === over.id);  
            dispatch(moveInQueue(activeIndex, overIndex));                        
        }
    }

    return (
        <div className={styles.player}>
            <div id='placeholder-for-player' className='bottom-0 h-20 w-full'></div>
            <div className={"fixed w-full z-10 h-full bottom-20 tama-gradient-trans flex flex-col transition-all "+(showQueue?'':'top-full')} style={ playerSize && showQueue ? playerSize : {height: 'calc(50vh - 5rem)'}}>                
                <div onMouseDown={() => setIsResizing(true)} onTouchStart={() => setIsResizing(true)} className="flex justify-center items-center" style={{cursor: 'ns-resize', touchAction: 'none' }}>
                    <div className="hidden sm:block w-10 h-5 sm:h-1 bg-white sm:rounded-full"></div>
                    <MoveIcon height={25} width={25} className="sm:hidden" style={{ transform: 'rotate(90deg)' }} />
                </div>                
                <div className="min-h-full pb-6" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>                    
                    <div className="flex flex-col h-full">
                        <div className="flex justify-end gap-5 mt-5 items-center px-5 pb-5">
                            <div className="text-xl text-tama-green whitespace-nowrap">Current Playlist - {queue?.length}</div>
                            {
                                !showSaveInput && 
                                <>
                                    { /* <button className={"btn btn-primary m-0 text-white text-center py-1 rounded border cursor-pointer w-16 sm:w-32 " + (queue.length == 0 ? 'opacity-20' : 'hover:bg-mandarin hover:text-white')} onClick={ (e) => { setShowSaveInput(true); } }>Save</button> */ }
                                    <button className={"btn btn-primary m-0 text-white text-center py-1 rounded border cursor-pointer w-16 sm:w-32 " + (queue.length == 0 ? 'opacity-20' : 'hover:bg-mandarin hover:text-white')} onClick={ (e) => { dispatch(clearQueue()) } }>Clear</button>                            
                                </>
                            }
                            <div className={'flex-grow flex gap-4 transition-all overflow-hidden '+(showSaveInput?'max-w-full':'max-w-0')}>
                                <input type='text' className={"text-black text-xl border-tama-green border-2 rounded px-2 py-1 w-full max-w-lg"} placeholder='Playlist Name' value={playlistName} onChange={ (e) => { setPlaylistName(e.target.value) } } />
                                <button className={"btn btn-primary m-0 text-white text-center py-1 rounded border cursor-pointer w-16 sm:w-32 " + (playlistName.length == 0 ? 'opacity-20' : 'hover:bg-mandarin hover:text-white')} onClick={ (e) => { alert('TODO'); } }>Save</button>              
                                <button className="btn btn-primary m-0 text-white text-center py-1 rounded border cursor-pointer w-16 sm:w-32 hover:bg-mandarin hover:text-white" onClick={ (e) => { setShowSaveInput(false); } }>Cancel</button>           
                            </div>
                            <div className="flex-grow"></div>
                            <CloseQueueIcon onClick={ (e) => { setShowQueue(false) } } className="w-10 h-10 cursor-pointer justify-end" fill="currentColor" />
                        </div>        
                        <div className="overflow-y-auto overscroll-contain h-full">
                            { 
                                queue?.length > 0 && 
                                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                                    <SortableContext items={queue.map(t => t.id)} strategy={verticalListSortingStrategy}>                                                  
                                        {
                                            queue?.length > 0 && queue.map((track, index, nodeRef) => <QueueItem key={track.id} track={track} index={index} showIndex={true} />)
                                        }                                                                                                    
                                    </SortableContext>
                                </DndContext>
                            }
                            {
                                queue?.length == 0 && 
                                <div className="pl-5">No tracks in your playlist</div>
                            } 
                        </div>
                    </div>
                </div>
            </div>
            <div className='fixed w-screen z-10 h-20 bottom-0 tama-gradient-trans bg-opacity-80 px-4 sm:px-10 text-silver flex flex-row items-center'>
                <div className={'flex flex-row items-center relative mr-2 sm:mr-2 '+(trackLoading?styles.trackLoading:'')}>
                    <VinylSVG height="124" className={`${isPlaying ? styles.playing : ''} hidden sm:block`} onClick={handlePlayPause} />
                    <i onClick={handlePlayPause} className={`fas fa-${isPlaying ? 'pause' : 'play'} sm:absolute text-silver px-2 sm:px-0`} style={{ left: "50px", color: "#eee", fontSize: "26px" }}></i>
                </div>
                <div className={`sm:pl-5 flex-grow sm:flex-none flex flex-row items-center mr-2 sm:mr-7 ${styles.playertitle}`}>
                    <div className='flex-initial hidden sm:block'>
                        <Link to={`/tracks/${currentTrack.id}`}>
                            <img src={currentTrack.imageUrl} alt='Track' className='w-12 h-12 shadow-2xl rounded'></img>
                        </Link>
                    </div>
                    <div className='flex flex-col sm:pl-5'>
                        <div><Link to={`/user/${currentTrack.User.username.replace('?', '%3F')}`}>{currentTrack.User.username}</Link></div>
                        <div ref={titleRef} className={scrollTitle ? styles.scrollTitle : ''}>
                            <Link to={`/tracks/${currentTrack.id}`} style={scrollTitle ? { transform: 'translateX(' + scrollTitle + 'px)' } : {}}>{currentTrack.title}</Link>
                        </div>
                    </div>
                </div>
                <div id="player-waveform" ref={waveformRef} className='hidden sm:block flex-grow mr-7' />
                <TrackReact track={currentTrack} className='sm:mr-7 flex' />

                <div className='hidden sm:flex flex-col items-center mr-7 relative text-white'>
                    <svg className='w-10 h-10 cursor-pointer hover:text-mandarin' viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" onClick={ (e) =>{ setShowVolumeControl(!showVolumeControl); }}>
                        { volume >= 0.75 && <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/> }
                        { volume >= 0.5 && <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/> }
                        { volume > 0.02 && <path d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8z" /> }
                        <path d="M7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z"/>
                    </svg>    
                    {                        
                        <div className={"absolute transform -rotate-90 inline-block p-5 transition-all "+(showVolumeControl?'bottom-28':'-bottom-36 opacity-20')} style={{backgroundColor: '#862359', height: 'calc(24px + 2.5rem)' }}>     
                            <input
                                ref={volumeRef}
                                className="slider w-36"
                                type="range"
                                id="volume"
                                name="volume"
                                min="0.01"
                                max="1"
                                step=".025"
                                onChange={onVolumeChange}
                                defaultValue={volume}
                                style={{ accentColor: "#6fcc5e", height: 24 }}
                            />
                        </div>
                    }
                </div>
                <div className="relative text-white">
                    <QueueIcon className={(queue?.length>0?'':'opacity-30')+" w-10 h-10 cursor-pointer hover:text-mandarin hover:opacity-100"} fill="currentColor" onClick={ (e) => { setShowQueue(!showQueue) } } />
                    { queue?.length > 0 && <span key={queue.length} className={styles.queueBadge}>{ queue.length }</span> }
                </div>
                { 
                    false &&
                    <Button onClick={handleStop} className='ant-btn text-white hover:text-mandarin font-bold h-10 w-10 m-0 hidden sm:block'>
                        <i className="fas fa-times fa-2x" style={{ opacity: "0.5" }}></i>
                    </Button>
                }
            </div>
        </div>
    );
}

export default Player;
